<template>
  <div>
    <slot name="title"></slot>
    <p v-if="collapsed" class="revealDetails" @click="toggle(false)">Show Calculator Details &#9660;</p>
    <p v-else class="revealDetails" @click="toggle(true)">Hide Calculator Details &#9650;</p>
    <div v-show="!collapsed" class="calc-detail-container">
      <slot></slot>
    </div>
  </div>
</template>

<script>
  export default {
    data() {
      return {
        collapsed: true
      }
    },
    methods: {
      toggle(bool) {
        this.collapsed = bool
      }
    }
  }
</script>
