var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "a",
    { attrs: { href: _vm.href, target: "_blank", tabindex: "-1" } },
    [_vm._v("SOC Section " + _vm._s(_vm.section))]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }