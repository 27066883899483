var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    {
      staticClass: "view",
      class: { expanded: _vm.group.expanded },
      on: {
        click: function ($event) {
          return _vm.$set(_vm.group, "expanded", !_vm.group.expanded)
        },
      },
    },
    [
      _c("td", [_vm._v(_vm._s(_vm.group.claim_number))]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.group.worker_first_name))]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm.group.worker_last_name))]),
      _vm._v(" "),
      _c("td", [_vm._v(_vm._s(_vm._f("formatDate")(_vm.group.updated_at)))]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }