<template>
  <div class="ui-helper-clearfix calcFieldBox" :class="[{ validationError: error }, containerClass]">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      containerClass: {
        type: Object,
        default() {}
      },
      error: {
        type: Boolean,
        default: false
      }
    }
  }
</script>
