<template>
    <div v-if="saveable">
      <div class="calcFieldBox" v-if="status">
        <div class="formFieldNotation success-notification">
          <p v-if="status === 'success'">
            <span><i class="fas fa-check-circle"></i>Success.</span>
            Your report has been saved and added to your <a href="/calculators/saved">Saved Reports</a>.
          </p>
          <p v-if="status === 'failure'">
            <span><i class="fas fa-times-circle"></i>Failure.</span>
            {{ message }}
          </p>
        </div>
      </div>
      <div class="buttonContainer" :class="{ 'tooltip-top big-tip': !enabled }" :data-tooltip="tooltipText">
        <button class="button black-button" @click="saveData" type="submit" :form="form" :class="{ disabled: !enabled }"
                :disabled="!enabled">
          Save Report
        </button>
      </div>
    </div>
</template>

<script>
  export default {
    inject: ['saveable', 'saveData'],
    props: {
      form: {
        type: String,
        required: true
      },
      message: {
        type: String
      },
      status: {
        type: String
      },
      enabled: {
        type: Boolean,
        required: true
      },
      tooltip: {
        type: String,
        default: 'Please input a Claim/Matter Number to save your report.'
      }
    },
    computed: {
      tooltipText() {
        return this.enabled ? null : this.tooltip;
      }
    }
  }
</script>
