<template>
  <div>
    <section id="calculator-form">
      <h2>Values to be Combined</h2>
      <form @submit.prevent>
        <calc-field-box v-for="(value, index) in values" :key="index" :error="$v.values.$each[index].$error">
          <calc-label :error="errorMessage(`values.$each.${index}`)">Value {{ index + 1 }}:</calc-label>
          <calc-field>
            <abbr @click="removeValue(index)" data-tooltip="Delete Value" v-if="index !== 0">
              <i class="fas fa-times-circle"></i>
            </abbr>
            <input v-focus type="number" min="1" max="99" :value="value" class="shortField" placeholder="0"
                   @change="updateValue(index, $event)">
          </calc-field>
        </calc-field-box>

        <div class="calcFieldBox" v-if="values.length === 1 && $v.values.$each[0].$invalid">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Input multiple values to be combined. After inputting a number, it will appear on the table below. Then add
            additional values as needed.
          </div>
        </div>

        <div class="buttonContainer">
          <button class="button clear-button" style="margin-top: 0" @click="addValue()">Add a Value</button>
        </div>
      </form>
    </section>

    <section id="calculator-results" v-if="$v.$anyDirty && !$v.$error">
      <h2>Results</h2>
      <div class="resultTableBox">
        <table id="intermediateResults">
          <caption>Intermediate Results:</caption>
          <colgroup>
            <col>
            <col>
          </colgroup>
          <thead>
          <tr>
            <th scope="col">Values Sorted</th>
            <th scope="col">Values Combined</th>
          </tr>
          </thead>
          <tbody>
          <tr v-for="(value, index) in sortedValues" :key="index" v-if="value">
            <td>{{ value | toPercent | toFixed(0) | percentSuffix }}</td>
            <td>{{ (index === 0 ? undefined : combined[index]) | toPercent | toFixed(0) | percentSuffix }}</td>
          </tr>
          </tbody>
        </table>
      </div>
      <div class="resultContainer lastResult" v-if="finalCombinedValue">
        <div class="resultLabel">Final Combined Value:</div>
        <div class="resultValue">{{ finalCombinedValue | toPercent | toFixed(0) | percentSuffix }}</div>
      </div>
    </section>
  </div>
</template>

<script>
  import { filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, between } from 'vuelidate/lib/validators';
  import valueCombiner from './value-combiner';

  export default {
    mixins: [filters, methods, components, validationMixin],
    data() {
      return {
        values: Array.apply(null, Array(1))
      }
    },
    computed: {
      sortedValues() {
        return this.values.map(v => v === undefined ? undefined : v / 100).sort((a, b) => (b || 0) - (a || 0));
      },
      combined() {
        return this.sortedValues.reduce(valueCombiner, []);
      },
      finalCombinedValue() {
        return this.combined.slice(1).reverse().find(v => v);
      }
    },
    filters: {
      percentSuffix(value) {
        if (value) {
          return `${value}%`;
        }
      }
    },
    methods: {
      addValue() {
        this.values.push(undefined);
      },
      updateValue(index, event) {
        this.$set(this.values, index, parseInt(event.target.value, 10));
        this.$v.values.$each[index].$touch();
      },
      removeValue(index) {
        this.values.splice(index, 1);
      }
    },
    directives: {
      focus: {
        inserted: function (el) {
          el.focus()
        }
      }
    },
    validations: {
      values: {
        required,
        $each: {
          required, between: between(1, 99)
        }
      }
    }
  }
</script>
