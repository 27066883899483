import Awards from './interest-on-awards.vue';
import CombinedValue from './combined-value.vue';
import CommutationDb from './commutations/death-benefits.vue';
import CommutationLp from './commutations/life-pension.vue';
import CommutationPd from './commutations/permanent-partial-disability.vue';
import CommutationPtd from './commutations/permanent-total-disability.vue';
import DateWheel from './date-wheel.vue';
import DeathBenefits from './death-benefits.vue';
import LatePmt from './interest-and-penalty.vue';
import LifeExpectancy from './life-expectancy.vue';
import MoneyChart from './money-chart.vue';
import PermanentDisability from './permanent-disability.vue';
import PresentValue from './present-value.vue';
import PvOfPdAndLp from './pv-of-pd-and-lp.vue';
import PvOfPtd from './pv-of-ptd.vue';
import Ttd from './temporary-total-disability.vue';

export const components = {
  'awards': Awards,
  'combined-value': CombinedValue,
  'commutation-db': CommutationDb,
  'commutation-lp': CommutationLp,
  'commutation-pd': CommutationPd,
  'commutation-ptd': CommutationPtd,
  'date-wheel': DateWheel,
  'death-benefits': DeathBenefits,
  'late-pmt': LatePmt,
  'life-expectancy': LifeExpectancy,
  'money-chart': MoneyChart,
  'pd': PermanentDisability,
  'present-value': PresentValue,
  'pv-of-pd-and-lp': PvOfPdAndLp,
  'pv-of-ptd': PvOfPtd,
  'ttd': Ttd
};

export { default as store } from './store';
export { default as formDataParser } from './form-data-parser';
