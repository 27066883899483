<template>
  <span>
    <template v-if="period ==='before'">
      <i class="far fa-arrow-up"></i>
      <span>No RTW Adjustments for injuries before 1/1/2005.</span>
    </template>
    <template v-else-if="period === 'after'">
      <i class="far fa-arrow-up"></i>
      <span>No RTW Adjustments for injuries on/after 1/1/2013.</span>
    </template>
    <template v-else-if="period === 'during'">
      <span v-if="jobOffered === 'na'">
        <i class="far fa-arrow-up"></i>
        No RTW adjustment applied for employers with fewer than 50 employees. (For more detail see
        <soc-section section="11.6"/>.)
      </span>
      <section v-else>
      <template v-if="pdRating === 0">
        <p>
          <i class="far fa-arrow-up"></i>
          No RTW adjustment applied because PD is 0%.
        </p>
      </template>
        <i class="far fa-arrow-up"></i>
        <span v-if="jobOffered === 'yes'">15% decrease of indemnity payments following offer to return to work.</span>
        <span v-if="jobOffered === 'no'">
          15% increase of indemnity payments after the first 60 days following the disability becoming Permanent &
          Stationary.
        </span>
      </section>
    </template>
  </span>
</template>

<script>
  import SocSection from '../templates/soc-section';
  import rtwAdjustmentPeriod from './rtw-adjustment-period';

  export default {
    props: {
      dateOfInjury: {
        type: Date,
        required: true
      },
      jobOffered: {
        type: String
      },
      pdRating: {
        type: Number
      }
    },
    computed: {
      period() {
        return rtwAdjustmentPeriod(this.dateOfInjury);
      }
    },
    components: { SocSection }
  };
</script>
