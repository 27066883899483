<template>
  <a :href="href" target="_blank" tabindex="-1">SOC Section {{ section }}</a>
</template>

<script>
  export default {
    props: {
      section: {
        type: String,
        required: true
      }
    },
    computed: {
      href() {
        return `/soc/index/title/${this.section}`;
      }
    }
  }
</script>
