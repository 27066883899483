var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "pv-of-pd-and-lp-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfBirth.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-birth",
                    error: _vm.errorMessage("dateOfBirth"),
                  },
                },
                [
                  _vm._v("\n          Date of Birth:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the Injured Worker's Date of Birth."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-birth",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yyyy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfBirth.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfBirth,
                      callback: function ($$v) {
                        _vm.dateOfBirth = $$v
                      },
                      expression: "dateOfBirth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.gender.$error } },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("gender") } },
                [
                  _vm._v("\n          Gender:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Select the Injured Worker's gender. (This information is needed for the Life Expectancy lookup.)\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Male", id: "male" },
                      domProps: { checked: _vm._q(_vm.gender, "Male") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.gender = "Male"
                          },
                          function ($event) {
                            return _vm.$v.gender.$touch()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "male" }, slot: "label" },
                      [_vm._v("Male")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Female", id: "female" },
                      domProps: { checked: _vm._q(_vm.gender, "Female") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.gender = "Female"
                          },
                          function ($event) {
                            return _vm.$v.gender.$touch()
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "female" },
                        slot: "label",
                      },
                      [_vm._v("Female")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customRatedAge.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.ratedAgeOption === "adjust",
                },
              },
            },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("customRatedAge") } },
                [
                  _vm._v('\n          Adjustment for "Rated Age"\n          '),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            To calculate the Injured Worker's life expectancy using a \"Rated Age,\" input the number of years the\n            person's life expectancy is anticipated to decrease as a result of illness or injury. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "14.83" } }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "none", id: "none" },
                      domProps: { checked: _vm._q(_vm.ratedAgeOption, "none") },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "none"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "none" }, slot: "label" },
                      [_vm._v("None")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "adjust", id: "adjust" },
                      domProps: {
                        checked: _vm._q(_vm.ratedAgeOption, "adjust"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "adjust"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "adjust" },
                        slot: "label",
                      },
                      [_vm._v("Adjust")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customRatedAge,
                              expression: "customRatedAge",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Years Reduced" },
                          domProps: { value: _vm.customRatedAge },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customRatedAge = _vm._n($event.target.value)
                              },
                              function ($event) {
                                return _vm.$v.customRatedAge.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yyyy",
                      disabledDates: {
                        to: _vm.dateOfBirth,
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasReturnToWorkAdjustment
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.jobOffered.$error } },
                [
                  _c(
                    "calc-label",
                    { attrs: { error: _vm.errorMessage("jobOffered") } },
                    [
                      _vm._v(
                        "\n          Was a Job Offered within 60 Days of P&S Date?:\n          "
                      ),
                      _c(
                        "template",
                        { slot: "tooltip" },
                        [
                          _vm._v(
                            "\n            Was an offer made to the Injured Worker to return to work by the employer within 60 days after the\n            P&S date? (If the employer has fewer than 50 employees, select N/A.) See "
                          ),
                          _c("soc-section", { attrs: { section: "11.6" } }),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "yes",
                            id: "offered-yes",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "yes") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "yes"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-yes" },
                            slot: "label",
                          },
                          [_vm._v("Yes")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "no",
                            id: "offered-no",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "no") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "no"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-no" },
                            slot: "label",
                          },
                          [_vm._v("No")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "na",
                            id: "offered-na",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "na") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "na"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-na" },
                            slot: "label",
                          },
                          [_vm._v("N/A")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdCommencementDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "pd-commencement-date",
                    error: _vm.errorMessage("pdCommencementDate"),
                  },
                },
                [
                  _vm._v("\n          PD Commencement Date:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the date that Permanent Disability payments began."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "pd-commencement-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yyyy",
                      disabledDates: { to: _vm.dateOfInjury },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.pdCommencementDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.pdCommencementDate,
                      callback: function ($$v) {
                        _vm.pdCommencementDate = $$v
                      },
                      expression: "pdCommencementDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.hasReturnToWorkAdjustment && _vm.jobOffered === "yes"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.rtwDate.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "rtw-date",
                        error: _vm.errorMessage("rtwDate", {
                          min: "the PD Commencement Date",
                          max: "60 days thereafter (%{max})",
                        }),
                      },
                    },
                    [
                      _vm._v("\n          Return to Work:\n          "),
                      _c(
                        "template",
                        { slot: "tooltip" },
                        [
                          _vm._v(
                            "\n            What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,\n            leave this blank.) See "
                          ),
                          _c("soc-section", { attrs: { section: "11.6" } }),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("date-picker", {
                        attrs: {
                          format: _vm.americanDateFormatter,
                          id: "rtw-date",
                          "input-class": "shortField",
                          typeable: true,
                          placeholder: "mm/dd/yyyy",
                          "open-date": _vm.pdCommencementDate,
                          "disabled-dates": {
                            to: _vm.pdCommencementDate,
                            from: _vm.addDays(_vm.pdCommencementDate, 60),
                          },
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.rtwDate.$touch()
                          },
                        },
                        model: {
                          value: _vm.rtwDate,
                          callback: function ($$v) {
                            _vm.rtwDate = $$v
                          },
                          expression: "rtwDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdRating.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "pd-rating",
                    error: _vm.errorMessage("pdRating"),
                  },
                },
                [
                  _vm._v("\n          PD Rating:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the PD rating as a percentage."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.pdRating,
                      expression: "pdRating",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: {
                    type: "number",
                    id: "pd-rating",
                    min: "1",
                    max: "99",
                  },
                  domProps: { value: _vm.pdRating },
                  on: {
                    change: [
                      function ($event) {
                        _vm.pdRating = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.pdRating.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfCalculation.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-calculation",
                    error: _vm.errorMessage("dateOfCalculation", {
                      min: "PD Commencement Date (%{min})",
                    }),
                  },
                },
                [
                  _vm._v("\n          Date of Calculation:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the date from which you are calculating the present value."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-calculation",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yyyy",
                      disabledDates: { to: _vm.pdCommencementDate },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfCalculation.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfCalculation,
                      callback: function ($$v) {
                        _vm.dateOfCalculation = $$v
                      },
                      expression: "dateOfCalculation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customAverageWeeklyEarnings.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.wageOption === "custom",
                },
              },
            },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    error: _vm.errorMessage("customAverageWeeklyEarnings"),
                  },
                },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Select Custom to input the Average Weekly Earnings for the injured worker. If the AWE you enter is outside\n            applicable limits, it will be modified accordingly and the updated value displayed in the Results section.\n            Or, select Max if you know that the AWE is high enough that the applicable statutory limits would apply.\n            Note that different limits apply to Permanent Disability and when applicable, Life Pension. If you are\n            unsure whether the actual AWE value exceeds the limits for both, input the actual AWE value instead. (See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "8.6" } }),
                      _vm._v(" for more detail on AWE.)\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "max", id: "awe-max" },
                      domProps: { checked: _vm._q(_vm.wageOption, "max") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "max"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-max" },
                        slot: "label",
                      },
                      [_vm._v("Max")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "custom",
                        id: "awe-custom",
                      },
                      domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                      on: {
                        change: function ($event) {
                          _vm.wageOption = "custom"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-custom" },
                        slot: "label",
                      },
                      [_vm._v("Custom")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("abbr", [_vm._v("$")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customAverageWeeklyEarnings,
                              expression: "customAverageWeeklyEarnings",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Enter Amount" },
                          domProps: { value: _vm.customAverageWeeklyEarnings },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customAverageWeeklyEarnings = _vm._n(
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$v.customAverageWeeklyEarnings.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.wageOption === "max"
            ? _c("calc-field-box", [
                _c("div", { staticClass: "formFieldNotation" }, [
                  _c("i", { staticClass: "far fa-arrow-up" }),
                  _vm._v(
                    "\n          Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can\n          differ. To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.\n        "
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.interestRate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "interest-rate",
                    error: _vm.errorMessage("interestRate"),
                  },
                },
                [
                  _vm._v("\n          Annual Discount Rate:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Input the annual interest rate to use for the present value discount calculation.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.interestRate,
                      expression: "interestRate",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "interest-rate" },
                  domProps: { value: _vm.interestRate },
                  on: {
                    change: [
                      function ($event) {
                        _vm.interestRate = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.interestRate.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.assumedAnnualIncreases.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "assumed-annual-increases",
                    error: _vm.errorMessage("assumedAnnualIncreases"),
                  },
                },
                [
                  _vm._v("\n          Assumed COLA Increases:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Input the average annual increase you expect will apply to COLA calculations.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.assumedAnnualIncreases,
                      expression: "assumedAnnualIncreases",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "assumed-annual-increases" },
                  domProps: { value: _vm.assumedAnnualIncreases },
                  on: {
                    change: [
                      function ($event) {
                        _vm.assumedAnnualIncreases = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.assumedAnnualIncreases.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Calculation Mode:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Choose the method for calculating the present value of PD payments:\n            "
                    ),
                    _c("ul", [
                      _c("li", [
                        _c("b", [_vm._v("Reduce for Mortality")]),
                        _vm._v(
                          " - reduces the expected payout in each future year to account for the\n                portion of the Injured Worker's cohort that will no longer be alive in that year. This is the\n                actuarily accurate approach and results in a lower calculated present value.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("b", [_vm._v("Full Value through Life Expectancy")]),
                        _vm._v(
                          " - assumes 100% of all PD and LP payments are received by the\n                Injured Worker in the future up to the date of the life expectancy cut-off. This results in a higher\n                calculated present value.\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reducePd,
                          expression: "reducePd",
                        },
                      ],
                      attrs: { type: "radio", value: "yes", id: "reduce-pd" },
                      domProps: { checked: _vm._q(_vm.reducePd, "yes") },
                      on: {
                        change: function ($event) {
                          _vm.reducePd = "yes"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "reduce-pd" },
                        slot: "label",
                      },
                      [_vm._v("Reduce for Mortality")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reducePd,
                          expression: "reducePd",
                        },
                      ],
                      attrs: { type: "radio", value: "no", id: "no-reduce-pd" },
                      domProps: { checked: _vm._q(_vm.reducePd, "no") },
                      on: {
                        change: function ($event) {
                          _vm.reducePd = "no"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "no-reduce-pd" },
                        slot: "label",
                      },
                      [_vm._v("Full Value through Life Expectancy")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Calculation Mode"),
              ]),
              _vm._v(" "),
              _vm.reducePd === "yes"
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v("Reduce for Mortality"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.reducePd === "no"
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v("Full Value through Life Expectancy"),
                  ])
                : _vm._e(),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.ratedAgeOption === "none"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Age on date of calculation"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ratedAgeOption === "adjust"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v('"Rated Age" on date of calculation'),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("toFixed")(_vm.ageOnDateOfCalculation, 2))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Life Expectancy on date of calculation"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.lifeTableLookup, 2))),
              ]),
            ]),
            _vm._v(" "),
            _vm.hasReturnToWorkAdjustment
              ? [
                  _c("h3", [_vm._v("Return to Work Adjustments")]),
                  _vm._v(" "),
                  _c("calc-field-box", [
                    _c(
                      "div",
                      { staticClass: "formFieldNotation" },
                      [
                        _c("i", { staticClass: "far fa-arrow-up" }),
                        _vm._v(" "),
                        _c("rtw-adjustments", {
                          attrs: {
                            "date-of-injury": _vm.dateOfInjury,
                            "job-offered": _vm.jobOffered,
                          },
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _vm.rtwModifier
                    ? [
                        _c("calc-field-box", { staticClass: "comment" }, [
                          _c("div", { staticClass: "formFieldNotation" }, [
                            _c("i", { staticClass: "far fa-arrow-up" }),
                            _vm._v(
                              "\n            PD values shown below reflect RTW adjustments.\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("Weeks Paid at Base Rate"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.rtwCalculations.weeksPaidAtBaseRate.weeks
                                ) +
                                " Weeks,\n            " +
                                _vm._s(
                                  _vm._f("toFixed")(
                                    _vm.rtwCalculations.weeksPaidAtBaseRate
                                      .days,
                                    0
                                  )
                                ) +
                                " Days\n          "
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("RTW Modifier Used"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(_vm._s(_vm.rtwModifier)),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("h3", [_vm._v("Permanent Disability")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Total weeks of Permanent Disability"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm.totalWeeksOfPd)),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Average Weekly Earnings"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("currency")(_vm.averageWeeklyEarnings.awe.pd)
                    ) +
                    " " +
                    _vm._s(_vm.averageWeeklyEarnings.suffix.pd) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.hasReturnToWorkAdjustment && _vm.rtwModifier
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PD weekly rate during base period"),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PD weekly rate"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.weeklyPdRate))),
              ]),
            ]),
            _vm._v(" "),
            _vm.hasReturnToWorkAdjustment && _vm.rtwModifier
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PD weekly rate after RTW adjustment"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("currency")(
                          _vm.rtwCalculations.weeklyPdCompensation
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.hasReturnToWorkAdjustment && _vm.rtwModifier
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Total all PD"),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Total PD"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.totalPd))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Weeks of PD already paid"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.weeksOfPdAlreadyPaid, 2))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("PD benefits already paid"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.pdBenefitsAlreadyPaid))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Weeks of PD remaining"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.weeksOfPdRemaining, 2))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Balance of PD due (without PV discount)"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.balanceOfPdDueWithoutDiscount))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Present Value of remaining Permanent Disability"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.presentValueOfRemainingPd))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Difference between Balance Due and PV of remaining PD"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.differenceBalanceDueAndPV))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("h3", [_vm._v("Life Pension")]),
            _vm._v(" "),
            _vm.averageWeeklyEarnings.limitsApplied && _vm.pdRating >= 70
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Average Weekly Earnings"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("currency")(_vm.averageWeeklyEarnings.awe.lp)
                        ) +
                        " " +
                        _vm._s(_vm.averageWeeklyEarnings.suffix.lp) +
                        "\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Date Life Pension benefits start"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm.americanDateFormatter(_vm.lpStartDate))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.isDOCAfterLpStartDate
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Life Pension weekly rate at start date"),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Life Pension weekly rate"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.lpRate))),
              ]),
            ]),
            _vm._v(" "),
            _vm.pdRating >= 70
              ? [
                  _vm.isDOCAfterLpStartDate
                    ? [
                        _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v(
                              "Life Pension weekly rate on date of calculation"
                            ),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(_vm._s(_vm._f("currency")(_vm.lpRateOnDoc))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("Weeks of Life Pension already paid"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("toFixed")(_vm.weeksOfLpAlreadyPaid, 2)
                              )
                            ),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("Life Pension benefits already paid"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              _vm._s(
                                _vm._f("currency")(_vm.lpBenefitsAlreadyPaid)
                              )
                            ),
                          ]),
                        ]),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.isDOCAfterLpStartDate
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v(
                      "\n        Gross value of expected remaining Life Pension payments with COLA applied\n      "
                    ),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v(
                      "Gross value of expected Life Pension payments with COLA applied"
                    ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.sumOfLifePensionWithCola))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.isDOCAfterLpStartDate
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v(
                      "\n        Present Value of expected remaining Life Pension payments with COLA applied\n      "
                    ),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v(
                      "Present Value of expected Life Pension payments with COLA applied"
                    ),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.pvOfLifePensionWithCola))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Difference between Gross and Present Values of expected LP payments"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.differenceGrossAndPresentValuesExpectedLP
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _vm.pdRating < 70
              ? _c("calc-field-box", [
                  _c("div", { staticClass: "formFieldNotation" }, [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n        PD is under 70%, so this rating does not qualify for a life pension.\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("h3", [_vm._v("Total Value Future Payments")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Gross value of PD and Life Pension with COLA applied"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.grossTotalValue))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Gross total present value of PD and Life Pension with COLA applied"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.grossTotalPresentValueWithCola))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Difference between Gross and PV of expected future PD and LP payments"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(_vm.differenceGrossAndPVexpectedFuturePD)
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "pv-of-pd-and-lp-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }