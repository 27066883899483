<template>
  <calc-field-box>
    <calc-field-box>
      <calc-label>
        Name of Injured Worker:
        <template slot="tooltip">
          Enter the name of the injured worker for whom you are preparing a calculation.
        </template>
      </calc-label>
      <calc-field>
        <input type="text" id="worker-first-name" name="data[worker][firstName]" placeholder="First" ref="firstName"
               :value="value.firstName" @input="updateName" style="width: 45%; float: left">
        <input type="text" id="worker-last-name" name="data[worker][lastName]" placeholder="Last" ref="lastName"
               :value="value.lastName" @input="updateName" style="width: 45%; float: right">
      </calc-field>
    </calc-field-box>
  </calc-field-box>
</template>

<script>
  import CalcFieldBox from './calc-field-box';
  import CalcLabel from './calc-label';
  import CalcField from './calc-field';

  export default {
    props: ['value'],
    methods: {
      updateName() {
        this.$emit('input', {
          firstName: this.$refs.firstName.value,
          lastName: this.$refs.lastName.value
        });
      }
    },
    components: { CalcFieldBox, CalcLabel, CalcField }
  }
</script>
