var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "pretty p-default p-round p-has-focus",
      class: _vm.containerClass,
      style: _vm.elStyle,
    },
    [
      _vm._t("default"),
      _vm._v(" "),
      _c("div", { staticClass: "state p-success-o" }, [_vm._t("label")], 2),
      _vm._v(" "),
      _vm._t("custom"),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }