<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="commutation-lp-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />
        <calc-field-box>
          <calc-label>
            Calculate Commutation of:
            <template slot="tooltip">
              Select the type of commutation you wish to perform:
              <ul>
                <li>
                  Choose <b>All Remaining Life Pension</b> to calculate the lump-sum payment received in exchange for
                  forgoing all remaining Life Pension payments.
                </li>
                <li>
                  Choose <b>Life Pension "Off the Side"</b> to determine the reduction in recurring life pension
                  payments required to receive a specified lump-sum payment.
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input type="radio" v-model="calculationType" id="all-remaining" value="all-remaining">
              <label for="all-remaining" slot="label">
                All Remaining Life Pension
              </label>
            </pretty-radio>
            <pretty-radio>
              <input type="radio" v-model="calculationType" id="portion-remaining" value="portion-remaining">
              <label for="portion-remaining" slot="label">
                Life Pension "Off the Side"
              </label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfBirth.$error">
          <calc-label target="date-of-birth" :error="errorMessage('dateOfBirth')">
            Date of Birth:
            <template slot="tooltip">Enter the Injured Worker's Date of Birth.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfBirth" id="date-of-birth"
                         input-class="shortField" @input="$v.dateOfBirth.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Gender:
            <template slot="tooltip">
              Enter the Injured Worker's gender. This is used in conjunction with Life Expectancy data to determine the
              appropriate calculations for the commutation.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Male" id="male">
              <label for="male" slot="label">Male</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Female" id="female">
              <label for="female" slot="label">Female</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customRatedAge.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': ratedAgeOption === 'adjust' }">
          <calc-label :error="errorMessage('customRatedAge')">
            Adjustment for "Rated Age"
            <template slot="tooltip">
              To calculate the Injured Worker's life expectancy using a "Rated Age," input the number of years the
              person's life expectancy is anticipated to decrease as a result of illness or injury. See
              <soc-section section="14.83"/>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="none" id="none">
              <label for="none" slot="label">None</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="adjust" id="adjust">
              <label for="adjust" slot="label">Adjust</label>
              <div class="reveal-if-active" slot="custom">
                <input v-model.number.lazy="customRatedAge" class="conditional-input" type="text"
                       placeholder="Years Reduced" @change="$v.customRatedAge.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabled-dates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdCommencementDate.$error">
          <calc-label target="lp-start-date"
                      :error="errorMessage('pdCommencementDate', { min: 'the Date of Injury' })">
            PD Commencement Date:
            <template slot="tooltip">Enter the date on which permanent disability payments began.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="lp-start-date"
                         input-class="shortField" @input="$v.pdCommencementDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdRating.$error">
          <calc-label target="pd-rating" :error="errorMessage('pdRating')">
            PD Rating:
            <template slot="tooltip">
              Enter the PD % value. Obtain this from a calculated rating string or combined rating. If you need to
              calculate the PD Rating, use the <calculator-link name="pd"/>.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="pdRating" type="number" id="pd-rating" min="70" max="99" class="shortField"
                   @change="$v.pdRating.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select <b>Custom</b> to input the Average Weekly Earnings for the injured worker. If the AWE you enter is
              outside applicable limits, it will be modified accordingly and the updated value displayed in the Results
              section. Or, select <b>Max</b> if you know that the AWE is high enough that the applicable statutory
              limits would apply.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation" :error="errorMessage('dateOfCalculation')">
            Date of Commutation (DOC):
            <template slot="tooltip">Enter the date of the commutation payment.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType === 'portion-remaining'" :error="$v.amountToCommute.$error"
          @change="$v.amountToCommute.$touch()" >
          <calc-label target="amount-to-commute" :error="errorMessage('amountToCommute')">
            Amount to Commute:
            <template slot="tooltip">
              Enter the specific lump sum payment to be received in exchange for commuting a portion of the payments.
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="amountToCommute" type="text" id="amount-to-commute" class="shortField"
                   @input="$v.amountToCommute.$touch()">
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>

      <div class="resultContainer">
        <div class="resultLabel">Commutation Type:</div>
        <div class="resultValue" v-if="calculationType === 'all-remaining'">All Remaining Life Pension</div>
        <div class="resultValue" v-else>Life Pension "Off the Side"</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel" v-if="ratedAgeOption === 'none'">Age on date of commutation:</div>
        <div class="resultLabel" v-if="ratedAgeOption === 'adjust'">"Rated Age" on date of commutation:</div>
        <div class="resultValue">{{ ageInYearsAtDoc | toFixed(2) }}</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings:</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.lp | currency }} {{ averageWeeklyEarnings.suffix.lp }}
        </div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">Life Pension start date:</div>
        <div class="resultValue">{{ americanDateFormatter(lpStartDate) }}</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">Life Pension weekly rate at LP start date:</div>
        <div class="resultValue">{{ lpRate | currency }}</div>
      </div>

      <template v-if="dateOfCalculation > lpStartDate">
        <div class="resultContainer">
          <div class="resultLabel">Days from commencement of LP through DOC:</div>
          <div class="resultValue">{{ daysBetweenLpStartDateAndDoc.toLocaleString() }}</div>
        </div>

        <div class="resultContainer">
          <div class="resultLabel">Weeks from commencement of LP through DOC:</div>
          <div class="resultValue">{{ daysBetweenLpStartDateAndDoc / 7 | toFixed(2) }}</div>
        </div>

        <div class="resultContainer">
          <div class="resultLabel">Life Pension weekly rate on DOC:</div>
          <div class="resultValue">{{ lpRateOnDoc | currency }}</div>
        </div>

        <div class="resultContainer">
          <div class="resultLabel">LP paid prior to DOC:</div>
          <div class="resultValue">{{ lpPaidPriorToDoc | currency }}</div>
        </div>
      </template>

      <div v-if="calculationType === 'all-remaining' && deferment <= 0">
        <div class="resultContainer">
          <div class="resultLabel">Commuted value of all Life Pension due after DOC:</div>
          <div class="resultValue">{{ commutedValueOfAllLp | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due per day for late payment after DOC:</div>
          <div class="resultValue">{{ commutedValueOfAllLp * 0.1 / 365 | currency }}</div>
        </div>
      </div>

      <div v-if="calculationType === 'all-remaining' && deferment > 0">
        <div class="resultContainer">
          <div class="resultLabel">Years from DOC until Life Pension begins:</div>
          <div class="resultValue">{{ deferment | toFixed(3) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Commuted value of all Life Pension as of DOC:</div>
          <div class="resultValue">{{ commutedValueOfAllLp | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due per day for late payment after DOC:</div>
          <div class="resultValue">{{ commutedValueOfAllLp * 0.1 / 365 | currency }}</div>
        </div>
      </div>

      <div v-if="calculationType === 'portion-remaining' && deferment > 0">
        <div class="resultContainer">
          <div class="resultLabel">Years from DOC until Life Pension begins:</div>
          <div class="resultValue">{{ deferment | toFixed(3) }}</div>
        </div>
      </div>

      <div v-if="calculationType === 'portion-remaining'">
        <div class="resultContainer">
          <div class="resultLabel">Amount of weekly reduction in Life Pension:</div>
          <div class="resultValue">{{ weeklyReductionInLifePension | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Starting Life Pension weekly rate after commutation:</div>
          <div class="resultValue">{{ lpRateAfterCommutation | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due for each day late:</div>
          <div class="resultValue">{{ amountToCommute * 0.1 / 365 | currency }}</div>
        </div>
      </div>

      <save-button form="commutation-lp-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import { differenceInDays, startOfDay, subHours, format as dateFormat, max, getYear } from 'date-fns';
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { roundFloat } from '../../util/float';
  import { props, provide, components, filters, methods } from '../mixins';
  import { validationMixin } from 'vuelidate';
  import { between, integer, maxValue, minValue, required } from 'vuelidate/lib/validators';
  import { greaterThan } from '../validators';
  import AverageWeeklyEarnings from '../permanent-disability/average-weekly-earnings';
  import PdDates from '../permanent-disability/dates';
  import { PaymentYear, totalPayments } from '../saww_payments';
  import saveMixin from '../saveable/commutation-lp';

  const resettableData = () => (
    {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      calculationType: 'all-remaining',
      dateOfBirth: null,
      dateOfInjury: null,
      pdCommencementDate: null,
      pdRating: null,
      wageOption: 'custom',
      customAverageWeeklyEarnings: null,
      ratedAgeOption: 'none',
      customRatedAge: null,
      dateOfCalculation: null,
      gender: 'Male',
      amountToCommute: null,
      pvOfLpAsOfAgeAtDoc: null,
      totalWeeksOfPd: null,
      lifePensionEarningMax: null,
      results: false,
      saveStatus: null,
      saveMessage: null
    }
  );

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    mounted() {
      axios.get('/state_average_weekly_wages').then(({ data }) => {
        this.wages = data.reduce((memo, { year, real_increase }) => {
          memo[year] = parseFloat(real_increase);
          return memo;
        }, {});
      });
    },
    data() {
      return Object.assign({
        wages: {},
      }, resettableData(), this.form);
    },
    asyncComputed: {
      pvOfLpAsOfAgeAtDoc: {
        get() {
          const params = {
            age: this.ageInYearsAtDoc.toFixed(3), gender: this.gender, deferment: this.deferment.toFixed(3)
          };
          return axios.get('/pv_of_lp_tables/interpolate_present_value', { params }).then(r => roundFloat(r.data, 2));
        },
        shouldUpdate() {
          return !(this.$v.dateOfBirth.$invalid || this.$v.dateOfCalculation.$invalid);
        },
        default: 0
      },
      totalWeeksOfPd: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/pdd_durations/lookup', { params }).then(r => parseFloat(r.data));
        },
        shouldUpdate() {
          return !(this.$v.pdRating.$invalid || this.$v.dateOfInjury.$invalid);
        },
        default: 0
      },
      lifePensionEarningMax: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/life_pension_limits/lookup', { params })
            .then(({ data: { value }}) => ({ value: parseFloat(value) }));
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { value: 0 }
      }
    },
    computed: {
      maxSawwYear() {
        return Math.max(...Object.keys(this.wages).map(year => parseInt(year, 10)));
      },
      adjustedDOB() {
        const factor = { none: 0, adjust: this.customRatedAge }[this.ratedAgeOption];
        return startOfDay(subHours(this.dateOfBirth, factor * 365.24 * 24));
      },
      daysBetweenDocAndDateOfBirth() {
        return differenceInDays(this.dateOfCalculation, this.adjustedDOB);
      },
      pdDates() {
        return new PdDates(this.pdCommencementDate, this.totalWeeksOfPd);
      },
      lpStartDate() {
        return this.pdDates.lpStartDate;
      },
      daysBetweenLpStartDateAndDoc() {
        return differenceInDays(this.dateOfCalculation, this.lpStartDate);
      },
      deferment() {
        return Math.max(0, roundFloat(this.pdDates.differenceInDaysToLpStartDate(this.dateOfCalculation), 1) / 365.24);
      },
      ageInYearsAtDoc() {
        return this.daysBetweenDocAndDateOfBirth / 365.24;
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          this.pdRating, this.wageOption, this.customAverageWeeklyEarnings, {}, this.lifePensionEarningMax
        );
      },
      lpRate() {
        return roundFloat(this.averageWeeklyEarnings.lpRate, 2);
      },
      lpRateOnDoc() {
        const year = getYear(this.dateOfCalculation);
        const paymentYear = new PaymentYear(this.wages, year, this.lpRate, this.lpStartDate, this.dateOfCalculation);
        return paymentYear.rate;
      },
      lpPaidPriorToDoc() {
        return totalPayments(this.wages, this.lpRate, this.lpStartDate, this.dateOfCalculation);
      },
      commutedValueOfAllLp() {
        return this.pvOfLpAsOfAgeAtDoc * this.effectiveRate;
      },
      effectiveRate() {
        return this.dateOfCalculation > this.lpStartDate ? this.lpRateOnDoc : this.lpRate;
      },
      weeklyReductionInLifePension() {
        return this.amountToCommute / this.pvOfLpAsOfAgeAtDoc;
      },
      lpRateAfterCommutation() {
        return this.effectiveRate - this.weeklyReductionInLifePension;
      }
    },
    methods: {
      clearForm() {
        Object.assign(this.$data, resettableData());
        this.$v.$reset();
      }
    },
    validations: {
      dateOfBirth: { required, minValue: minValue(new Date(1900, 0, 1)) },
      dateOfInjury: {
        required,
        minValue(value) { return minValue(max(this.dateOfBirth, new Date(2003, 0, 1)))(value) },
        maxValue(value) { return maxValue(methods.methods.endOfYear())(value) }
      },
      pdCommencementDate: { required, minValue(value) { return minValue(this.dateOfInjury)(value) } },
      pdRating: { required, integer, between: between(70, 99) },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value) : true },
        minValue: minValue(0)
      },
      dateOfCalculation: {
        required,
        minValue(value) { return minValue(this.pdCommencementDate)(value) },
        maxValue(value) { return maxValue(new Date(this.maxSawwYear, 11, 31))(value) }
      },
      amountToCommute: {
        required(value) {
          return this.calculationType === 'portion-remaining' ? required(value) : true
        },
        minValue: minValue(0),
        maxValue(value) { return  maxValue(roundFloat(this.commutedValueOfAllLp))(value) }
      },
      customRatedAge: {
        required(value) { return this.ratedAgeOption === 'adjust' ? required(value) : true },
        greaterThan(value) { return this.ratedAgeOption === 'none' ? true : greaterThan(0)(value) }
      }
    },
    components: { DatePicker }
  }
</script>
