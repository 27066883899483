import { format as dateFormat } from 'date-fns';
import i18n from '../util/i18n';
import Polyglot from 'node-polyglot';
import { roundFloat } from '../util/float';
import get from 'lodash.get';

import CalculateButton from './calculate-button';
import CalcLabel from './templates/calc-label';
import CalcField from './templates/calc-field';
import CalcFieldBox from './templates/calc-field-box';
import CalculatorLink from './templates/calculator-link';
import PrettyRadio from './templates/pretty-radio';
import ClaimNumber from './templates/claim-number';
import InjuredWorker from './templates/injured-worker';
import SaveButton from './save-button';
import SocSection from './templates/soc-section';
import axios from 'axios';

const currencyFormatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 2
});

const dollarFields = ['amountToCommute', 'averageWeeklyEarnings', 'paymentAmount', 'maxBenefitAmount', 'billAmount'];

export const provide = {
  provide() {
    return {
      saveable: this.saveable,
      saveData: this.saveData
    }
  }
};

export const props = {
  props: {
    form: {
      type: Object,
      default() {
        return {};
      }
    },
    saveable: {
      type: Boolean,
      default: false
    }
  }
};

export const methods = {
  methods: {
    humanDateFormatter(date) {
      return dateFormat(date, 'dddd MM/DD/YYYY');
    },
    americanDateFormatter(date) {
      return dateFormat(date, 'MM/DD/YYYY');
    },
    endOfYear() {
      return new Date(new Date().getFullYear(), 11, 31)
    },
    defaultToDate() {
      return new Date(1983, 0, 1)
    },
    errorMessage(prop, overrides = {}) {
      const key = Object.keys(get(this.$v, prop).$params).find(key => !get(this.$v, prop)[key]);
      if (key) {
        if(key === 'nothingToCommute') {
          return i18n.t(`errors.nothingToCommute`);
        }
        const data = get(this.$v, prop).$params[key];
        const dates =
          Object.keys(data)
            .filter(key => Object.prototype.toString.call(data[key]) === '[object Date]')
            .reduce((memo, key) => {
              memo[key] = dateFormat(data[key], 'MM/DD/YYYY');
              return memo;
            }, {});

        const transformedOverrides = Object.keys(overrides).reduce((memo, key) => {
          memo[key] = Polyglot.transformPhrase(overrides[key], Object.assign({}, data, dates));
          return memo;
        }, {});


        let currencies = {};
        if(dollarFields.includes(prop)) {
          currencies =
            Object.keys(data)
              .reduce((memo, key) => {
                memo[key] = currencyFormatter.format(data[key]);
                return memo;
              }, {});
        }

        return i18n.t(`errors.${key}`, Object.assign({}, data, dates, currencies, transformedOverrides));
      }
    },
    calculate() {
      this.$v.$touch();
      if (this.$v.$invalid) { return }
      this.results = true;
      this.$nextTick(function () {
        this.$scrollTo('#calculator-results', 500, { offset: -100 });
      });
    },
    clearForm() {
      Object.assign(this.$data, this.$options.data.apply(this), { results: false });
      this.$v.$reset();
    },
    saveData() {
      this.saveStatus = null;
      this.$v.$touch();
      if (this.$v.$invalid) { return }

      const { claimNumber, worker } = this;
      const data = { ...this.extractInputData(), claimNumber, worker };
      const authenticity_token = document.querySelector('meta[name=csrf-token]').content;

      axios.post(document.location.pathname, { data, authenticity_token, format: 'json' })
        .then(({ data: { url } }) => {
          this.saveStatus = 'success';
          window.history.pushState({}, null, url)
        })
        .catch(() => {
          this.saveStatus = 'failure';
          this.saveMessage = 'Could not save calculator data';
        });
    },
    populateForm(data) {
      this.clearForm();
      Object.assign(this.$data, data);
    }
  }
};

export const filters = {
  filters: {
    currency(value) {
      return currencyFormatter.format(value);
    },
    toFixed(value, size) {
      if (value === null || value === undefined) { return null }
      return value.toFixed(size);
    },
    toPercent(value) {
      if (value === null || value === undefined) { return null }
      return value * 100;
    },
    commaSeparate(value, precision = 2) {
      return roundFloat(value, precision).toLocaleString();
    },
    formatDate(value) {
      return dateFormat(value, 'MM/DD/YYYY');
    }
  }
};

export const components = {
  components: {
    CalculateButton, CalcFieldBox, CalcLabel, CalcField, CalculatorLink, InjuredWorker, PrettyRadio, ClaimNumber,
    SaveButton, SocSection
  }
};
