var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("section", { attrs: { id: "calculator-form" } }, [
        _c(
          "form",
          {
            attrs: { id: "pd-form" },
            on: {
              submit: function ($event) {
                $event.preventDefault()
              },
            },
          },
          [
            _c("h2", [_vm._v("Report Information")]),
            _vm._v(" "),
            _c("claim-number", {
              on: { data: _vm.populateForm },
              model: {
                value: _vm.claimNumber,
                callback: function ($$v) {
                  _vm.claimNumber = $$v
                },
                expression: "claimNumber",
              },
            }),
            _vm._v(" "),
            _c(
              "calc-field-box",
              [
                _c(
                  "calc-label",
                  [
                    _vm._v("\n          Name of Doctor:\n          "),
                    _c("template", { slot: "tooltip" }, [
                      _vm._v(
                        "Enter the name of the doctor whose report you rating."
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c("calc-field", [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.doctor.firstName,
                        expression: "doctor.firstName",
                      },
                    ],
                    staticStyle: { width: "45%", float: "left" },
                    attrs: {
                      type: "text",
                      id: "doctor-first-name",
                      placeholder: "First",
                    },
                    domProps: { value: _vm.doctor.firstName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.doctor, "firstName", $event.target.value)
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.doctor.lastName,
                        expression: "doctor.lastName",
                      },
                    ],
                    staticStyle: { width: "45%", float: "right" },
                    attrs: {
                      type: "text",
                      id: "doctor-last-name",
                      placeholder: "Last",
                    },
                    domProps: { value: _vm.doctor.lastName },
                    on: {
                      input: function ($event) {
                        if ($event.target.composing) return
                        _vm.$set(_vm.doctor, "lastName", $event.target.value)
                      },
                    },
                  }),
                ]),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "calc-field-box",
              [
                _c(
                  "calc-label",
                  [
                    _vm._v("\n          Date of Doctor's Report:\n          "),
                    _c("template", { slot: "tooltip" }, [
                      _vm._v(
                        "Enter the date of the report that you are rating."
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "calc-field",
                  [
                    _c("date-picker", {
                      attrs: {
                        format: _vm.americanDateFormatter,
                        id: "date-of-report",
                        "input-class": "shortField",
                        typeable: true,
                        placeholder: "mm/dd/yy",
                      },
                      model: {
                        value: _vm.reportDate,
                        callback: function ($$v) {
                          _vm.reportDate = $$v
                        },
                        expression: "reportDate",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h2", [_vm._v("Employee Information")]),
            _vm._v(" "),
            _c("injured-worker", {
              model: {
                value: _vm.worker,
                callback: function ($$v) {
                  _vm.worker = $$v
                },
                expression: "worker",
              },
            }),
            _vm._v(" "),
            _c(
              "calc-field-box",
              { attrs: { error: _vm.$v.dateOfBirth.$error } },
              [
                _c(
                  "calc-label",
                  {
                    attrs: {
                      target: "date-of-birth",
                      error: _vm.errorMessage("dateOfBirth"),
                    },
                  },
                  [
                    _vm._v("\n          Date of Birth:\n          "),
                    _c("template", { slot: "tooltip" }, [
                      _vm._v("Enter the Injured Worker's Date of Birth."),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "calc-field",
                  [
                    _c("date-picker", {
                      attrs: {
                        format: _vm.americanDateFormatter,
                        id: "date-of-birth",
                        "input-class": "shortField",
                        typeable: true,
                        placeholder: "mm/dd/yyyy",
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.dateOfBirth.$touch()
                        },
                      },
                      model: {
                        value: _vm.dateOfBirth,
                        callback: function ($$v) {
                          _vm.dateOfBirth = $$v
                        },
                        expression: "dateOfBirth",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              "calc-field-box",
              { attrs: { error: _vm.$v.dateOfInjury.$error } },
              [
                _c(
                  "calc-label",
                  {
                    attrs: {
                      target: "date-of-injury",
                      error: _vm.errorMessage("dateOfInjury"),
                    },
                  },
                  [
                    _vm._v("\n          Date of Injury:\n          "),
                    _c(
                      "template",
                      { slot: "tooltip" },
                      [
                        _vm._v(
                          "\n            Enter the Injured Worker's Date of Injury. See "
                        ),
                        _c("soc-section", { attrs: { section: "5.5" } }),
                        _vm._v(
                          " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                        ),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "calc-field",
                  [
                    _c("date-picker", {
                      attrs: {
                        format: _vm.americanDateFormatter,
                        id: "date-of-injury",
                        "input-class": "shortField",
                        typeable: true,
                        placeholder: "mm/dd/yyyy",
                        "disabled-dates": {
                          to: new Date(1996, 0, 1),
                          from: _vm.endOfYear(),
                        },
                      },
                      on: {
                        input: function ($event) {
                          return _vm.$v.dateOfInjury.$touch()
                        },
                      },
                      model: {
                        value: _vm.dateOfInjury,
                        callback: function ($$v) {
                          _vm.dateOfInjury = $$v
                        },
                        expression: "dateOfInjury",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("div", { staticClass: "calcFieldBox comment" }, [
              _c(
                "div",
                { staticClass: "formFieldNotation" },
                [
                  _c("i", { staticClass: "far fa-arrow-up" }),
                  _vm._v(
                    "\n          For Continuous Trauma claims, enter the ending date of the CT period or the date determined in accordance\n          with LC 5412 (see "
                  ),
                  _c("soc-section", { attrs: { section: "5.5" } }),
                  _vm._v(").\n        "),
                ],
                1
              ),
            ]),
            _vm._v(" "),
            _vm.dateOfInjury
              ? _c(
                  "div",
                  {
                    staticClass: "descriptionTextArea",
                    staticStyle: { margin: "10px 0 20px 0" },
                  },
                  [
                    _vm._v(
                      "\n        This rating is calculated using the " +
                        _vm._s(_vm.scheduleYear) +
                        " Permanent Disability Rating Schedule\n      "
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "calc-field-box",
              { attrs: { error: _vm.$v.occupationGroup.$error } },
              [
                _c(
                  "calc-label",
                  { attrs: { error: _vm.errorMessage("occupationGroup") } },
                  [
                    _vm._v("\n          Occupation\n          "),
                    _c("template", { slot: "tooltip" }, [
                      _vm._v(
                        "Begin typing and then select the group number or occupation by name."
                      ),
                    ]),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "calc-field",
                  { attrs: { "container-class": "v-select" } },
                  [
                    _c("v-select", {
                      attrs: {
                        options: _vm.occupationCodes,
                        loading: _vm.occupationCodes.length === 0,
                        filterBy: _vm.loosenedSearchFilter,
                        clearable: false,
                        placeholder: "Search Group Numbers or Occupation Names",
                      },
                      model: {
                        value: _vm.occupationGroup,
                        callback: function ($$v) {
                          _vm.occupationGroup = $$v
                        },
                        expression: "occupationGroup",
                      },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c("h2", [_vm._v("Earnings Information")]),
            _vm._v(" "),
            _c(
              "calc-field-box",
              {
                attrs: {
                  error: _vm.$v.customAverageWeeklyEarnings.$error,
                  "container-class": {
                    "expanding-radio": true,
                    "expanding-radio-margin": _vm.wageOption === "custom",
                  },
                },
              },
              [
                _c(
                  "calc-label",
                  {
                    attrs: {
                      error: _vm.errorMessage("customAverageWeeklyEarnings"),
                    },
                  },
                  [
                    _vm._v("\n          Average Weekly Earnings:\n          "),
                    _c(
                      "template",
                      { slot: "tooltip" },
                      [
                        _vm._v("\n            Select "),
                        _c("b", [_vm._v("Custom")]),
                        _vm._v(
                          " to input the Average Weekly Earnings for the injured worker. If the AWE you enter is\n            outside applicable limits, it will be modified accordingly and the updated value displayed in the Results\n            section. Or, select "
                        ),
                        _c("b", [_vm._v("Max")]),
                        _vm._v(
                          " if you know that the AWE is high enough that the applicable statutory\n            limits would apply. Note that different limits apply to Permanent Disability and when applicable,\n            Life Pension. If you are unsure whether the actual AWE value exceeds the limits for both, input the actual\n            AWE value instead. (See "
                        ),
                        _c("soc-section", { attrs: { section: "8.6" } }),
                        _vm._v(" for more detail on AWE.)\n          "),
                      ],
                      1
                    ),
                  ],
                  2
                ),
                _vm._v(" "),
                _c(
                  "calc-field",
                  [
                    _c("pretty-radio", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.wageOption,
                            expression: "wageOption",
                          },
                        ],
                        attrs: { type: "radio", value: "max", id: "awe-max" },
                        domProps: { checked: _vm._q(_vm.wageOption, "max") },
                        on: {
                          change: function ($event) {
                            _vm.wageOption = "max"
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          attrs: { slot: "label", for: "awe-max" },
                          slot: "label",
                        },
                        [_vm._v("Max")]
                      ),
                    ]),
                    _vm._v(" "),
                    _c("pretty-radio", [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.wageOption,
                            expression: "wageOption",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          value: "custom",
                          id: "awe-custom",
                        },
                        domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                        on: {
                          change: function ($event) {
                            _vm.wageOption = "custom"
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          attrs: { slot: "label", for: "awe-custom" },
                          slot: "label",
                        },
                        [_vm._v("Custom")]
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        {
                          staticClass: "reveal-if-active",
                          attrs: { slot: "custom" },
                          slot: "custom",
                        },
                        [
                          _c("abbr", [_vm._v("$")]),
                          _vm._v(" "),
                          _c("input", {
                            directives: [
                              {
                                name: "model",
                                rawName: "v-model.number.lazy",
                                value: _vm.customAverageWeeklyEarnings,
                                expression: "customAverageWeeklyEarnings",
                                modifiers: { number: true, lazy: true },
                              },
                            ],
                            staticClass: "conditional-input",
                            attrs: {
                              type: "text",
                              placeholder: "Enter Amount",
                            },
                            domProps: {
                              value: _vm.customAverageWeeklyEarnings,
                            },
                            on: {
                              change: [
                                function ($event) {
                                  _vm.customAverageWeeklyEarnings = _vm._n(
                                    $event.target.value
                                  )
                                },
                                function ($event) {
                                  return _vm.$v.customAverageWeeklyEarnings.$touch()
                                },
                              ],
                              blur: function ($event) {
                                return _vm.$forceUpdate()
                              },
                            },
                          }),
                        ]
                      ),
                    ]),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _vm.wageOption === "max"
              ? _c("calc-field-box", [
                  _c("div", { staticClass: "formFieldNotation" }, [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n          Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can\n          differ. To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.\n        "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            !_vm.$v.dateOfInjury.$invalid && _vm.adjustmentPeriod === "during"
              ? [
                  _c("h2", [_vm._v("Return to Work Adjustments")]),
                  _vm._v(" "),
                  _c(
                    "calc-field-box",
                    [
                      _c(
                        "calc-label",
                        [
                          _vm._v(
                            "\n            Was a Job Offered within 60 Days of P&S Date?:\n            "
                          ),
                          _c(
                            "template",
                            { slot: "tooltip" },
                            [
                              _vm._v(
                                "\n              Was an offer made to the Injured Worker to return to work by the employer within 60 days after the P&S\n              date? (If the employer has fewer than 50 employees, select N/A.) See "
                              ),
                              _c("soc-section", { attrs: { section: "11.6" } }),
                              _vm._v(".\n            "),
                            ],
                            1
                          ),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c(
                        "calc-field",
                        [
                          _c("pretty-radio", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.jobOffered,
                                  expression: "jobOffered",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "yes",
                                id: "job-offered-yes",
                              },
                              domProps: {
                                checked: _vm._q(_vm.jobOffered, "yes"),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.jobOffered = "yes"
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: {
                                  slot: "label",
                                  for: "job-offered-yes",
                                },
                                slot: "label",
                              },
                              [_vm._v("Yes")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("pretty-radio", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.jobOffered,
                                  expression: "jobOffered",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "no",
                                id: "job-offered-no",
                              },
                              domProps: {
                                checked: _vm._q(_vm.jobOffered, "no"),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.jobOffered = "no"
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { slot: "label", for: "job-offered-no" },
                                slot: "label",
                              },
                              [_vm._v("No")]
                            ),
                          ]),
                          _vm._v(" "),
                          _c("pretty-radio", [
                            _c("input", {
                              directives: [
                                {
                                  name: "model",
                                  rawName: "v-model",
                                  value: _vm.jobOffered,
                                  expression: "jobOffered",
                                },
                              ],
                              attrs: {
                                type: "radio",
                                value: "na",
                                id: "job-offered-na",
                              },
                              domProps: {
                                checked: _vm._q(_vm.jobOffered, "na"),
                              },
                              on: {
                                change: function ($event) {
                                  _vm.jobOffered = "na"
                                },
                              },
                            }),
                            _vm._v(" "),
                            _c(
                              "label",
                              {
                                attrs: { slot: "label", for: "job-offered-na" },
                                slot: "label",
                              },
                              [_vm._v("N/A")]
                            ),
                          ]),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _vm.jobOffered === "yes"
                    ? [
                        _c(
                          "calc-field-box",
                          {
                            attrs: { error: _vm.$v.pdCommencementDate.$error },
                          },
                          [
                            _c(
                              "calc-label",
                              {
                                attrs: {
                                  target: "ps-date",
                                  error: _vm.errorMessage(
                                    "pdCommencementDate",
                                    { min: "the Date of Injury (%{min})" }
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n              Permanent and Stationary Date:\n              "
                                ),
                                _c(
                                  "template",
                                  { slot: "tooltip" },
                                  [
                                    _vm._v(
                                      "\n                What was the date the Injured Worker became Permanent & Stationary? (If you don't know, leave this\n                blank.) See "
                                    ),
                                    _c("soc-section", {
                                      attrs: { section: "10.3" },
                                    }),
                                    _vm._v(".\n              "),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "calc-field",
                              [
                                _c("date-picker", {
                                  attrs: {
                                    format: _vm.americanDateFormatter,
                                    id: "ps-date",
                                    "input-class": "shortField",
                                    typeable: true,
                                    placeholder: "mm/dd/yyyy",
                                    "open-date": _vm.dateOfInjury,
                                    "disabled-dates": { to: _vm.dateOfInjury },
                                  },
                                  on: {
                                    input: function ($event) {
                                      return _vm.$v.pdCommencementDate.$touch()
                                    },
                                  },
                                  model: {
                                    value: _vm.pdCommencementDate,
                                    callback: function ($$v) {
                                      _vm.pdCommencementDate = $$v
                                    },
                                    expression: "pdCommencementDate",
                                  },
                                }),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _vm.pdCommencementDate
                          ? _c(
                              "calc-field-box",
                              { attrs: { error: _vm.$v.rtwDate.$error } },
                              [
                                _c(
                                  "calc-label",
                                  {
                                    attrs: {
                                      target: "rtw-date",
                                      error: _vm.errorMessage("rtwDate", {
                                        min: "the Permanent and Stationary Date",
                                        max: "60 days thereafter (%{max})",
                                      }),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              Return to Work:\n              "
                                    ),
                                    _c(
                                      "template",
                                      { slot: "tooltip" },
                                      [
                                        _vm._v(
                                          "\n                What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,\n                leave this blank.) See "
                                        ),
                                        _c("soc-section", {
                                          attrs: { section: "11.6" },
                                        }),
                                        _vm._v(".\n              "),
                                      ],
                                      1
                                    ),
                                  ],
                                  2
                                ),
                                _vm._v(" "),
                                _c(
                                  "calc-field",
                                  [
                                    _c("date-picker", {
                                      attrs: {
                                        format: _vm.americanDateFormatter,
                                        id: "rtw-date",
                                        "input-class": "shortField",
                                        typeable: true,
                                        placeholder: "mm/dd/yyyy",
                                        "open-date": _vm.pdCommencementDate,
                                        "disabled-dates": {
                                          to: _vm.pdCommencementDate,
                                          from: _vm.addDays(
                                            _vm.pdCommencementDate,
                                            60
                                          ),
                                        },
                                      },
                                      on: {
                                        input: function ($event) {
                                          return _vm.$v.rtwDate.$touch()
                                        },
                                      },
                                      model: {
                                        value: _vm.rtwDate,
                                        callback: function ($$v) {
                                          _vm.rtwDate = $$v
                                        },
                                        expression: "rtwDate",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : _vm._e(),
                      ]
                    : _vm._e(),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm._l(_vm.impairments, function (impairment, index) {
              return _c(
                "div",
                { key: index, staticClass: "saved-string" },
                [
                  !(
                    _vm.$v.impairmentDeps.$invalid ||
                    _vm.$v.impairments.$each[index].$invalid
                  )
                    ? [
                        _c("h3", { staticStyle: { display: "block" } }, [
                          _vm._v(
                            _vm._s(_vm.impairmentRatings[index].ratingString)
                          ),
                        ]),
                        _vm._v(" "),
                        _c("h3", [
                          _vm._v(_vm._s(_vm.impairmentRatings[index].title)),
                        ]),
                        _vm._v(" "),
                        _c(
                          "div",
                          {
                            staticClass: "show-hide-container",
                            staticStyle: { "text-align": "right" },
                          },
                          [
                            !impairment.collapsed
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "reveal",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggle(impairment)
                                      },
                                    },
                                  },
                                  [_vm._v("Hide ▲")]
                                )
                              : _vm._e(),
                            _vm._v(" "),
                            impairment.collapsed
                              ? _c(
                                  "p",
                                  {
                                    staticClass: "reveal",
                                    on: {
                                      click: function ($event) {
                                        return _vm.toggle(impairment)
                                      },
                                    },
                                  },
                                  [_vm._v("Show ▼")]
                                )
                              : _vm._e(),
                          ]
                        ),
                      ]
                    : _vm._e(),
                  _vm._v(" "),
                  !impairment.collapsed
                    ? _c(
                        "div",
                        [
                          _c("h2", [_vm._v("Impairment Information")]),
                          _vm._v(" "),
                          _c(
                            "calc-field-box",
                            {
                              attrs: {
                                error:
                                  _vm.$v.impairments.$each[index].method.$error,
                              },
                            },
                            [
                              _c(
                                "calc-label",
                                {
                                  attrs: {
                                    error: _vm.errorMessage(
                                      `impairments.$each.${index}.method`
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Method\n              "
                                  ),
                                  _c(
                                    "template",
                                    { slot: "tooltip" },
                                    [
                                      _vm._v(
                                        "\n                Begin typing and select the Impairment Number that matches the Injured Worker's body part from the\n                report you are rating. See "
                                      ),
                                      _c("soc-section", {
                                        attrs: { section: "10.15" },
                                      }),
                                      _vm._v(".\n              "),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c(
                                "calc-field",
                                {
                                  attrs: {
                                    "container-class": "v-select select-method",
                                  },
                                },
                                [
                                  _c("v-select", {
                                    attrs: {
                                      options: _vm.availableImpairmentOptions(),
                                      clearable: false,
                                      loading:
                                        _vm.impairmentOptions.length === 0,
                                      filterBy: _vm.loosenedSearchFilter,
                                      placeholder:
                                        "Enter Method Number or Search Terms",
                                    },
                                    on: {
                                      input: function ($event) {
                                        return _vm.$v.impairments.$each[
                                          index
                                        ].method.$touch()
                                      },
                                    },
                                    model: {
                                      value: impairment.method,
                                      callback: function ($$v) {
                                        _vm.$set(impairment, "method", $$v)
                                      },
                                      expression: "impairment.method",
                                    },
                                  }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          [16, 17].includes(
                            _vm.get(impairment, "method.chapter")
                          )
                            ? _c(
                                "calc-field-box",
                                {
                                  attrs: {
                                    error:
                                      _vm.$v.impairments.$each[index].sideOfBody
                                        .$error,
                                  },
                                },
                                [
                                  _c(
                                    "calc-label",
                                    {
                                      attrs: {
                                        error: _vm.errorMessage(
                                          `impairments.$each.${index}.sideOfBody`
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Side of Body\n              "
                                      ),
                                      _c("template", { slot: "tooltip" }, [
                                        _vm._v("Select side of body"),
                                      ]),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "calc-field",
                                    {
                                      attrs: {
                                        "container-class":
                                          "v-select shortField",
                                      },
                                    },
                                    [
                                      _c("v-select", {
                                        attrs: {
                                          options:
                                            _vm.availableSidesOfBody(
                                              impairment
                                            ),
                                          clearable: false,
                                        },
                                        on: {
                                          input: function ($event) {
                                            return _vm.$v.impairments.$each[
                                              index
                                            ].sideOfBody.$touch()
                                          },
                                        },
                                        model: {
                                          value: impairment.sideOfBody,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              impairment,
                                              "sideOfBody",
                                              $$v
                                            )
                                          },
                                          expression: "impairment.sideOfBody",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.specialCaseOptions.hasOwnProperty(
                            _vm.get(impairment, "method.value")
                          )
                            ? [
                                !impairment.specialCase
                                  ? _c(
                                      "div",
                                      {
                                        staticClass: "descriptionTextArea",
                                        staticStyle: { "margin-top": "0" },
                                      },
                                      [
                                        _vm._v(
                                          "\n              This impairment affects several other impairments. Select the option that most closely applies.\n            "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                _c(
                                  "calc-field-box",
                                  {
                                    attrs: {
                                      error:
                                        _vm.$v.impairments.$each[index]
                                          .specialCase.$error,
                                    },
                                  },
                                  [
                                    _c("calc-label", [_vm._v(" ")]),
                                    _vm._v(" "),
                                    _c(
                                      "calc-field",
                                      {
                                        attrs: {
                                          "container-class": "v-select",
                                        },
                                      },
                                      [
                                        _c("v-select", {
                                          attrs: {
                                            options:
                                              _vm.specialCaseOptions[
                                                impairment.method.value
                                              ],
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.$v.impairments.$each[
                                                index
                                              ].specialCase.$touch()
                                            },
                                          },
                                          model: {
                                            value: impairment.specialCase,
                                            callback: function ($$v) {
                                              _vm.$set(
                                                impairment,
                                                "specialCase",
                                                $$v
                                              )
                                            },
                                            expression:
                                              "impairment.specialCase",
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.get(impairment, "method.value") === "11.01.01.00"
                            ? [
                                _c(
                                  "calc-field-box",
                                  [
                                    _c(
                                      "calc-label",
                                      [
                                        _vm._v(
                                          "\n                Scale:\n                "
                                        ),
                                        _c("template", { slot: "tooltip" }, [
                                          _vm._v(
                                            "\n                  Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose\n                  either "
                                          ),
                                          _c("b", [
                                            _vm._v(
                                              "Binaural Hearing Impairment"
                                            ),
                                          ]),
                                          _vm._v(" or "),
                                          _c("b", [
                                            _vm._v(
                                              "WPI (Whole Person Impairment)"
                                            ),
                                          ]),
                                          _vm._v(
                                            " based on the\n                  scale identified in the report you are rating.\n                "
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "calc-field",
                                      [
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "bhi",
                                              id: "wpi-bhi",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "bhi"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "bhi"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-bhi",
                                              },
                                              slot: "label",
                                            },
                                            [
                                              _vm._v(
                                                "Binaural Hearing Impairment"
                                              ),
                                            ]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "wpi",
                                              id: "wpi-wpi",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "wpi"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "wpi"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-wpi",
                                              },
                                              slot: "label",
                                            },
                                            [_vm._v("WPI")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                impairment.wpiOption === "bhi"
                                  ? _c(
                                      "calc-field-box",
                                      {
                                        attrs: {
                                          error:
                                            _vm.$v.impairments.$each[index].bhi
                                              .$error,
                                        },
                                      },
                                      [
                                        _c(
                                          "calc-label",
                                          {
                                            attrs: {
                                              error: _vm.errorMessage(
                                                `impairments.$each.${index}.bhi`
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Binaural Hearing Impairment\n                "
                                            ),
                                            _c("template", { slot: "tooltip" }),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("calc-field", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: impairment.bhi,
                                                expression: "impairment.bhi",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass: "shortField",
                                            attrs: { type: "number" },
                                            domProps: { value: impairment.bhi },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    impairment,
                                                    "bhi",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.bhiOrVsiChanged(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              ],
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                impairment.wpi === 0
                                  ? _c("div", { staticClass: "calcFieldBox" }, [
                                      _vm._m(0, true),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.get(impairment, "method.value") === "11.03.04.00"
                            ? [
                                _c(
                                  "calc-field-box",
                                  [
                                    _c(
                                      "calc-label",
                                      [
                                        _vm._v(
                                          "\n                Scale:\n                "
                                        ),
                                        _c("template", { slot: "tooltip" }, [
                                          _vm._v(
                                            "\n                  Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose\n                  either "
                                          ),
                                          _c("b", [
                                            _vm._v("Voice/Speech Impairment"),
                                          ]),
                                          _vm._v(" or "),
                                          _c("b", [
                                            _vm._v(
                                              "WPI (Whole Person Impairment)"
                                            ),
                                          ]),
                                          _vm._v(
                                            " based on the scale\n                  identified in the report you are rating.\n                "
                                          ),
                                        ]),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "calc-field",
                                      [
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "vsi",
                                              id: "wpi-vsi",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "vsi"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "vsi"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-vsi",
                                              },
                                              slot: "label",
                                            },
                                            [_vm._v("Voice/Speech Impairment")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "wpi",
                                              id: "wpi-wpi",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "wpi"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "wpi"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-wpi",
                                              },
                                              slot: "label",
                                            },
                                            [_vm._v("WPI")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                impairment.wpiOption === "vsi"
                                  ? _c(
                                      "calc-field-box",
                                      {
                                        attrs: {
                                          error:
                                            _vm.$v.impairments.$each[index].vsi
                                              .$error,
                                        },
                                      },
                                      [
                                        _c(
                                          "calc-label",
                                          {
                                            attrs: {
                                              error: _vm.errorMessage(
                                                `impairments.$each.${index}.vsi`
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Voice/Speech Impairment\n                "
                                            ),
                                            _c("template", { slot: "tooltip" }),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("calc-field", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: impairment.vsi,
                                                expression: "impairment.vsi",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass: "shortField",
                                            attrs: { type: "number" },
                                            domProps: { value: impairment.vsi },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    impairment,
                                                    "vsi",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.bhiOrVsiChanged(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              ],
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                impairment.wpi === 0
                                  ? _c("div", { staticClass: "calcFieldBox" }, [
                                      _vm._m(1, true),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _vm.get(impairment, "method.value") ===
                            "14.01.00.00" &&
                          _vm.dateOfInjury >= new Date(2005, 0, 1)
                            ? [
                                _c(
                                  "calc-field-box",
                                  [
                                    _c(
                                      "calc-label",
                                      [
                                        _vm._v(
                                          "\n                Scale:\n                "
                                        ),
                                        _c(
                                          "template",
                                          { slot: "tooltip" },
                                          [
                                            _vm._v(
                                              "\n                  Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose\n                  either "
                                            ),
                                            _c("b", [
                                              _vm._v(
                                                "GAF (Global Assessment of Functioning)"
                                              ),
                                            ]),
                                            _vm._v(" or "),
                                            _c("b", [
                                              _vm._v(
                                                "WPI (Whole Person Impairment)"
                                              ),
                                            ]),
                                            _vm._v(
                                              " based\n                  on the scale identified in the report you are rating. See "
                                            ),
                                            _c("soc-section", {
                                              attrs: { section: "10.15" },
                                            }),
                                            _vm._v(".\n                "),
                                          ],
                                          1
                                        ),
                                      ],
                                      2
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      "calc-field",
                                      [
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "gaf",
                                              id: "wpi-gaf",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "gaf"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "gaf"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-gaf",
                                              },
                                              slot: "label",
                                            },
                                            [_vm._v("GAF")]
                                          ),
                                        ]),
                                        _vm._v(" "),
                                        _c("pretty-radio", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model",
                                                value: impairment.wpiOption,
                                                expression:
                                                  "impairment.wpiOption",
                                              },
                                            ],
                                            attrs: {
                                              type: "radio",
                                              value: "wpi",
                                              id: "wpi-wpi",
                                            },
                                            domProps: {
                                              checked: _vm._q(
                                                impairment.wpiOption,
                                                "wpi"
                                              ),
                                            },
                                            on: {
                                              change: [
                                                function ($event) {
                                                  return _vm.$set(
                                                    impairment,
                                                    "wpiOption",
                                                    "wpi"
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.wpiOptionChanged(
                                                    index
                                                  )
                                                },
                                              ],
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "label",
                                            {
                                              attrs: {
                                                slot: "label",
                                                for: "wpi-wpi",
                                              },
                                              slot: "label",
                                            },
                                            [_vm._v("WPI")]
                                          ),
                                        ]),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                impairment.wpiOption === "gaf"
                                  ? _c(
                                      "calc-field-box",
                                      {
                                        attrs: {
                                          error:
                                            _vm.$v.impairments.$each[index].gaf
                                              .$error,
                                        },
                                      },
                                      [
                                        _c(
                                          "calc-label",
                                          {
                                            attrs: {
                                              error: _vm.errorMessage(
                                                `impairments.$each.${index}.gaf`
                                              ),
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                Global Assessment of Functioning (GAF)\n                "
                                            ),
                                            _c(
                                              "template",
                                              { slot: "tooltip" },
                                              [
                                                _vm._v(
                                                  "\n                  Locate the Global Assessment of Functioning (GAF) score in the report you are rating.\n                  See "
                                                ),
                                                _c("soc-section", {
                                                  attrs: { section: "10.15" },
                                                }),
                                                _vm._v(".\n                "),
                                              ],
                                              1
                                            ),
                                          ],
                                          2
                                        ),
                                        _vm._v(" "),
                                        _c("calc-field", [
                                          _c("input", {
                                            directives: [
                                              {
                                                name: "model",
                                                rawName: "v-model.number",
                                                value: impairment.gaf,
                                                expression: "impairment.gaf",
                                                modifiers: { number: true },
                                              },
                                            ],
                                            staticClass: "shortField",
                                            attrs: { type: "number" },
                                            domProps: { value: impairment.gaf },
                                            on: {
                                              input: [
                                                function ($event) {
                                                  if ($event.target.composing)
                                                    return
                                                  _vm.$set(
                                                    impairment,
                                                    "gaf",
                                                    _vm._n($event.target.value)
                                                  )
                                                },
                                                function ($event) {
                                                  return _vm.gafChanged(
                                                    $event,
                                                    index
                                                  )
                                                },
                                              ],
                                              blur: function ($event) {
                                                return _vm.$forceUpdate()
                                              },
                                            },
                                          }),
                                        ]),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                                _vm._v(" "),
                                impairment.wpi === 0
                                  ? _c("div", { staticClass: "calcFieldBox" }, [
                                      _vm._m(2, true),
                                    ])
                                  : _vm._e(),
                              ]
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "calc-field-box",
                            {
                              attrs: {
                                error:
                                  _vm.$v.impairments.$each[index].wpi.$error,
                              },
                            },
                            [
                              _c(
                                "calc-label",
                                {
                                  attrs: {
                                    error: _vm.errorMessage(
                                      `impairments.$each.${index}.wpi`
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Whole Person Impairment (WPI)\n              "
                                  ),
                                  _c(
                                    "template",
                                    { slot: "tooltip" },
                                    [
                                      _vm._v(
                                        "\n                Locate the Whole Person Impairment (WPI) in the report you are rating. See\n                "
                                      ),
                                      _c("soc-section", {
                                        attrs: { section: "10.15" },
                                      }),
                                      _vm._v(".\n              "),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("calc-field", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy.number",
                                      value: impairment.wpi,
                                      expression: "impairment.wpi",
                                      modifiers: { lazy: true, number: true },
                                    },
                                  ],
                                  staticClass: "shortField",
                                  attrs: {
                                    type: "number",
                                    readonly: _vm.WPIReadOnly(impairment),
                                  },
                                  domProps: { value: impairment.wpi },
                                  on: {
                                    change: [
                                      function ($event) {
                                        _vm.$set(
                                          impairment,
                                          "wpi",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      function ($event) {
                                        _vm.$asyncComputed.totalWeeksOfPd.update() &&
                                          _vm.$v.impairments.$each[
                                            index
                                          ].wpi.$touch()
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("%")]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _vm.get(impairment, "method.value") !== "14.01.00.00"
                            ? _c(
                                "calc-field-box",
                                {
                                  attrs: {
                                    error:
                                      _vm.$v.impairments.$each[index].painAddon
                                        .$error,
                                  },
                                },
                                [
                                  _c(
                                    "calc-label",
                                    {
                                      attrs: {
                                        error: _vm.errorMessage(
                                          `impairments.$each.${index}.painAddon`
                                        ),
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n              Add-on for Pain\n              "
                                      ),
                                      _c(
                                        "template",
                                        { slot: "tooltip" },
                                        [
                                          _vm._v(
                                            "\n                Locate the Whole Person Impairment (WPI) in the report you are rating. If the doctor has included an\n                add-on for pain, enter it in this field. See "
                                          ),
                                          _c("soc-section", {
                                            attrs: { section: "10.15" },
                                          }),
                                          _vm._v(".\n              "),
                                        ],
                                        1
                                      ),
                                    ],
                                    2
                                  ),
                                  _vm._v(" "),
                                  _c("calc-field", [
                                    _c("input", {
                                      directives: [
                                        {
                                          name: "model",
                                          rawName: "v-model.lazy.number",
                                          value: impairment.painAddon,
                                          expression: "impairment.painAddon",
                                          modifiers: {
                                            lazy: true,
                                            number: true,
                                          },
                                        },
                                      ],
                                      staticClass: "shortField",
                                      attrs: { type: "number" },
                                      domProps: { value: impairment.painAddon },
                                      on: {
                                        change: [
                                          function ($event) {
                                            _vm.$set(
                                              impairment,
                                              "painAddon",
                                              _vm._n($event.target.value)
                                            )
                                          },
                                          function ($event) {
                                            return _vm.painAddonChanged(index)
                                          },
                                        ],
                                        blur: function ($event) {
                                          return _vm.$forceUpdate()
                                        },
                                      },
                                    }),
                                    _vm._v(" "),
                                    _c("span", [_vm._v("%")]),
                                  ]),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "calc-field-box",
                            {
                              attrs: {
                                error:
                                  _vm.$v.impairments.$each[index]
                                    .industrialCausation.$error,
                              },
                            },
                            [
                              _c(
                                "calc-label",
                                {
                                  attrs: {
                                    error: _vm.errorMessage(
                                      `impairments.$each.${index}.industrialCausation`
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              Percent Industrial Causation\n              "
                                  ),
                                  _c(
                                    "template",
                                    { slot: "tooltip" },
                                    [
                                      _vm._v(
                                        "\n                If apportionment applies, set this value to indicate the percentage that remains industrial. So, if no\n                apportionment applies, set this value to 100% because it is fully industrial. See\n                "
                                      ),
                                      _c("soc-section", {
                                        attrs: { section: "10.31" },
                                      }),
                                      _vm._v(" through "),
                                      _c("soc-section", {
                                        attrs: { section: "10.37" },
                                      }),
                                      _vm._v(".\n              "),
                                    ],
                                    1
                                  ),
                                ],
                                2
                              ),
                              _vm._v(" "),
                              _c("calc-field", [
                                _c("input", {
                                  directives: [
                                    {
                                      name: "model",
                                      rawName: "v-model.lazy.number",
                                      value: impairment.industrialCausation,
                                      expression:
                                        "impairment.industrialCausation",
                                      modifiers: { lazy: true, number: true },
                                    },
                                  ],
                                  staticClass: "shortField",
                                  attrs: { type: "number" },
                                  domProps: {
                                    value: impairment.industrialCausation,
                                  },
                                  on: {
                                    change: [
                                      function ($event) {
                                        _vm.$set(
                                          impairment,
                                          "industrialCausation",
                                          _vm._n($event.target.value)
                                        )
                                      },
                                      function ($event) {
                                        _vm.$asyncComputed.totalWeeksOfPd.update() &&
                                          _vm.$v.impairments.$each[
                                            index
                                          ].industrialCausation.$touch()
                                      },
                                    ],
                                    blur: function ($event) {
                                      return _vm.$forceUpdate()
                                    },
                                  },
                                }),
                                _vm._v(" "),
                                _c("span", [_vm._v("%")]),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          !(
                            _vm.$v.impairmentDeps.$invalid ||
                            _vm.$v.impairments.$each[index].$invalid
                          )
                            ? _c(
                                "div",
                                {
                                  staticClass:
                                    "descriptionTextArea rating-string",
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(_vm.ratingStrings[index]) +
                                      "\n            "
                                  ),
                                  _c("i", {
                                    staticClass: "copy far fa-copy",
                                    on: {
                                      click: function ($event) {
                                        return _vm.copyRatingString(index)
                                      },
                                    },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "transition",
                                    { attrs: { name: "fade" } },
                                    [
                                      impairment.copied
                                        ? _c(
                                            "span",
                                            { staticClass: "success-text" },
                                            [_vm._v("Copied!")]
                                          )
                                        : _vm._e(),
                                    ]
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "button",
                            {
                              staticClass: "button clear-button fitted",
                              attrs: { type: "button" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeImpairment(index)
                                },
                              },
                            },
                            [
                              _vm._v(
                                "\n            Remove Impairment\n          "
                              ),
                            ]
                          ),
                        ],
                        2
                      )
                    : _vm._e(),
                ],
                2
              )
            }),
            _vm._v(" "),
            _c("div", { staticClass: "buttonContainer add-impairment" }, [
              _c(
                "button",
                _vm._g(
                  {
                    staticClass: "button clear-button fitted",
                    class: { disabled: !_vm.impairmentsValid },
                    attrs: { type: "button" },
                  },
                  _vm.impairmentsValid ? { click: _vm.addImpairment } : null
                ),
                [_vm._v("\n          Add Impairment\n        ")]
              ),
            ]),
            _vm._v(" "),
            _c("calculate-button", {
              on: {
                click: function ($event) {
                  return _vm.calculatePd()
                },
                clear: function ($event) {
                  return _vm.clearForm()
                },
              },
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      !_vm.$v.$invalid && _vm.results
        ? _c(
            "section",
            { attrs: { id: "calculator-results" } },
            [
              _c("h2", [_vm._v("Results")]),
              _vm._v(" "),
              _vm.impairments.length > 1 || _vm.isSpecialGafResult
                ? _c(
                    "div",
                    { staticClass: "descriptionTextArea rating-summary" },
                    [
                      _c("div", { staticClass: "title" }, [
                        _vm._v("Rating Summary"),
                      ]),
                      _vm._v(" "),
                      _vm._l(
                        _vm.sortedRatingGroups,
                        function (combinedRatingGroup) {
                          return [
                            _vm._l(
                              combinedRatingGroup.impairmentRatings,
                              function (impairmentRating) {
                                return [
                                  _c(
                                    "div",
                                    { staticClass: "combined-rating" },
                                    [
                                      _c("div", [
                                        _vm._v(
                                          _vm._s(impairmentRating.ratingString)
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("div", [
                                        _vm._v(_vm._s(impairmentRating.title)),
                                      ]),
                                    ]
                                  ),
                                ]
                              }
                            ),
                          ]
                        }
                      ),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "combined-rating" },
                        [
                          _vm._l(
                            _vm.sortedRatingGroups,
                            function (combinedRatingGroup) {
                              return [
                                combinedRatingGroup.impairmentRatings.length > 1
                                  ? _c("div", [
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            combinedRatingGroup.ratingString
                                          ) +
                                          "\n          "
                                      ),
                                    ])
                                  : _vm._e(),
                              ]
                            }
                          ),
                          _vm._v(" "),
                          _c("div", [
                            _vm._v(_vm._s(_vm.combinedRatingGroupsSummary)),
                          ]),
                        ],
                        2
                      ),
                      _vm._v(" "),
                      _c("i", {
                        staticClass: "copy far fa-copy",
                        on: { click: _vm.copyRatingSummary },
                      }),
                      _vm._v(" "),
                      _c("transition", { attrs: { name: "fade" } }, [
                        _vm.summaryCopied
                          ? _c("span", { staticClass: "success-text" }, [
                              _vm._v("Copied!"),
                            ])
                          : _vm._e(),
                      ]),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "resultContainer" }, [
                _vm.impairments.length > 1
                  ? _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Combined Rating"),
                    ])
                  : _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Final Rating"),
                    ]),
                _vm._v(" "),
                _c("div", { staticClass: "resultValue" }, [
                  _vm._v(_vm._s(_vm._f("toFixed")(_vm.pdRating, 0)) + "%"),
                ]),
              ]),
              _vm._v(" "),
              _vm.isSpecialGafResult
                ? [_vm._m(3)]
                : [
                    _c("div", { staticClass: "resultContainer" }, [
                      _c("div", { staticClass: "resultLabel" }, [
                        _vm._v("Total of All Add-ons for Pain"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "resultValue" }, [
                        _vm._v(_vm._s(_vm.painAddonTotal) + "%"),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.painAddonTotal > 3
                      ? _c("calc-field-box", [
                          _c(
                            "div",
                            { staticClass: "formFieldNotation" },
                            [
                              _c("i", { staticClass: "far fa-arrow-up" }),
                              _vm._v(
                                "\n          This result exceeds the maximum allowable add-on for pain, which is 3%. See "
                              ),
                              _c("soc-section", {
                                attrs: { section: "10.15" },
                              }),
                              _vm._v(".\n        "),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pdRating < 100
                      ? _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("Total Weeks of PD"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              _vm._s(_vm._f("toFixed")(_vm.totalWeeksOfPd, 2))
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultContainer" }, [
                      _c("div", { staticClass: "resultLabel" }, [
                        _vm._v("Age on DOI"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "resultValue" }, [
                        _vm._v(_vm._s(_vm.age)),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.age > 100
                      ? _c("div", { staticClass: "calcFieldBox comment" }, [
                          _vm._m(4),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultContainer" }, [
                      _c("div", { staticClass: "resultLabel" }, [
                        _vm._v("Average Weekly Earnings"),
                      ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "resultValue" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm._f("currency")(
                                _vm.averageWeeklyEarnings.awe.pd
                              )
                            ) +
                            " " +
                            _vm._s(_vm.averageWeeklyEarnings.suffix.pd) +
                            "\n        "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultContainer" }, [
                      _vm.pdRating === 100
                        ? _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("PTD Weekly Rate:"),
                          ])
                        : _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("PD Weekly Rate:"),
                          ]),
                      _vm._v(" "),
                      _c("div", { staticClass: "resultValue" }, [
                        _vm._v(_vm._s(_vm._f("currency")(_vm.weeklyPdRate))),
                      ]),
                    ]),
                    _vm._v(" "),
                    _vm.pdRating === 100
                      ? _c("div", { staticClass: "calcFieldBox comment" }, [
                          _c(
                            "div",
                            { staticClass: "formFieldNotation" },
                            [
                              _c("i", { staticClass: "far fa-arrow-up" }),
                              _vm._v(
                                "\n          To determine the present value of PTD payments owed, see the\n          "
                              ),
                              _c("calculator-link", {
                                attrs: {
                                  name: "pv_of_ptd",
                                  "claim-number": _vm.claimNumber,
                                },
                              }),
                              _vm._v(
                                " calculator. To calculate a commutation of\n          Permanent Total Disability see the "
                              ),
                              _c("calculator-link", {
                                attrs: {
                                  name: "commutation_ptd",
                                  "claim-number": _vm.claimNumber,
                                },
                              }),
                              _vm._v("\n          calculator.\n        "),
                            ],
                            1
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pdRating < 100
                      ? [
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v("Total PD Payout"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.totalPdPayout))
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "sub-header" }, [
                            _vm._v("Return to Work Adjustments"),
                          ]),
                          _vm._v(" "),
                          _c("calc-field-box", [
                            _c(
                              "div",
                              { staticClass: "formFieldNotation" },
                              [
                                _c("rtw-adjustments", {
                                  attrs: {
                                    "job-offered": _vm.jobOffered,
                                    "date-of-injury": _vm.dateOfInjury,
                                    "pd-rating": _vm.pdRating,
                                  },
                                }),
                              ],
                              1
                            ),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.hasRtwAdjustmentPeriod &&
                    _vm.rtwModifier &&
                    _vm.pdRating != 0
                      ? [
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v("Weeks Paid at Base Rate"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.rtwWeeksPaidAtBaseRate.weeks) +
                                  " weeks, " +
                                  _vm._s(_vm.rtwWeeksPaidAtBaseRate.days) +
                                  " days\n          "
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v("PD Paid at Base Rate"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(_vm.rtwPdPaidAtBaseRate)
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v("RTW Modifier Used"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(_vm._s(_vm.rtwModifier)),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v(
                                "Weekly PD Compensation (after Adjustment)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.rtwWeeklyPdCompensation
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v(
                                "Weeks of PD Remaining (after Adjustment)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("toFixed")(
                                    _vm.rtwWeeksOfPdRemaining,
                                    2
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v(
                                "PD Indemnity Remaining (after Adjustment)"
                              ),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.rtwPdIndemnityRemaining
                                  )
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultContainer" }, [
                            _c("div", { staticClass: "resultLabel" }, [
                              _vm._v("Total PD Payout (after Adjustment)"),
                            ]),
                            _vm._v(" "),
                            _c("div", { staticClass: "resultValue" }, [
                              _vm._v(
                                _vm._s(_vm._f("currency")(_vm.rtwTotalPdPayout))
                              ),
                            ]),
                          ]),
                        ]
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.averageWeeklyEarnings.limitsApplied &&
                    _vm.isQualifiedForLp
                      ? _c("div", { staticClass: "resultContainer" }, [
                          _c("div", { staticClass: "resultLabel" }, [
                            _vm._v("Average Weekly Earnings"),
                          ]),
                          _vm._v(" "),
                          _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.averageWeeklyEarnings.awe.lp
                                  )
                                ) +
                                " " +
                                _vm._s(_vm.averageWeeklyEarnings.suffix.lp) +
                                "\n        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultContainer" }, [
                      _c("div", { staticClass: "resultLabel" }, [
                        _vm._v("Life Pension Weekly Rate"),
                      ]),
                      _vm._v(" "),
                      _vm.isQualifiedForLp
                        ? _c("div", { staticClass: "resultValue" }, [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm._f("currency")(
                                    _vm.averageWeeklyEarnings.lpRate
                                  )
                                ) +
                                "\n        "
                            ),
                          ])
                        : _c("div", { staticClass: "resultValue" }, [
                            _vm._v(_vm._s(_vm._f("currency")(0))),
                          ]),
                    ]),
                    _vm._v(" "),
                    _vm.pdRating < 70
                      ? _c("calc-field-box", [
                          _c("div", { staticClass: "formFieldNotation" }, [
                            _c("i", { staticClass: "far fa-arrow-up" }),
                            _vm._v(
                              "\n          PD is under 70%, so this rating does not qualify for a life pension.\n        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.pdRating === 100
                      ? _c("calc-field-box", [
                          _c("div", { staticClass: "formFieldNotation" }, [
                            _c("i", { staticClass: "far fa-arrow-up" }),
                            _vm._v(
                              "\n          PD is 100%, so this rating does not qualify for a life pension.\n        "
                            ),
                          ]),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c("result-calculator-messages", {
                      attrs: {
                        "pd-rating": _vm.pdRating,
                        "claim-number": _vm.claimNumber,
                      },
                    }),
                  ],
              _vm._v(" "),
              _c("save-button", {
                attrs: {
                  form: "pd-form",
                  status: _vm.saveStatus,
                  message: _vm.saveMessage,
                  enabled: !!_vm.claimNumber,
                },
              }),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showPainModal && !_vm.acceptPainTotal
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "message" }, [
                  _vm._v(
                    "\n        Your entries exceed 3%, which is the maximum allowance for pain resulting from a single injury.\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "prompt" }, [
                  _vm._v("Do you wish to continue?"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button clear-button action",
                    on: { click: _vm.exceedPainAddonTotal },
                  },
                  [_vm._v("Yes - Let Me Exceed 3%")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button black-button action",
                    on: { click: _vm.capPainAddOnTotal },
                  },
                  [_vm._v("No - Cap Pain at 3%")]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.showScheduleModal && !_vm.acceptRatingSchedule
        ? _c(
            "modal",
            [
              _c("template", { slot: "body" }, [
                _c("p", { staticClass: "message" }, [
                  _vm._v(
                    "\n        This Date of Injury is before 1/1/2005 but you may rate this claim using the 2005 ratings manual.\n      "
                  ),
                ]),
                _vm._v(" "),
                _c("p", { staticClass: "prompt" }, [
                  _vm._v("Do you wish to continue?"),
                ]),
              ]),
              _vm._v(" "),
              _c("template", { slot: "footer" }, [
                _c(
                  "button",
                  {
                    staticClass: "button clear-button action",
                    on: { click: _vm.continueWith2005Schedule },
                  },
                  [_vm._v("Yes - Continue")]
                ),
                _vm._v(" "),
                _c(
                  "button",
                  {
                    staticClass: "button black-button action",
                    on: { click: _vm.clearForm },
                  },
                  [_vm._v("No - Clear Form")]
                ),
              ]),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v(
        "\n                This Binaural Hearing Impairment value does not result in any Whole Person Impairment.\n              "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v(
        "\n                This Voice/Speech Impairment value does not result in any Whole Person Impairment.\n              "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v(
        "\n                This GAF score does not result in any psychiatric impairment.\n              "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calcFieldBox" }, [
      _c("div", { staticClass: "formFieldNotation" }, [
        _c("i", { staticClass: "far fa-arrow-up" }),
        _vm._v(
          "\n          This GAF score does not result in any psychiatric impairment.\n        "
        ),
      ]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v(
        "\n          Ages over 100 are rated at 100 to match the assumption used by the Age Adjustment table in the PD Rating\n          Schedule.\n        "
      ),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }