import { parse as parseDate } from 'date-fns';

const DATE_REGEX = /(^date[A-Z])|([a-z]Date$)/;

export const reviver = (key, value) => {
  if (DATE_REGEX.test(key) && value) {
    return parseDate(value);
  }
  return value;
};

export default (form) => {
  return JSON.parse(form, reviver);
}
