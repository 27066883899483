var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                { attrs: { target: "mode" } },
                [
                  _vm._v("\n          Calculation Type:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Select the type of calculation you wish to perform."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.mode,
                        expression: "mode",
                      },
                    ],
                    attrs: { id: "mode" },
                    on: {
                      change: [
                        function ($event) {
                          var $$selectedVal = Array.prototype.filter
                            .call($event.target.options, function (o) {
                              return o.selected
                            })
                            .map(function (o) {
                              var val = "_value" in o ? o._value : o.value
                              return val
                            })
                          _vm.mode = $event.target.multiple
                            ? $$selectedVal
                            : $$selectedVal[0]
                        },
                        function ($event) {
                          return _vm.resetData()
                        },
                      ],
                    },
                  },
                  [
                    _c("option", { attrs: { value: "difference" } }, [
                      _vm._v("Calculate Difference in Days"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "start" } }, [
                      _vm._v("Calculate a Starting Date"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "end" } }, [
                      _vm._v("Calculate an Ending Date"),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Counting Method:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Select to determine whether or not to count the first date in a range as one day on its own:\n            "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v("\n                Select "),
                        _c("b", [_vm._v("1st day counted")]),
                        _vm._v(
                          " to include the starting date in the count of days. (This is useful when\n                counting days of payment.)\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("\n                Select "),
                        _c("b", [_vm._v("24 hours per day")]),
                        _vm._v(
                          " to measure the number of days that span a date range. In this approach,\n                it takes 24 hours to reach a count of one. So for example, the period from the first date in a range\n                until the next date on the calendar are counted as the first day, and so on. (This is useful when\n                counting the number of days until a deadline.)\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.countingOffset,
                          expression: "countingOffset",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: { type: "radio", value: "1", id: "first-day" },
                      domProps: {
                        checked: _vm._q(_vm.countingOffset, _vm._n("1")),
                      },
                      on: {
                        change: function ($event) {
                          _vm.countingOffset = _vm._n("1")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "first-day" },
                        slot: "label",
                      },
                      [_vm._v("1st day counted")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.countingOffset,
                          expression: "countingOffset",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "0",
                        id: "twenty-four-hours",
                      },
                      domProps: {
                        checked: _vm._q(_vm.countingOffset, _vm._n("0")),
                      },
                      on: {
                        change: function ($event) {
                          _vm.countingOffset = _vm._n("0")
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "twenty-four-hours" },
                        slot: "label",
                      },
                      [_vm._v("24 hours per day")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.mode !== "start"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.startDateInput.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "start-date",
                        error: _vm.errorMessage("startDateInput"),
                      },
                    },
                    [
                      _vm._v("\n          Starting Date:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v("Please enter a starting date"),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("date-picker", {
                        attrs: {
                          format: _vm.americanDateFormatter,
                          id: "start-date",
                          "input-class": "shortField",
                          typeable: true,
                          placeholder: "mm/dd/yyyy",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.startDateInput.$touch()
                          },
                        },
                        model: {
                          value: _vm.startDateInput,
                          callback: function ($$v) {
                            _vm.startDateInput = $$v
                          },
                          expression: "startDateInput",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === "start"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.intervalInput.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "interval",
                        error: _vm.errorMessage("intervalInput"),
                      },
                    },
                    [
                      _vm._v("\n          Length of Time:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v("\n            Select either "),
                        _c("b", [_vm._v("Number of Days")]),
                        _vm._v(" or "),
                        _c("b", [_vm._v("Number of Weeks")]),
                        _vm._v(
                          " and then specify the length of time for the\n            period you wish to calculate.\n          "
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.intervalOption,
                              expression: "intervalOption",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "radio", value: "days", id: "days" },
                          domProps: {
                            checked: _vm._q(_vm.intervalOption, _vm._n("days")),
                          },
                          on: {
                            change: function ($event) {
                              _vm.intervalOption = _vm._n("days")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "days" },
                            slot: "label",
                          },
                          [_vm._v("Number of Days")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.intervalOption,
                              expression: "intervalOption",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "radio", value: "weeks", id: "weeks" },
                          domProps: {
                            checked: _vm._q(
                              _vm.intervalOption,
                              _vm._n("weeks")
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.intervalOption = _vm._n("weeks")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "weeks" },
                            slot: "label",
                          },
                          [_vm._v("Number of Weeks")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number.lazy",
                            value: _vm.intervalInput,
                            expression: "intervalInput",
                            modifiers: { number: true, lazy: true },
                          },
                        ],
                        staticClass: "shortField",
                        attrs: { type: "text", id: "interval" },
                        domProps: { value: _vm.intervalInput },
                        on: {
                          change: [
                            function ($event) {
                              _vm.intervalInput = _vm._n($event.target.value)
                            },
                            function ($event) {
                              return _vm.$v.intervalInput.$touch()
                            },
                          ],
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mode !== "end"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.endDateInput.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "end-date",
                        error: _vm.errorMessage("endDateInput"),
                      },
                    },
                    [
                      _vm._v("\n          Ending Date:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v("Please enter an ending date"),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("date-picker", {
                        attrs: {
                          format: _vm.americanDateFormatter,
                          id: "end-date",
                          "input-class": "shortField",
                          typeable: true,
                          placeholder: "mm/dd/yyyy",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.endDateInput.$touch()
                          },
                        },
                        model: {
                          value: _vm.endDateInput,
                          callback: function ($$v) {
                            _vm.endDateInput = $$v
                          },
                          expression: "endDateInput",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.mode === "end"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.intervalInput.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "interval",
                        error: _vm.errorMessage("intervalInput"),
                      },
                    },
                    [
                      _vm._v("\n          Length of Time:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v("\n            Select either "),
                        _c("b", [_vm._v("Number of Days")]),
                        _vm._v(" or "),
                        _c("b", [_vm._v("Number of Weeks")]),
                        _vm._v(
                          " and then specify the length of time for the\n            period you wish to calculate.\n          "
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.intervalOption,
                              expression: "intervalOption",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "radio", value: "days", id: "days" },
                          domProps: {
                            checked: _vm._q(_vm.intervalOption, _vm._n("days")),
                          },
                          on: {
                            change: function ($event) {
                              _vm.intervalOption = _vm._n("days")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "days" },
                            slot: "label",
                          },
                          [_vm._v("Number of Days")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.intervalOption,
                              expression: "intervalOption",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: { type: "radio", value: "weeks", id: "weeks" },
                          domProps: {
                            checked: _vm._q(
                              _vm.intervalOption,
                              _vm._n("weeks")
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.intervalOption = _vm._n("weeks")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "weeks" },
                            slot: "label",
                          },
                          [_vm._v("Number of Weeks")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model.number.lazy",
                            value: _vm.intervalInput,
                            expression: "intervalInput",
                            modifiers: { number: true, lazy: true },
                          },
                        ],
                        staticClass: "shortField",
                        attrs: { type: "text", id: "interval" },
                        domProps: { value: _vm.intervalInput },
                        on: {
                          change: [
                            function ($event) {
                              _vm.intervalInput = _vm._n($event.target.value)
                            },
                            function ($event) {
                              return _vm.$v.intervalInput.$touch()
                            },
                          ],
                          blur: function ($event) {
                            return _vm.$forceUpdate()
                          },
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c("section", { attrs: { id: "calculator-results" } }, [
          _c("h2", [_vm._v("Results")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Starting Date:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.humanDateFormatter(_vm.startDate))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Difference in Days:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.numberOfDays.toLocaleString())),
            ]),
          ]),
          _vm._v(" "),
          _vm.numberOfDays >= 7
            ? _c("div", { staticClass: "resultContainer" }, [
                _c("div", { staticClass: "resultLabel" }, [
                  _vm._v("Difference in Weeks:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "resultValue" }, [
                  _vm._v(
                    _vm._s(_vm.numberOfWeeks.weeks) +
                      " Weeks, " +
                      _vm._s(_vm._f("toFixed")(_vm.numberOfWeeks.days, 2)) +
                      " Days"
                  ),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [_vm._v("Ending Date:")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.humanDateFormatter(_vm.endDate))),
            ]),
          ]),
          _vm._v(" "),
          _c("h4", [_vm._v("Mailbox Rule Extensions from Ending Date")]),
          _vm._v(" "),
          _c("p", [
            _vm._v(
              "\n      If a party receives a document by mail that has a time limit for a response, that limit may be extended. This\n      is known as the mailbox rule. The length of the extension, calculated from the ending date, is as follows:\n    "
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("If the party being served is w/in CA (+5 days):"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.humanDateFormatter(_vm.addToEndDate(5)))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v(
                "If the party being served is w/in USA but outside CA (+10 days):"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.humanDateFormatter(_vm.addToEndDate(10)))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("If the party being served is outside USA (+20 days):"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.humanDateFormatter(_vm.addToEndDate(20)))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v(
                "\n      If any of the indicated dates fall on a weekend or on a holiday for which the offices of the appeals board are\n      closed, the act or response may be performed or exercised the next business day. (For details see\n      "
              ),
              _c("soc-section", { attrs: { section: "15.15" } }),
              _vm._v(")\n    "),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }