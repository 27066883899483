var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Values to be Combined")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _vm._l(_vm.values, function (value, index) {
            return _c(
              "calc-field-box",
              {
                key: index,
                attrs: { error: _vm.$v.values.$each[index].$error },
              },
              [
                _c(
                  "calc-label",
                  {
                    attrs: { error: _vm.errorMessage(`values.$each.${index}`) },
                  },
                  [_vm._v("Value " + _vm._s(index + 1) + ":")]
                ),
                _vm._v(" "),
                _c("calc-field", [
                  index !== 0
                    ? _c(
                        "abbr",
                        {
                          attrs: { "data-tooltip": "Delete Value" },
                          on: {
                            click: function ($event) {
                              return _vm.removeValue(index)
                            },
                          },
                        },
                        [_c("i", { staticClass: "fas fa-times-circle" })]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _c("input", {
                    directives: [{ name: "focus", rawName: "v-focus" }],
                    staticClass: "shortField",
                    attrs: {
                      type: "number",
                      min: "1",
                      max: "99",
                      placeholder: "0",
                    },
                    domProps: { value: value },
                    on: {
                      change: function ($event) {
                        return _vm.updateValue(index, $event)
                      },
                    },
                  }),
                ]),
              ],
              1
            )
          }),
          _vm._v(" "),
          _vm.values.length === 1 && _vm.$v.values.$each[0].$invalid
            ? _c("div", { staticClass: "calcFieldBox" }, [_vm._m(0)])
            : _vm._e(),
          _vm._v(" "),
          _c("div", { staticClass: "buttonContainer" }, [
            _c(
              "button",
              {
                staticClass: "button clear-button",
                staticStyle: { "margin-top": "0" },
                on: {
                  click: function ($event) {
                    return _vm.addValue()
                  },
                },
              },
              [_vm._v("Add a Value")]
            ),
          ]),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    _vm.$v.$anyDirty && !_vm.$v.$error
      ? _c("section", { attrs: { id: "calculator-results" } }, [
          _c("h2", [_vm._v("Results")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultTableBox" }, [
            _c("table", { attrs: { id: "intermediateResults" } }, [
              _c("caption", [_vm._v("Intermediate Results:")]),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _vm._m(2),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.sortedValues, function (value, index) {
                  return value
                    ? _c("tr", { key: index }, [
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("percentSuffix")(
                                _vm._f("toFixed")(_vm._f("toPercent")(value), 0)
                              )
                            )
                          ),
                        ]),
                        _vm._v(" "),
                        _c("td", [
                          _vm._v(
                            _vm._s(
                              _vm._f("percentSuffix")(
                                _vm._f("toFixed")(
                                  _vm._f("toPercent")(
                                    index === 0
                                      ? undefined
                                      : _vm.combined[index]
                                  ),
                                  0
                                )
                              )
                            )
                          ),
                        ]),
                      ])
                    : _vm._e()
                }),
                0
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.finalCombinedValue
            ? _c("div", { staticClass: "resultContainer lastResult" }, [
                _c("div", { staticClass: "resultLabel" }, [
                  _vm._v("Final Combined Value:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "resultValue" }, [
                  _vm._v(
                    _vm._s(
                      _vm._f("percentSuffix")(
                        _vm._f("toFixed")(
                          _vm._f("toPercent")(_vm.finalCombinedValue),
                          0
                        )
                      )
                    )
                  ),
                ]),
              ])
            : _vm._e(),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v(
        "\n          Input multiple values to be combined. After inputting a number, it will appear on the table below. Then add\n          additional values as needed.\n        "
      ),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [_c("col"), _vm._v(" "), _c("col")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Values Sorted")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Values Combined")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }