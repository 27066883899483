<template>
  <calc-field-box>
    <calc-label target="claim-number">
      Claim/Matter Number:
      <template slot="tooltip">
        Enter the claim or matter number for the report you are rating.
        <template v-if="saveable">
          This input is required only if you wish to save this report.
          <br><br>
          To view or edit an existing saved calculator, go to the
          <a href="/calculators/saved" target="_blank">Saved Reports</a>.
        </template>
      </template>
    </calc-label>
    <calc-field :container-class="saveable ? 'v-select half-width' : ''">
      <input v-if="!saveable" id="claim-number" type="text">
      <v-select v-if="saveable" :value="value" :options="options" @input="selectionChanged" :clearable="false"
                :selectOnTab="true" :get-option-label="getOptionLabel" taggable/>
    </calc-field>
  </calc-field-box>
</template>

<script>
  import CalcFieldBox from './calc-field-box';
  import CalcLabel from './calc-label';
  import CalcField from './calc-field';
  import vSelect from 'vue-select';
  import axios from 'axios';
  import { reviver } from '../form-data-parser';

  export default {
    props: ['value', 'url'],
    inject: ['saveable'],
    created() {
      if (this.saveable) {
        axios.get('/calculator_groups').then(({ data: responseData }) => {
          this.optionData = responseData.reduce((memo, { claim_number, data }) => {
            console.log('aasaaasd')
            console.log(responseData)
            memo[claim_number] = data;
            return memo;
          }, {});
          this.options = Object.keys(this.optionData);
          this.initializeFromURL();
        });
      }
    },
    data() {
      return {
        options: [],
        optionData: {},
      }
    },
    methods: {
      initializeFromURL() {
        const parsedUrl = new URL(this.url || document.location.href);
        const claimNumber = parsedUrl.searchParams.get("claim_number");
        if (claimNumber) {
          this.selectionChanged(claimNumber);
        }
      },
      selectionChanged(value) {
        this.$emit('input', value);
        const data = this.reviveData(value);
        if (data) {
          this.$emit('data', data);
        }
      },
      reviveData(value) {
        const data = this.optionData[value];
        if (data) {
          return Object.keys(data).reduce((memo, key) => {
            memo[key] = reviver(key, data[key]);
            return memo;
          }, {});
        }
      },
      getOptionLabel(option) {
        const data = this.optionData[option];
        console.log("data")
        console.log(this.optionData)
        console.log(option)
        console.log(typeof(option))
        console.log(Object.keys(this.optionData))
        console.log(Object.keys(this.optionData)[0] == option)
        console.log(this.optionData[option])
        if (data) {
          const { firstName, lastName } = data.worker || {};
          console.log(data.worker);
          console.log("data.worker");
          const name = firstName && lastName ? `${firstName} ${lastName}` : null;
          return name ? `${option} (${name})` : option;
        } else {
          return option;
        }
      }
    },
    components: { CalcFieldBox, CalcLabel, CalcField, vSelect }
  }
</script>
