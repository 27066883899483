var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "death-benefits-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      "disabled-dates": { to: new Date(1996, 0, 1) },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.annualSpend.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "annual-spend",
                    error: _vm.errorMessage("annualSpend"),
                  },
                },
                [
                  _vm._v(
                    "\n          Amount spent annually to support any partial dependents:\n          "
                  ),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the amount annually devoted to the support of the partial dependents. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "12.18" } }),
                      _vm._v(" for details.\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("span", [_vm._v("$")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.annualSpend,
                      expression: "annualSpend",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "annual-spend" },
                  domProps: { value: _vm.annualSpend },
                  on: {
                    change: [
                      function ($event) {
                        _vm.annualSpend = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.annualSpend.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("h4", [
              _vm._v(
                "The Death Benefits owed are as follows under each of the indicated scenarios."
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "longResultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("One total dependent and no partial dependents:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(250000))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "longResultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Two total dependents (and any number of partial dependents):"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(290000))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "longResultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "\n        Three or more total dependents (and any number of partial dependents):\n      "
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(320000))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "longResultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "One total dependent plus one or more partial dependents:"
                ),
              ]),
              _vm._v(" "),
              _vm.oneTotalDependentOnePlusPartial > 290000
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("currency")(290000)) +
                        " maximum benefits\n      "
                    ),
                  ])
                : _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("currency")(250000)) +
                        " + (4 × " +
                        _vm._s(_vm._f("currency")(_vm.annualSpend)) +
                        ") = " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.oneTotalDependentOnePlusPartial
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "longResultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("No total and one or more partial dependents:"),
              ]),
              _vm._v(" "),
              _vm.noTotalDependentsOnePlusPartial > 250000
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm._f("currency")(250000)) +
                        " maximum benefits\n      "
                    ),
                  ])
                : _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        8 × " +
                        _vm._s(_vm._f("currency")(_vm.annualSpend)) +
                        " = " +
                        _vm._s(
                          _vm._f("currency")(
                            _vm.noTotalDependentsOnePlusPartial
                          )
                        ) +
                        "\n      "
                    ),
                  ]),
            ]),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "death-benefits-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }