<template>
  <calc-field-box v-if="pdRating !== 100">
    <div class="formFieldNotation">
      <i class="far fa-arrow-up"></i>
      <template v-if="pdRating < 70">
        To determine the present value of PD, see the
        <calculator-link name="pv_of_pd_and_lp" :claim-number="claimNumber"/> calculator. To calculate the commutation
        of PD payments, see the <calculator-link name="commutation_pd" :claim-number="claimNumber"/> calculator.
      </template>
      <template v-else>
        To determine the present value of Permanent Disability and Life Pension payments owed, see the
        <calculator-link name="pv_of_pd_and_lp" :claim-number="claimNumber"/> calculator.

        <div style="margin-top: 10px">
          <i class="far fa-arrow-up"></i>
          To calculate the commutation of PD payments, see the
          <calculator-link name="commutation_pd" :claim-number="claimNumber"/> calculator. To calculate a commutation of
          the Life Pension see the <calculator-link name="commutation_lp" :claim-number="claimNumber"/> calculator.
        </div>
      </template>
    </div>
  </calc-field-box>
</template>

<script>
  import CalcFieldBox from '../templates/calc-field-box';
  import CalculatorLink from '../templates/calculator-link';

  export default {
    props: {
      pdRating: {
        type: Number,
        required: true
      },
      claimNumber: String,
      default: null
    },
    components: { CalcFieldBox, CalculatorLink }
  }
</script>
