import { differenceInDays, getYear, max, min } from 'date-fns';

export class PaymentYear {
  constructor(wages, year, startRate, startDate, endDate) {
    this.wages = wages;
    this.year = year;
    this.startRate = startRate;
    this.startDate = startDate;
    this.endDate = endDate;
  }

  get startYear() {
    return getYear(this.startDate);
  }

  get startOfYear() {
    return new Date(this.year, 0, 1);
  }

  get endOfYear() {
    return new Date(this.year, 11, 31);
  }

  get rate() {
    return Math.max(1, this.wages[this.year] / this.wages[this.startYear]) * this.startRate;
  }

  get weeks() {
    const start = max(this.startOfYear, this.startDate);
    const end = min(this.endOfYear, this.endDate);
    return (differenceInDays(end, start) + 1) / 7;
  }
}

export const totalPayments = (wages, startRate, startDate, endDate) => {
  let currentYear = getYear(startDate);
  const lastYear = getYear(endDate);
  let result = 0;
  let residual = 0;

  while (currentYear <= lastYear) {
    const paymentYear = new PaymentYear(wages, currentYear, startRate, startDate, endDate);
    const totalWeeks = paymentYear.weeks + residual;
    const numberOfPayments = currentYear === lastYear ? totalWeeks : Math.trunc(totalWeeks);
    residual = totalWeeks - Math.trunc(totalWeeks);
    result += numberOfPayments * paymentYear.rate;
    currentYear++;
  }

  return result;
};
