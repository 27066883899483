<template>
  <tr>
    <td><a :href="editUrl">{{ calculator.report_type }}</a></td>
    <td><a :href="editUrl">{{ calculator.first_name }}</a></td>
    <td><a :href="editUrl">{{ calculator.last_name }}</a></td>
    <td><a :href="editUrl">{{ calculator.report_date }}</a></td>
    <td>
      <abbr class="tooltip-top" data-tooltip="View/Edit">
        <a :href="editUrl">
          <i class="fas fa-pencil-alt" aria-hidden="true"></i>
        </a>
      </abbr>
      <abbr class="tooltip-top" data-tooltip="Delete" @click="$emit('delete', index)">
        <a href="javascript:void(0)">
          <i class="fas fa-times-circle" aria-hidden="true"></i>
        </a>
      </abbr>
    </td>
  </tr>
</template>

<script>
  export default {
    props: {
      calculator: {
        type: Object,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    computed: {
      editUrl() {
        const { id, component } = this.calculator;
        return `/calculators/${component}/${id}`;
      }
    }
  }
</script>
