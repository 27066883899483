<template>
  <table class="benefits-through-doc">
    <colgroup>
      <col>
      <col>
      <col class="inputCol">
      <col>
      <col>
      <col>
      <col>
      <col>
    </colgroup>
    <thead>
      <tr>
        <th>From</th>
        <th>Through</th>
        <th>Weekly Rate</th>
        <th>Number of Weeks</th>
        <th>Paid in Period</th>
        <th>Weeks from Start</th>
        <th>Paid from Start</th>
        <th>Balance Remaining</th>
      </tr>
    </thead>
    <tbody>
      <benefit-table-row
        v-for="(row, index) in rows"
        :key="index"
        :from="row.from"
        :through="row.through"
        :rate="row.rate"
        :index="index">
      </benefit-table-row>
    </tbody>
  </table>
</template>

<script>
  import BenefitTableRow from './benefit-table-row.vue';
  import { createNamespacedHelpers } from 'vuex';

  const { mapGetters } = createNamespacedHelpers('commutationOfDeathBenefits/beforeDoc');

  export default {
    computed: {
      ...mapGetters(['rows'])
    },
    components: { BenefitTableRow }
  }
</script>
