<template>
  <div class="calcLabel" :class="containerClass">
    <label :for="target" :data-content="error">
      <slot></slot>
      <i class="fa fa-info-circle" v-if="$slots.tooltip"></i>
      <div class="ttip" v-if="$slots.tooltip">
        <slot name="tooltip"></slot>
      </div>
    </label>
  </div>
</template>

<script>
  export default {
    props: {
      containerClass: String,
      target: String,
      error: String
    }
  }
</script>
