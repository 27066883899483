<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="commutation-pd-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm"/>
        <injured-worker v-model="worker" />
        <calc-field-box>
          <calc-label container-class="full-width">
            Calculate Commutation of:
            <template slot="tooltip">
              Select the type of commutation you wish to perform:
              <ul>
                <li>
                  Choose <strong>All Remaining Permanent Disability</strong> to calculate the lump-sum payment
                  received in exchange for forgoing all remaining PD payments.
                </li>
                <li>
                  Choose <strong>Permanent Disability "Off the Far End"</strong> to determine how many PD payments
                  would be eliminated from the end of the benefit period in exchange for receiving a specified
                  lump-sum payment.
                </li>
                <li>
                  Choose <strong>Permanent Disability "Off the Side"</strong> to determine the reduction in weekly PD
                  benefit payments required to receive a specified lump-sum payment.
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio container-class="full-width">
              <input type="radio" v-model="calculationType" id="all-remaining" value="all-remaining">
              <label for="all-remaining" slot="label">
                All Remaining Permanent Disability
              </label>
            </pretty-radio>
            <pretty-radio container-class="full-width">
              <input type="radio" v-model="calculationType" id="off-the-far-end" value="off-the-far-end">
              <label for="off-the-far-end" slot="label">
                Permanent Disability "Off the Far End"
              </label>
            </pretty-radio>
            <pretty-radio container-class="full-width">
              <input type="radio" v-model="calculationType" id="uniform-reduction" value="uniform-reduction">
              <label for="uniform-reduction" slot="label">
                Permanent Disability "Off the Side"
              </label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdCommencementDate.$error">
          <calc-label target="pd-commencement-date"
                      :error="errorMessage('pdCommencementDate', { min: 'the Date of Injury' })">
            PD Commencement Date:
            <template slot="tooltip">Enter the date on which permanent disability payments began.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="pd-commencement-date"
                         input-class="shortField" @input="$v.pdCommencementDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation"
                      :error="errorMessage('dateOfCalculation', { min: 'the PD Commencement Date' })">
            Date of Commutation (DOC):
            <template slot="tooltip">Enter the date of the commutation payment.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdRating.$error">
          <calc-label target="pd-rating" :error="errorMessage('pdRating')">
            PD Rating:
            <template slot="tooltip">
              Enter the PD % value. Obtain this from a calculated rating string or combined rating. If you need to
              calculate the PD Rating, use the <calculator-link name="pd"/>.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="pdRating" type="number" id="pd-rating" min="1" max="99" class="shortField"
                   @change="$v.pdRating.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customPdWeeklyRate.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': pdRateOption === 'custom' }">
          <calc-label :error="errorMessage('customPdWeeklyRate')">
            PD Weekly Rate:
            <template slot="tooltip">
              Select the Min or Max option to use that statutory limit for the date of injury and calculated PD rating.
              Or select the Custom option to input the actual PD Weekly Rate. If the amount you enter is outside
              applicable limits, it will be modified accordingly and the updated value displayed in the Results section.
              (See <soc-section section="10.61"/> for more detail on statutory
              limits on PD.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="pdRateOption" type="radio" value="min" id="pd-rate-min"
                     @change="customPdWeeklyRate = null">
              <label for="pd-rate-min" slot="label">Min</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="pdRateOption" type="radio" value="max" id="pd-rate-max"
                     @change="customPdWeeklyRate = null">
              <label for="pd-rate-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="pdRateOption" type="radio" value="custom" id="pd-rate-custom">
              <label for="pd-rate-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customPdWeeklyRate" id="custom-pd-rate" type="text"
                       class="conditional-input" placeholder="Custom"
                       @change="$v.customPdWeeklyRate.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType !== 'all-remaining'" :error="$v.amountToCommute.$error">
          <calc-label target="amount-to-commute" :error="errorMessage('amountToCommute')">
            Amount to Commute:
            <template slot="tooltip">
              Enter the specific lump sum payment to be received in exchange for commuting a portion of the payments.
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="amountToCommute" type="text" id="amount-to-commute" class="shortField" >
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Commutation Type:</div>
        <div class="resultValue" v-if="calculationType === 'all-remaining'">All Remaining PD</div>
        <div class="resultValue" v-if="calculationType === 'off-the-far-end'">PD "Off the Far End"</div>
        <div class="resultValue" v-if="calculationType === 'uniform-reduction'">
          PD "Off the Side"
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">PD weekly rate:</div>
        <div class="resultValue">{{ pdWeeklyRate | currency }} {{ pdRateSuffix }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Total weeks of PD:</div>
        <div class="resultValue">{{ totalWeeksOfPd }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Gross PD payout:</div>
        <div class="resultValue">{{ grossIndemnity | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Days from commencement of PD through DOC:</div>
        <div class="resultValue">{{ daysBetweenDocAndPdStart }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Weeks from commencement of PD through DOC:</div>
        <div class="resultValue">{{ weeksBetweenDocAndPdStart | toFixed(4) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">PD paid prior to DOC:</div>
        <div class="resultValue">{{ pdPaidPriorToDoc | currency }}</div>
      </div>

      <template v-if="calculationType === 'all-remaining'">
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD remaining on DOC:</div>
          <div class="resultValue">{{ weeksOfPdRemainingAfterDoc | toFixed(4) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Gross value of all PD remaining:</div>
          <div class="resultValue">{{ grossValueOfAllPdRemaining | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Commuted value of PD remaining:</div>
          <div class="resultValue">{{ commutedValueOfPdRemaining | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Difference between Gross and Commuted value remaining:</div>
          <div class="resultValue">{{ differenceBetweenGrossAndCommutedRemaining | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due per day for late payment after DOC:</div>
          <div class="resultValue">{{ commutedValueOfPdRemaining * 0.1 / 365 | currency }}</div>
        </div>
      </template>

      <template v-if="calculationType === 'off-the-far-end'">
        <div class="resultContainer">
          <div class="resultLabel">Amount to commute:</div>
          <div class="resultValue">{{ amountToCommute | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD remaining on DOC:</div>
          <div class="resultValue">{{ weeksOfPdRemainingAfterDoc | toFixed(4) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD remaining to be paid on DOC after commutation:</div>
          <div class="resultValue">{{ weeksOfPdRemainingAfterCommutationOffFarEnd | toFixed(4) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Number of weeks of PD eliminated from the far end:</div>
          <div class="resultValue">{{ weeksOfPdEliminatedFromFarEnd | toFixed(4) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Gross value of PD remaining after disbursement:</div>
          <div class="resultValue">{{ grossValuePdOff | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Remaining PD owed for period after DOC:</div>
          <div class="resultValue">{{ pdStillOwedForPeriodAfterDocOffFarEnd | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Difference between Gross and Commuted value remaining:</div>
          <div class="resultValue">{{ differenceBetweenGrossAndCommutedFarEnd | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional payment to bring commutation to whole number of weeks:</div>
          <div class="resultValue">{{ additionalPaymentToWholeNumberOfWeeks | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD remaining after payment to bring to whole weeks:</div>
          <div class="resultValue">{{ weeksOfPdToBringWholeWeeks }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due per day for late payment after DOC:</div>
          <div class="resultValue">{{ additionalInterestDuePerDayForLatePaymentFarEnd | currency }}</div>
        </div>
      </template>

      <template v-if="calculationType === 'uniform-reduction'">
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD remaining on DOC:</div>
          <div class="resultValue">{{ weeksOfPdRemainingAfterDoc | toFixed(4) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Amount to commute:</div>
          <div class="resultValue">{{ amountToCommute | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Amount of weekly reduction required to produce lump sum:</div>
          <div class="resultValue">{{ reductionRequiredToProduceLumpSum | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">New PD rate after reduction:</div>
          <div class="resultValue">{{ newPdRateAfterReduction | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Gross value of PD remaining after disbursement:</div>
          <div class="resultValue">{{ grossValuePdOff | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Remaining PD owed for period after DOC:</div>
          <div class="resultValue">{{ pdStillOwedForPeriodAfterDocUniformReduction | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Difference between Gross and Commuted value remaining:</div>
          <div class="resultValue">{{ differenceGrossCommutedRemaining | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due per day for late payment after DOC:</div>
          <div class="resultValue">{{ additionalInterestDuePerDayForLatePaymentSide | currency }}</div>
        </div>
      </template>

      <calc-field-box v-if="pdRating >= 70">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          The commutation adjustments shown above apply only to PD payments, not to Life Pension payments, which would
          begin following the completion of the period of PD. To calculate a commutation of the Life Pension see the
          <calculator-link name="commutation_lp" :claim-number="claimNumber"/> calculator.
        </div>
      </calc-field-box>

      <save-button form="commutation-pd-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import differenceInDays from 'date-fns/difference_in_days';
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { roundFloat } from '../../util/float';
  import { format as dateFormat } from 'date-fns';
  import { props, provide, filters, methods, components } from '../mixins';
  import { validationMixin } from 'vuelidate';
  import { required, between, minValue, maxValue, integer } from 'vuelidate/lib/validators';
  import saveMixin from '../saveable/commutation-pd';

  const initialData = () => {
    return {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      calculationType: 'all-remaining',
      dateOfInjury: null,
      pdCommencementDate: null,
      dateOfCalculation: null,
      pdRating: null,
      pdRateOption: 'max',
      customPdWeeklyRate: null,
      totalWeeksOfPd: null,
      pvOfWeeksRemainingAfterDoc: null,
      amountToCommute: null,
      weeksOfPdRemainingAfterCommutationOffFarEnd: null,
      ppdEarningsLimit: null,
      results: false,
      saveStatus: null,
      saveMessage: null
    }
  };

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    data() {
      return Object.assign({}, initialData(), this.form);
    },
    asyncComputed: {
      totalWeeksOfPd: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/pdd_durations/lookup', { params }).then(r => roundFloat(r.data));
        },
        shouldUpdate() {
          return !(this.$v.pdRating.$invalid || this.$v.dateOfInjury.$invalid);
        },
        default: 0
      },
      pvOfWeeksRemainingAfterDoc: {
        get() {
          if (this.weeksOfPdRemainingAfterDoc === 0) { return Promise.resolve(0) }
          const params = { weeks: this.weeksOfPdRemainingAfterDoc.toFixed(4) };
          return axios.get('/pv_of_pd_lookups/interpolate_present_value', { params }).then(r => roundFloat(r.data));
        },
        shouldUpdate() {
          return this.weeksOfPdRemainingAfterDoc >= 0;
        },
        default: 0
      },
      weeksOfPdRemainingAfterCommutationOffFarEnd: {
        get() {
          if (this.pvOfWeeksRemainingAfterCommutationOffFarEnd < 1) { return Promise.resolve(this.pvOfWeeksRemainingAfterCommutationOffFarEnd) }
          const params = { value: this.pvOfWeeksRemainingAfterCommutationOffFarEnd.toFixed(4) };
          return axios.get('/pv_of_pd_lookups/weeks_of_pd_remaining_after_commutation', { params }).then(r => roundFloat(r.data));
        },
        shouldUpdate() {
          return this.pvOfWeeksRemainingAfterCommutationOffFarEnd >= 0;
        },
        default: 0
      },
      ppdEarningsLimit: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/ppd_earnings_limits/lookup', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return !(this.$v.pdRating.$invalid || this.$v.dateOfInjury.$invalid);
        },
        default() { return { min: 0, max: 0 } }
      }
    },
    computed: {
      pdRateSuffix() {
        return {
          [this.minPdWeeklyRate]: '(Min.)',
          [this.maxPdWeeklyRate]: '(Max.)',
        }[this.pdWeeklyRate]
      },
      daysBetweenDocAndPdStart() {
        return differenceInDays(this.dateOfCalculation, this.pdCommencementDate) + 1;
      },
      weeksBetweenDocAndPdStart() {
        return this.daysBetweenDocAndPdStart / 7.0;
      },
      weeksOfPdRemainingAfterDoc() {
        return Math.max(roundFloat(this.totalWeeksOfPd - this.weeksBetweenDocAndPdStart), 0);
      },
      weeksOfPdToBringWholeWeeks() {
        return Math.trunc(this.weeksOfPdRemainingAfterCommutationOffFarEnd);
      },
      additionalPaymentToWholeNumberOfWeeks() {
        return (this.weeksOfPdRemainingAfterCommutationOffFarEnd - this.weeksOfPdToBringWholeWeeks) * this.pdWeeklyRate; 
      },
      grossValueOfAllPdRemaining() {
        return Math.max(roundFloat(this.grossIndemnity - this.pdPaidPriorToDoc), 0);
      },
      commutedValueOfPdRemaining() {
        return this.pdWeeklyRate * this.pvOfWeeksRemainingAfterDoc;
      },
      additionalInterestDuePerDayForLatePaymentSide() {
        if(isNaN(this.reductionRequiredToProduceLumpSum)){
          return NaN;
        }
        return this.amountToCommute * 0.1 / 365
      },
      additionalInterestDuePerDayForLatePaymentFarEnd() {
        if(this.weeksOfPdEliminatedFromFarEnd == 0) {
          return 0;
        }
        return this.amountToCommute * 0.1 / 365
      },
      differenceBetweenGrossAndCommutedRemaining() {
        return Math.max(roundFloat(this.grossValueOfAllPdRemaining - this.commutedValueOfPdRemaining), 0);
      },
      pvOfAmountToCommute() {
        return roundFloat(this.amountToCommute * 1.0 / this.pdWeeklyRate);
      },
      pvOfWeeksRemainingAfterCommutationOffFarEnd() {
        return roundFloat(this.pvOfWeeksRemainingAfterDoc - this.pvOfAmountToCommute);
      },
      pdStillOwedForPeriodAfterDocOffFarEnd() {
        return this.pdWeeklyRate * this.weeksOfPdRemainingAfterCommutationOffFarEnd;
      },
      weeksOfPdEliminatedFromFarEnd() {
        return this.weeksOfPdRemainingAfterDoc - this.weeksOfPdRemainingAfterCommutationOffFarEnd;
      },
      grossValuePdOff() {
        return Math.max(roundFloat(this.grossIndemnity - this.pdPaidPriorToDoc - this.amountToCommute), 0);
      },
      differenceBetweenGrossAndCommutedFarEnd() {
        return Math.max(roundFloat(this.grossValuePdOff - this.pdStillOwedForPeriodAfterDocOffFarEnd), 0);
      },
      reductionRequiredToProduceLumpSum() {
        return roundFloat(this.amountToCommute * 1.0 / this.pvOfWeeksRemainingAfterDoc, 2);
      },
      newPdRateAfterReduction() {
        return roundFloat(this.pdWeeklyRate - this.reductionRequiredToProduceLumpSum, 2);
      },
      pdStillOwedForPeriodAfterDocUniformReduction() {
        return this.weeksOfPdRemainingAfterDoc * this.newPdRateAfterReduction;
      },
      differenceGrossCommutedRemaining() {
        return Math.max(roundFloat(this.grossValuePdOff - this.pdStillOwedForPeriodAfterDocUniformReduction), 0);
      },
      grossIndemnity() {
        return this.totalWeeksOfPd * this.pdWeeklyRate;
      },
      pdPaidPriorToDoc() {
        return Math.min(this.weeksBetweenDocAndPdStart * this.pdWeeklyRate, this.grossIndemnity);
      },
      boundaryMode() {
        return ['min', 'max'].includes(this.pdRateOption);
      },
      minPdWeeklyRate() {
        if (!this.ppdEarningsLimit) { return 0 }
        return this.ppdEarningsLimit.min / 1.5;
      },
      maxPdWeeklyRate() {
        if (!this.ppdEarningsLimit) { return Infinity }
        return this.ppdEarningsLimit.max / 1.5;
      },
      pdWeeklyRate() {
        return {
          min: this.minPdWeeklyRate,
          max: this.maxPdWeeklyRate,
          custom: Math.min(Math.max(this.customPdWeeklyRate, this.minPdWeeklyRate), this.maxPdWeeklyRate)
        }[this.pdRateOption]
      }
    },
    validations: {
      dateOfInjury: { required, minValue: minValue(new Date(1996, 0, 1)), maxValue: maxValue(methods.methods.endOfYear()) },
      pdCommencementDate: { required, minValue(value) { return minValue(this.dateOfInjury)(value) } },
      dateOfCalculation: { required, minValue(value) { return minValue(this.pdCommencementDate)(value) } },
      pdRating: { required, integer, between: between(1, 99) },
      customPdWeeklyRate: { required(value) { return this.boundaryMode ? true : required(value) } },
      amountToCommute: {
        nothingToCommute: (value, vm) => {
           return vm.calculationType === 'all-remaining' || vm.commutedValueOfPdRemaining != 0 },
        required(value) { return ((this.calculationType === 'all-remaining' || this.commutedValueOfPdRemaining == 0) ? true : required(value)) },
        minValue: minValue(0),
        maxValue(value) { return this.calculationType === 'all-remaining' ? true : maxValue(roundFloat(this.commutedValueOfPdRemaining))(value) }
      }
    },
    components: { DatePicker }
  }
</script>
