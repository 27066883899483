var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "pv-of-ptd-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfBirth.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-birth",
                    error: _vm.errorMessage("dateOfBirth"),
                  },
                },
                [
                  _vm._v("\n          Date of Birth:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the Injured Worker's Date of Birth."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-birth",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfBirth.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfBirth,
                      callback: function ($$v) {
                        _vm.dateOfBirth = $$v
                      },
                      expression: "dateOfBirth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Gender:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Select the Injured Worker's gender. (This information is needed for the Life Expectancy lookup.)\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Male", id: "male" },
                      domProps: { checked: _vm._q(_vm.gender, "Male") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Male"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "male" }, slot: "label" },
                      [_vm._v("Male")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Female", id: "female" },
                      domProps: { checked: _vm._q(_vm.gender, "Female") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Female"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "female" },
                        slot: "label",
                      },
                      [_vm._v("Female")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customRatedAge.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.ratedAgeOption === "adjust",
                },
              },
            },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("customRatedAge") } },
                [
                  _vm._v('\n          Adjustment for "Rated Age"\n          '),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            To calculate the Injured Worker's life expectancy using a \"Rated Age,\" input the number of years the\n            person's life expectancy is anticipated to decrease as a result of illness or injury. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "14.83" } }),
                      _vm._v("\n            .\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "none", id: "none" },
                      domProps: { checked: _vm._q(_vm.ratedAgeOption, "none") },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "none"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "none" }, slot: "label" },
                      [_vm._v("None")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "adjust", id: "adjust" },
                      domProps: {
                        checked: _vm._q(_vm.ratedAgeOption, "adjust"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "adjust"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "adjust" },
                        slot: "label",
                      },
                      [_vm._v("Adjust")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customRatedAge,
                              expression: "customRatedAge",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Years Reduced" },
                          domProps: { value: _vm.customRatedAge },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customRatedAge = _vm._n($event.target.value)
                              },
                              function ($event) {
                                return _vm.$v.customRatedAge.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      "disabled-dates": {
                        to: new Date(1996, 0, 1),
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdCommencementDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "pd-commencement-date",
                    error: _vm.errorMessage("pdCommencementDate"),
                  },
                },
                [
                  _vm._v("\n          PTD Commencement Date:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the date on which PTD payments began. This is the Permanent and Stationary date (see\n            "
                      ),
                      _c("soc-section", { attrs: { section: "10.3" } }),
                      _vm._v(").\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "pd-commencement-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yyyy",
                      disabledDates: { to: _vm.dateOfInjury },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.pdCommencementDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.pdCommencementDate,
                      callback: function ($$v) {
                        _vm.pdCommencementDate = $$v
                      },
                      expression: "pdCommencementDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfCalculation.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-calculation",
                    error: _vm.errorMessage("dateOfCalculation"),
                  },
                },
                [
                  _vm._v("\n          Date of Calculation:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the date as of which you wish to calculate the present value."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-calculation",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      disabledDates: { to: _vm.dateOfBirth },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfCalculation.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfCalculation,
                      callback: function ($$v) {
                        _vm.dateOfCalculation = $$v
                      },
                      expression: "dateOfCalculation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customAverageWeeklyEarnings.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.wageOption === "custom",
                },
              },
            },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    error: _vm.errorMessage("customAverageWeeklyEarnings"),
                  },
                },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Select Custom to input the Average Weekly Earnings for the injured worker. If the AWE you enter is outside\n            applicable limits, it will be modified accordingly and the updated value displayed in the Results section.\n            (See "
                      ),
                      _c("soc-section", { attrs: { section: "8.6" } }),
                      _vm._v(" for more detail on AWE.)\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "max", id: "awe-max" },
                      domProps: { checked: _vm._q(_vm.wageOption, "max") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "max"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-max" },
                        slot: "label",
                      },
                      [_vm._v("Max")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "custom",
                        id: "awe-custom",
                      },
                      domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                      on: {
                        change: function ($event) {
                          _vm.wageOption = "custom"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-custom" },
                        slot: "label",
                      },
                      [_vm._v("Custom")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("abbr", [_vm._v("$")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customAverageWeeklyEarnings,
                              expression: "customAverageWeeklyEarnings",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Enter Amount" },
                          domProps: { value: _vm.customAverageWeeklyEarnings },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customAverageWeeklyEarnings = _vm._n(
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$v.customAverageWeeklyEarnings.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.interestRate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "interest-rate",
                    error: _vm.errorMessage("interestRate"),
                  },
                },
                [
                  _vm._v("\n          Annual Discount Rate:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the interest rate for present value discounting."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.interestRate,
                      expression: "interestRate",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "interest-rate" },
                  domProps: { value: _vm.interestRate },
                  on: {
                    change: [
                      function ($event) {
                        _vm.interestRate = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.interestRate.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.assumedAnnualIncreases.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "assumed-annual-increases",
                    error: _vm.errorMessage("assumedAnnualIncreases"),
                  },
                },
                [
                  _vm._v("\n          Assumed COLA Increases:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter an assumption for the average rate of annual COLA increases to be applied. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "10.61" } }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.assumedAnnualIncreases,
                      expression: "assumedAnnualIncreases",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "assumed-annual-increases" },
                  domProps: { value: _vm.assumedAnnualIncreases },
                  on: {
                    change: [
                      function ($event) {
                        _vm.assumedAnnualIncreases = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.assumedAnnualIncreases.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Calculation Mode:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Choose the method for calculating the present value of PTD payments:\n            "
                    ),
                    _c("ul", [
                      _c("li", [
                        _c("b", [_vm._v("Reduce PTD for Mortality")]),
                        _vm._v(
                          " - reduces the expected payout in each future year to account for the\n                portion of the Injured Worker's cohort that will no longer be alive in that year. This is the\n                actuarily accurate approach and results in a lower calculated present value.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _c("b", [_vm._v("Full PTD through Life Expectancy")]),
                        _vm._v(
                          " - assumes 100% of all PTD payments are received by the\n                Injured Worker in the future up to the date of the life expectancy cut-off. This results in a higher\n                calculated present value.\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reducePtd,
                          expression: "reducePtd",
                        },
                      ],
                      attrs: { type: "radio", value: "yes", id: "reduce-ptd" },
                      domProps: { checked: _vm._q(_vm.reducePtd, "yes") },
                      on: {
                        change: function ($event) {
                          _vm.reducePtd = "yes"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "reduce-ptd" },
                        slot: "label",
                      },
                      [_vm._v("Reduce PTD for Mortality")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.reducePtd,
                          expression: "reducePtd",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "no",
                        id: "no-reduce-ptd",
                      },
                      domProps: { checked: _vm._q(_vm.reducePtd, "no") },
                      on: {
                        change: function ($event) {
                          _vm.reducePtd = "no"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "no-reduce-ptd" },
                        slot: "label",
                      },
                      [_vm._v("Full PTD through Life Expectancy")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.ratedAgeOption === "none"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Age on date of calculation:"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ratedAgeOption === "adjust"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v('"Rated Age" on date of calculation:'),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.ageInYearsAtDoc, 2))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Life Expectancy:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.lifeExpectancy, 2))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Average Weekly Earnings:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("currency")(_vm.averageWeeklyEarnings.awe.pd)
                    ) +
                    " " +
                    _vm._s(_vm.averageWeeklyEarnings.suffix.pd) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.ptdRate.toFixed(2) !== _vm.ptdRateOnDoc.toFixed(2)
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("\n        PTD weekly rate at start date:\n      "),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PTD weekly rate:"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.ptdRate))),
              ]),
            ]),
            _vm._v(" "),
            _vm.ptdRate.toFixed(2) !== _vm.ptdRateOnDoc.toFixed(2)
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PTD weekly rate on DOC:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.ptdRateOnDoc))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isDocAfterPtdStartDate
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Weeks from commencement of PTD through DOC:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      _vm._s(
                        _vm._f("toFixed")(
                          _vm.weeksFromPtdStartDateThroughDoc,
                          2
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.isDocAfterPtdStartDate
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PTD paid prior to DOC:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(_vm._s(_vm._f("currency")(_vm.ptdPaidPriorToDoc))),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Gross Value of Remaining PTD Payments with COLA applied:"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.grossValueOfPtdPayments))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v(
                  "Present Value of Remaining PTD Payments with COLA applied:"
                ),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(_vm._f("currency")(_vm.presentValueOfPtdPayments))
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Difference between Gross and Present Values:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(_vm.differenceBetweenGrossAndPresent)
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "pv-of-ptd-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }