var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "buttonContainer" }, [
    _c(
      "button",
      {
        staticClass: "button clear-button dark",
        attrs: { type: "reset" },
        on: {
          click: function ($event) {
            return _vm.$emit("clear")
          },
        },
      },
      [_vm._v("Clear Form")]
    ),
    _vm._v(" "),
    _c(
      "button",
      {
        staticClass: "button black-button",
        attrs: { type: "button" },
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [_vm._v("Calculate")]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }