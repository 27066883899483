<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="commutation-db-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.benefitStartDate.$error">
          <calc-label target="benefit-start-date" :error="errorMessage('benefitStartDate')">
            Date of Death:
            <template slot="tooltip">
              Enter the date the Injured Worker died. This is used as the benefit start date. See
              <soc-section section="12.21"/>.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="benefitStartDate" id="benefit-start-date"
                         input-class="shortField" @input="$v.benefitStartDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :open-date="dateOfInjury"
                         :disabled-dates="{ to: dateOfInjury, from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation" :error="errorMessage('dateOfCalculation')">
            Date of Commutation:
            <template slot="tooltip">
              Enter the date on which you wish to calculate the commutation.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :open-date="benefitStartDate"
                         :disabled-dates="{ to: benefitStartDate }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.averageWeeklyEarnings.$error">
          <calc-label :error="errorMessage('averageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Please enter the actual calculated Average Weekly Earnings. This is required because in some cases the
              maximum statutory value will change over the course of the payout period, so the actual value is needed to
              determine how the maximum would potentially be applied.
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="averageWeeklyEarnings" class="shortField" type="text"
                   @change="$v.averageWeeklyEarnings.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label target="calculation-type">
            Calculation Type:
            <template slot="tooltip">
              Select to indicate whether you will calculate the commutation based on the maximum benefits payable amount
              or, if there is one or more totally dependent minor children, to enter the ending date for the payment of
              benefits.
            </template>
          </calc-label>
          <calc-field>
            <select v-model="calculationType" id="calculation-type">
              <option value="maxBenefit">Max Benefit</option>
              <option value="endDate">End Date</option>
            </select>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType === 'maxBenefit'" :error="$v.benefitAmount.$error">
          <calc-label target="max-benefit-amount" :error="errorMessage('benefitAmount')">
            Max Benefit Amount:
            <template slot="tooltip">
              Enter the maximum benefits payable in this matter. See <soc-section section="12.18"/> or the
              <calculator-link name="death_benefits"/> calculator if you need assistance determining what value to enter
              .
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="benefitAmount" id="max-benefit-amount" class="shortField" type="text"
                   @change="$v.benefitAmount.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType === 'endDate'" :error="$v.benefitEndDate.$error">
          <calc-label target="benefit-end-date" :error="errorMessage('benefitEndDate')">
            Benefit End Date:
            <template slot="tooltip">
              If there is one or more totally dependent minor children, enter the date the youngest child will reach the
              age of majority. For totally incapacitated minor children, use the
              <calculator-link name="life_expectancy"/> calculator and enter an appropriate date. See
              <soc-section section="12.19"/>.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="benefitEndDate" id="benefit-end-date"
                         input-class="shortField" @input="$v.benefitEndDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.rateOfIncrease.$error">
          <calc-label target="rate-of-increase" :error="errorMessage('rateOfIncrease')">
            Assumed Rate of Increase:
            <template slot="tooltip">
              What percentage rate do you wish to assume for the yearly increase of the TTD maximum value? This value is
              analogous to the COLA increase and like COLA, is based on the annual increase in the State Average Weekly
              Wage.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="rateOfIncrease" type="text" id="rate-of-increase" class="shortField">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Benefits paid prior to Date of Commutation:</div>
        <div class="resultValue">{{ benefitsPaidPriorToDOC | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Gross value of outstanding payments owed:</div>
        <div class="resultValue">{{ grossValueOfOutstandingPaymentsOwed | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Commuted value of outstanding payments owed:</div>
        <div class="resultValue">{{ commutedValueOfOutstandingPaymentsOwed | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference between gross and commuted value:</div>
        <div class="resultValue">
          {{ grossValueOfOutstandingPaymentsOwed - commutedValueOfOutstandingPaymentsOwed | currency }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Interest due for each day late:</div>
        <div class="resultValue">{{ interestDue | currency }}</div>
      </div>

      <save-button form="commutation-db-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>

    <div class="calc-detail-container" v-if="!$v.$invalid && results">
      <h2>Cash Flow Summary</h2>
      <div class="calcTableBox">
        <div class="calcLabel">
          <label>Benefits Paid through DOC:</label>
        </div>
        <benefit-table-before-doc></benefit-table-before-doc>
      </div>

      <div class="calcTableBox">
        <div class="calcLabel">
          <label>Benefits to be Paid after DOC:</label>
        </div>
        <benefit-table-after-doc></benefit-table-after-doc>
      </div>
    </div>
  </div>
</template>

<script>
  import BenefitTableBeforeDoc from './death_benefits/before_doc/benefit-table.vue';
  import BenefitTableAfterDoc from './death_benefits/after_doc/benefit-table.vue';
  import DatePicker from 'vuejs-datepicker';
  import { parse as parseDate } from 'date-fns';
  import axios from 'axios';
  import { validationMixin } from 'vuelidate';
  import { required, minValue, maxValue } from 'vuelidate/lib/validators';
  import { props, provide, methods, filters, components } from '../mixins';
  import { greaterThan } from '../validators';
  import saveMixin from '../saveable/commutation-db';

  const mapFormInput = function(inputs) {
    return inputs.reduce((memo, name) => {
      memo[name] = {
        get() {
          return this.$store.state.commutationOfDeathBenefits[name];
        },
        set(value) {
          this.$store.commit('commutationOfDeathBenefits/updateFormInput', { name, value });
        }
      };
      return memo;
    }, {});
  };

  export default {
    mixins: [props, provide, methods, filters, components, validationMixin, saveMixin],
    created() {
      axios.get('/ttd_limits').then(({ data }) => {
        const values = data.map(({ start_date, end_date, min, max }) => (
          {
            startDate: parseDate(start_date),
            endDate: parseDate(end_date),
            min: parseFloat(min),
            max: parseFloat(max)
          }
        ));
        this.$store.commit('commutationOfDeathBenefits/updateWageLimits', values);
      });

      this.populateForm(this.form);
    },
    destroyed() {
      this.$store.commit('commutationOfDeathBenefits/clearState');
    },
    computed: {
      ...mapFormInput([
        'claimNumber',
        'worker',
        'dateOfInjury',
        'benefitStartDate',
        'dateOfCalculation',
        'benefitAmount',
        'averageWeeklyEarnings',
        'calculationType',
        'benefitEndDate',
        'rateOfIncrease',
        'results',
        'saveStatus',
        'saveMessage'
      ]),
      benefitsPaidPriorToDOC() {
        const getters = this.$store.getters;
        return getters['commutationOfDeathBenefits/intervalAmount']
          .slice(0, getters['commutationOfDeathBenefits/afterDocRowIndex'])
          .reduce((m, v) => m + v, 0);
      },
      grossValueOfOutstandingPaymentsOwed() {
        const getters = this.$store.getters;
        return getters['commutationOfDeathBenefits/intervalAmount']
          .slice(getters['commutationOfDeathBenefits/afterDocRowIndex'])
          .reduce((m, v) => m + v, 0);
      },
      commutedValueOfOutstandingPaymentsOwed() {
        const getters = this.$store.getters;
        return getters['commutationOfDeathBenefits/presentValue']
          .slice(getters['commutationOfDeathBenefits/afterDocRowIndex'])
          .reduce((m, v) => m + v, 0);
      },
      interestDue() {
        return this.commutedValueOfOutstandingPaymentsOwed * 0.1 / 365.24;
      }
    },
    methods: {
      clearForm() {
        this.$store.commit('commutationOfDeathBenefits/resetState');
        this.$v.$reset();
      },
      populateForm(data) {
        Object.keys(data).forEach(name => {
          const value = data[name];
          this.$store.commit('commutationOfDeathBenefits/updateFormInput', { name, value });
        });
      }
    },
    validations: {
      dateOfInjury: { required, minValue: minValue(new Date(2007, 0, 1)), maxValue: maxValue(methods.methods.endOfYear()) },
      benefitStartDate: { required, minValue(value)  { return minValue(this.dateOfInjury)(value) } },
      dateOfCalculation: { required, minValue(value) { return minValue(this.benefitStartDate)(value) } },
      averageWeeklyEarnings: { required, minValue: minValue(0) },
      benefitAmount: {
        required(value) { return this.calculationType === 'maxBenefit' ? required(value) : true },
        greaterThan: greaterThan(0)
      },
      benefitEndDate: {
        required(value) { return this.calculationType === 'endDate' ? required(value) : true },
        minValue(value) { return minValue(this.dateOfCalculation)(value) }
      },
      rateOfIncrease: { required }
    },
    components: {
      DatePicker,
      BenefitTableBeforeDoc,
      BenefitTableAfterDoc
    }
  }
</script>
