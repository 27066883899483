var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "calc-field-box",
    [
      _c(
        "calc-field-box",
        [
          _c(
            "calc-label",
            [
              _vm._v("\n      Name of Injured Worker:\n      "),
              _c("template", { slot: "tooltip" }, [
                _vm._v(
                  "\n        Enter the name of the injured worker for whom you are preparing a calculation.\n      "
                ),
              ]),
            ],
            2
          ),
          _vm._v(" "),
          _c("calc-field", [
            _c("input", {
              ref: "firstName",
              staticStyle: { width: "45%", float: "left" },
              attrs: {
                type: "text",
                id: "worker-first-name",
                name: "data[worker][firstName]",
                placeholder: "First",
              },
              domProps: { value: _vm.value.firstName },
              on: { input: _vm.updateName },
            }),
            _vm._v(" "),
            _c("input", {
              ref: "lastName",
              staticStyle: { width: "45%", float: "right" },
              attrs: {
                type: "text",
                id: "worker-last-name",
                name: "data[worker][lastName]",
                placeholder: "Last",
              },
              domProps: { value: _vm.value.lastName },
              on: { input: _vm.updateName },
            }),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }