var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.age.$error } },
            [
              _c(
                "calc-label",
                { attrs: { target: "age", error: _vm.errorMessage("age") } },
                [
                  _vm._v("\n          Age:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the Injured Worker's Age."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.age,
                      expression: "age",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { id: "age", type: "text" },
                  domProps: { value: _vm.age },
                  on: {
                    change: [
                      function ($event) {
                        _vm.age = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.age.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.gender.$error } },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("gender") } },
                [
                  _vm._v("\n          Gender:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the Injured Worker's Gender."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Male", id: "male" },
                      domProps: { checked: _vm._q(_vm.gender, "Male") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Male"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "male" }, slot: "label" },
                      [_vm._v("Male")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Female", id: "female" },
                      domProps: { checked: _vm._q(_vm.gender, "Female") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Female"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "female" },
                        slot: "label",
                      },
                      [_vm._v("Female")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c("section", { attrs: { id: "calculator-results" } }, [
          _c("h2", [_vm._v("Results")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Expected Years Remaining:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("toFixed")(_vm.ex, 2))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Expected Weeks Remaining:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("commaSeparate")((_vm.ex * 365.24) / 7, 2))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Expected Days Remaining:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("commaSeparate")(_vm.ex * 365.24, 0))),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "p",
            [
              _vm._v(
                '\n      The value shown is a general life expectancy and not reflective of a "rated age", which may be lower due to the\n      existence of medical factors. See '
              ),
              _c("soc-section", { attrs: { section: "14.82" } }),
              _vm._v(" and "),
              _c("soc-section", { attrs: { section: "14.83" } }),
              _vm._v(
                " for in-depth\n      discussions regarding life expectancy and its relevance to settlement calculations.\n    "
              ),
            ],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }