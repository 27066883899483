export default {
  calculators: {
    commutationLp: 'Commutation of Life Pension',
    commutationPd: 'Commutation of PD',
    commutationPtd: 'Commutation of PTD',
    dateWheel: 'Date Differences (The Wheel)',
    deathBenefits: 'Death Benefits',
    lifeExpectancy: 'Life Expectancy Lookup',
    moneyChart: 'PD and Life Pension Payments (Money Chart)',
    pd: 'Rating String/PD Calculator',
    pvOfPdAndLp: 'Present Value of Permanent Disability and Life Pension with COLA',
    pvOfPtd: 'Present Value of PTD with COLA'
  },
  errors: {
    between: 'This field must have a value between %{min} and %{max}',
    integer: 'This field must be an integer',
    maxValue: 'This field must have a value less than or equal to %{max}',
    minValue: 'This field must have a value greater than or equal to %{min}',
    required: 'This field is required',
    numeric: 'This field must be numeric',
    greaterThan: 'This field must have a value greater than %{value}',
    nothingToCommute: 'The entire Permanent Disability benefit was paid prior to the Date of Commutation. There is nothing left to commute.'
  }
}
