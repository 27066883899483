import pick from '../../util/pick';

const inputs = [
  'dateOfInjury', 'benefitStartDate', 'dateOfCalculation', 'calculationType', 'benefitAmount', 'benefitEndDate',
  'averageWeeklyEarnings', 'interestRate', 'rateOfIncrease'
];

const methods = {
  extractInputData() {
    return pick(this.$store.state.commutationOfDeathBenefits, ...inputs);
  }
};

export default { methods }
