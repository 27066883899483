import "@babel/polyfill";
import 'core-js/stable'
import 'regenerator-runtime/runtime'
import $ from "jquery"
import Vue from 'vue/dist/vue.esm';
import Vuelidate from 'vuelidate'
import VueScrollTo from 'vue-scrollto';
import AsyncComputed from 'vue-async-computed';
import TurbolinksAdapter from 'vue-turbolinks';
import VueClipboard from 'vue-clipboard2';

import CalcHeader from './calculators/templates/calc-header';
import SavedReports from './calculators/saved-reports';
// import components from './calculators/index'
// import formDataParser from './calculators/form-data-parser'
// import store from './calculators/store'

import { components, formDataParser, store } from './calculators/index';

Vue.use(VueScrollTo);
Vue.use(Vuelidate)
Vue.use(AsyncComputed);
Vue.use(TurbolinksAdapter);
Vue.use(VueClipboard);

document.addEventListener('turbo:load', () => {
  const element = document.getElementById('calculator');

  if (element !== null) {
    const { component, form: rawForm } = element.dataset;
    const form = formDataParser(rawForm);
    const results = Object.keys(form).length > 0;

    new Vue({
      el: element,
      store,
      data: {
        form: Object.assign(form, { results })
      },
      components: {
        CalcHeader,
        [component]: components[component]
      }
    });
  }
});

document.addEventListener('turbo:load', () => {
  const element = document.getElementById('saved-calculators');

  if (element !== null) {
    new Vue({
      el: element,
      components: {
        SavedReports
      }
    });
  }
});


$(document).off('click', "p.reveal");
$(document).on('click', "p.reveal", function () {
  if ($.trim($(this).text()) === 'Hide ▲') {
    $(this).text('Show ▼');
  } else {
    $(this).text('Hide ▲');
  }

  return false;
});
