<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="commutation-ptd-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />
        <calc-field-box>
          <calc-label>
            Calculate:
            <template slot="tooltip">
              Select the type of commutation you wish to perform:
              <ul>
                <li>
                  Choose <b>Commutation of All Remaining PTD</b> to calculate the lump-sum payment received in exchange
                  for forgoing all remaining PTD payments.
                </li>
                <li>
                  Choose <b>Commutation of PTD "Off the Side"</b> to determine the reduction in recurring PTD payments
                  required to receive a specified lump-sum payment.
                </li>
                <li>
                  Choose <b>Attorney Fees</b> - to determine the dollar value of attorney fees owed the and the
                  associated reduction of PTD payments.
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input type="radio" v-model="calculationType" id="all-remaining" value="all-remaining">
              <label for="all-remaining" slot="label">
                Commutation of All Remaining PTD
              </label>
            </pretty-radio>
            <pretty-radio>
              <input type="radio" v-model="calculationType" id="portion-remaining" value="portion-remaining">
              <label for="portion-remaining" slot="label">
                Commutation of PTD "Off the Side"
              </label>
            </pretty-radio>
            <pretty-radio>
              <input type="radio" v-model="calculationType" id="attorney-fees" value="attorney-fees">
              <label for="attorney-fees" slot="label">
                Attorney Fees
              </label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfBirth.$error">
          <calc-label target="date-of-birth" :error="errorMessage('dateOfBirth')">
            Date of Birth:
            <template slot="tooltip">Enter the Injured Worker's Date of Birth.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfBirth" id="date-of-birth"
                         input-class="shortField" @input="$v.dateOfBirth.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Gender:
            <template slot="tooltip">
              Select the Injured Worker's gender. (This information is needed for the Life Expectancy lookup.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Male" id="male">
              <label for="male" slot="label">Male</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Female" id="female">
              <label for="female" slot="label">Female</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customRatedAge.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': ratedAgeOption === 'adjust' }">
          <calc-label :error="errorMessage('customRatedAge')">
            Adjustment for "Rated Age"
            <template slot="tooltip">
              To calculate the Injured Worker's life expectancy using a "Rated Age," input the number of years the
              person's life expectancy is anticipated to decrease as a result of illness or injury. See
              <soc-section section="14.83"/>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="none" id="none">
              <label for="none" slot="label">None</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="adjust" id="adjust">
              <label for="adjust" slot="label">Adjust</label>
              <div class="reveal-if-active" slot="custom">
                <input v-model.number.lazy="customRatedAge" class="conditional-input" type="text"
                       placeholder="Years Reduced" @change="$v.customRatedAge.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabled-dates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdCommencementDate.$error">
          <calc-label target="pd-commencement-date" :error="errorMessage('pdCommencementDate')">
            PTD Commencement Date:
            <template slot="tooltip">
              Enter the date on which PTD payments began. This is the Permanent and Stationary date (see
              <soc-section section="10.3"/>).
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="pd-commencement-date"
                         input-class="shortField" @input="$v.pdCommencementDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabledDates="{ to: dateOfInjury }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation" :error="errorMessage('dateOfCalculation')">
            Date of Commutation:
            <template slot="tooltip">Enter the date on which you wish to calculate the commutation.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ to: dateOfBirth }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select Custom to input the Average Weekly Earnings for the injured worker. If the AWE you enter is outside
              applicable limits, it will be modified accordingly and the updated value displayed in the Results section.
              (See <soc-section section="8.6"/> for more detail on AWE.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.assumedAnnualIncreases.$error">
          <calc-label target="assumed-annual-increases" :error="errorMessage('assumedAnnualIncreases')">
            Assumed COLA Increases:
            <template slot="tooltip">
              Enter an assumption for the average rate of annual COLA increases to be applied. See
              <soc-section section="10.61"/>.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="assumedAnnualIncreases" type="text" id="assumed-annual-increases"
                   class="shortField" @change="$v.assumedAnnualIncreases.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType === 'portion-remaining'" :error="$v.amountToCommute.$error">
          <calc-label target="amount-to-commute" :error="errorMessage('amountToCommute')">
            Amount to Commute:
            <template slot="tooltip">
              Enter the specific lump sum payment to be received in exchange for commuting a portion of the payments.
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="amountToCommute" type="text" id="amount-to-commute" class="shortField"
                   @change="$v.amountToCommute.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="calculationType === 'attorney-fees'" :error="$v.attorneyFee.$error">
          <calc-label target="attorney-fee" :error="errorMessage('attorneyFee')">
            Attorney Fee:
            <template slot="tooltip">
              Enter the percentage rate of attorney fees to be paid on a contingency basis.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="attorneyFee" type="text" id="attorney-fee" class="shortField"
                   @change="$v.attorneyFee.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Commutation type:</div>
        <div class="resultValue" v-if="calculationType === 'all-remaining'">Commutation of All Remaining PTD</div>
        <div class="resultValue" v-if="calculationType === 'portion-remaining'">Commutation of PTD "Off the Side"</div>
        <div class="resultValue" v-if="calculationType === 'attorney-fees'">Attorney Fees</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">{{ ratedAgeOption === 'none' ? 'Age' : '"Rated Age"'}} on date of commutation:</div>
        <div class="resultValue">{{ ageInYearsAtDoc | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Life expectancy:</div>
        <div class="resultValue">{{ lifeExpectancy | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings:</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.pd | currency }} {{ averageWeeklyEarnings.suffix.pd }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">PTD weekly rate:</div>
        <div class="resultValue">{{ ptdRate | currency }}</div>
      </div>
      <div class="resultContainer" v-if="ptdRate.toFixed(2) !== ptdRateOnDoc.toFixed(2)">
        <div class="resultLabel">PTD weekly rate on DOC:</div>
        <div class="resultValue">{{ ptdRateOnDoc | currency }}</div>
      </div>
      <div class="resultContainer" v-if="isDocAfterPtdStartDate">
        <div class="resultLabel">Weeks from commencement of PTD through DOC:</div>
        <div class="resultValue">{{ weeksFromPtdStartDateThroughDoc | toFixed(2) }}</div>
      </div>
      <div class="resultContainer" v-if="isDocAfterPtdStartDate">
        <div class="resultLabel">PTD paid prior to DOC:</div>
        <div class="resultValue">{{ ptdPaidPriorToDoc | currency }}</div>
      </div>

      <template v-if="calculationType === 'all-remaining'">
        <div class="resultContainer">
          <div class="resultLabel">Commuted value of all PTD (with COLA) due after DOC:</div>
          <div class="resultValue">{{ futureValues['full_benefits']['future_value'] | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due for each day late:</div>
          <div class="resultValue">{{ futureValues['full_benefits']['future_value'] * 0.1 / 365.24 | currency }}</div>
        </div>
      </template>

      <template v-if="calculationType === 'portion-remaining'">
        <div class="resultContainer">
          <div class="resultLabel">Lump sum to be paid on DOC:</div>
          <div class="resultValue">{{ amountToCommute | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Weekly reduction in PTD necessary to produce lump sum:</div>
          <div class="resultValue">{{ weeklyReductioninPTDforLumpSum | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Initial PTD payment after reduction applied:</div>
          <div class="resultValue">{{ (ptdRateOnDoc - weeklyReductioninPTDforLumpSum) | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Additional interest due for each day late:</div>
          <div class="resultValue">{{ amountToCommute * 0.1 / 365.24 | currency }}</div>
        </div>
      </template>

      <template v-if="calculationType === 'attorney-fees'">
        <div class="resultContainer">
          <div class="resultLabel">Commuted value of all PTD (with COLA) due after DOC:</div>
          <div class="resultValue">{{ futureValues['full_benefits']['future_value'] | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Total Basis for Attorney Fee:</div>
          <div class="resultValue">{{ totalBasisForAttorneyFee | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Attorney Fee:</div>
          <div class="resultValue">{{ attorneyFee }}%</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Total Fee Amount:</div>
          <div class="resultValue">{{ totalFeeAmount | currency }}</div>
        </div>
        <h3>Reduction of PTD Rate to produce attorney fee:</h3>
        <div class="resultContainer">
          <div class="resultLabel">Method #1 - Uniform Rate Reduction:</div>
          <div class="resultValue">{{ totalFeeAmount / pvOfLpAsOfAgeAtDoc | currency }}</div>
        </div>
        <calc-field-box class="comment">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Method #1 is the Uniform Reduction Method. The weekly deduction remains constant or uniform for the life
            of the injured worker. The reduction becomes effective on the day after the date of commutation.
          </div>
        </calc-field-box>
        <div class="resultContainer">
          <div class="resultLabel">Method #2 - Uniform Increasing Rate Reduction:</div>
          <div class="resultValue">{{ ptdRateOnDoc * attorneyFee / 100 | currency }}</div>
        </div>
        <calc-field-box class="comment">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Method #2 is the Uniformly Increasing Reduction Method. The weekly reduction increases every year effective
            January 1st by the same fixed percentage equal to the <b>Assumed COLA Increases</b> above. The initial
            reduction becomes effective on the day after the date of the commutation.
          </div>
        </calc-field-box>
      </template>

      <save-button form="commutation-ptd-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import AverageWeeklyEarnings from '../permanent-disability/average-weekly-earnings';
  import {
    differenceInDays,
    getYear,
    format as dateFormat,
    subHours,
    startOfDay,
    addDays,
    isAfter,
    isLeapYear
  } from 'date-fns';
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { props, provide, filters, methods, components } from '../mixins';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, minValue, between, decimal } from 'vuelidate/lib/validators';
  import { greaterThan } from '../validators';
  import { PaymentYear, totalPayments } from '../saww_payments';
  import { roundFloat } from '../../util/float';
  import saveMixin from '../saveable/commutation-ptd';

  const resettableData = () => (
    {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      calculationType: 'all-remaining',
      dateOfBirth: null,
      customRatedAge: null,
      ratedAgeOption: 'none',
      dateOfInjury: null,
      pdCommencementDate: null,
      dateOfCalculation: null,
      wageOption: 'custom',
      customAverageWeeklyEarnings: null,
      interestRate: 3.0,
      assumedAnnualIncreases: 3.0,
      amountToCommute: null,
      attorneyFee: null,
      gender: 'Male',
      results: false,
      saveStatus: null,
      saveMessage: null,
      ttdLimits: null,
      lifeTableLookup: null,
      futureValues: null,
      pvOfLpAsOfAgeAtDoc: null
    }
  );

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    mounted() {
      axios.get('/state_average_weekly_wages').then(({ data }) => {
        this.wages = data.reduce((memo, { year, real_increase }) => {
          memo[year] = parseFloat(real_increase);
          return memo;
        }, {});
      });
    },
    data() {
      return Object.assign({
        wages: {},
      }, resettableData(), this.form);
    },
    asyncComputed: {
      pvOfLpAsOfAgeAtDoc: {
        get() {
          const params = {
            age: this.ageInYearsAtDoc.toFixed(3), gender: this.gender, deferment: 0
          };
          return axios.get('/pv_of_lp_tables/interpolate_present_value', { params }).then(r => roundFloat(r.data, 2));
        },
        shouldUpdate() {
          return !(this.$v.dateOfBirth.$invalid || this.$v.dateOfCalculation.$invalid);
        },
        default: 0
      },
      ttdLimits: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/ttd_limits/lookup', { params })
            .then(({ data: { min, max }}) => ({ min: parseFloat(min) * 1.5, max: parseFloat(max) * 1.5 }));
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { min: 0, max: Infinity }
      },
      lifeTableLookup: {
        get() {
          const params = { age: Math.min(this.ageInYearsAtDoc, 109), gender: this.gender };
          return axios.get('/life_tables/interpolate', { params }).then(({ data: { value }}) => parseFloat(value));
        },
        shouldUpdate() {
          return !['dateOfBirth', 'customRatedAge', 'dateOfCalculation'].some(e => this.$v[e].$invalid);
        },
        default: 0
      },
      futureValues: {
        get() {
          const params = {
            age: Math.min(this.ageInYearsAtDoc, 109),
            gender: this.gender,
            benefit_rate: this.benefitRate,
            interest_rate: this.interestRate / 100.0,
            assumed_annual_increase: this.assumedAnnualIncreases / 100.0,
            escalation_days: this.escalationDays
          };
          return axios.get('/life_tables/future_values', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return ![
            'dateOfBirth', 'dateOfCalculation', 'customAverageWeeklyEarnings', 'interestRate', 'assumedAnnualIncreases'
          ].some(e => this.$v[e].$invalid)
        },
        default: {
          full_benefits: { future_value: 0, sum: 0 }
        }
      }
    },
    computed: {
      maxSawwYear() {
        return Math.max(...Object.keys(this.wages).map(year => parseInt(year, 10)));
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          100, this.wageOption, this.customAverageWeeklyEarnings, this.ttdLimits, {}
        )
      },
      adjustedDOB() {
        const factor = { none: 0, adjust: this.customRatedAge }[this.ratedAgeOption];
        return startOfDay(subHours(this.dateOfBirth, factor * 365.24 * 24));
      },
      daysBetweenDocAndDateOfBirth() {
        return differenceInDays(this.dateOfCalculation, this.adjustedDOB);
      },
      daysBetweenEscalationBeginDateAndDoc() {
        return differenceInDays(this.escalationBeginDate, this.dateOfCalculation);
      },
      ageInYearsAtDoc() {
        return this.daysBetweenDocAndDateOfBirth / 365.24;
      },
      lifeExpectancy() {
        return this.lifeTableLookup;
      },
      docPaymentYear() {
        const year = getYear(this.dateOfCalculation);
        return new PaymentYear(this.wages, year, this.ptdRate, this.pdCommencementDate, this.dateOfCalculation);
      },
      ptdRate() {
        return this.averageWeeklyEarnings.pdRate;
      },
      ptdRateOnDoc() {
        return this.docPaymentYear.rate;
      },
      isDocAfterPtdStartDate() {
        return isAfter(this.dateOfCalculation, this.pdCommencementDate);
      },
      ptdPaidPriorToDoc() {
        return totalPayments(this.wages, this.ptdRate, this.pdCommencementDate, this.dateOfCalculation);
      },
      weeksFromPtdStartDateThroughDoc() {
        return differenceInDays(this.dateOfCalculation, this.pdCommencementDate) / 7;
      },
      benefitRate() {
        return roundFloat(this.ptdRateOnDoc, 2) * 365.24 / 7;
      },
      escalationBeginDate () {
        return new Date(getYear(this.dateOfCalculation) + 1, 0, 1);
      },
      escalationDays() {
        const days = isLeapYear(this.dateOfCalculation) ? 366 : 365;
        return days - this.daysBetweenEscalationBeginDateAndDoc;
      },
      weeklyReductioninPTDforLumpSum() {
        return this.amountToCommute / this.pvOfLpAsOfAgeAtDoc;
      },
      totalBasisForAttorneyFee() {
        return this.futureValues['full_benefits']['future_value'] + this.ptdPaidPriorToDoc;
      },
      totalFeeAmount() {
        return this.totalBasisForAttorneyFee * this.attorneyFee / 100;
      }
    },
    methods: {
      clearForm() {
        Object.assign(this.$data, resettableData());
        this.$v.$reset();
      }
    },
    validations: {
      dateOfBirth: { required, minValue: minValue(new Date(1900, 0, 1)) },
      dateOfInjury: { required, minValue: minValue(new Date(2003, 0, 1)), maxValue: maxValue(methods.methods.endOfYear()) },
      pdCommencementDate: {
        required,
        minValue(value) {
          return minValue(addDays(this.dateOfInjury, 1))(value);
        }
      },
      dateOfCalculation: {
        required,
        minValue(value) { return minValue(this.dateOfInjury)(value) },
        maxValue(value) { return maxValue(new Date(this.maxSawwYear, 11, 31))(value) }
      },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value) : true },
        decimal,
        minValue: minValue(0)
      },
      interestRate: { required, between: between(0, 100) },
      assumedAnnualIncreases: { required },
      amountToCommute: {
        required(value) { return this.calculationType === 'portion-remaining' ? required(value) : true }
      },
      customRatedAge: {
        required(value) { return this.ratedAgeOption === 'adjust' ? required(value) : true },
        greaterThan(value) { return this.ratedAgeOption === 'none' ? true : greaterThan(0)(value) }
      },
      attorneyFee: {
        required(value) { return this.calculationType === 'attorney-fees' ? required(value) : true },
        between: between(0, 100),
        decimal
      }
    },
    components: { DatePicker }
  }
</script>
