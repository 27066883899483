import { roundFloat } from '../util/float';

export default (memo, value, index) => {
  if (value === undefined) {
    memo.push(undefined);
  } else {
    const prev = index === 0 ? 0 : memo[index - 1];
    memo.push(roundFloat(Math.min(prev + (value * (1 - prev)), 1) + 0.00000001, 2));
  }
  return memo;
};
