<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form @submit.prevent>
        <calc-field-box :error="$v.paymentAmount.$error">
          <calc-label target="payment-amount" :error="errorMessage('paymentAmount')">
            Payment Amount:
            <template slot="tooltip">Enter the amount of the recurring payment.</template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="paymentAmount" id="payment-amount" type="text" class="shortField"
                   @change="$v.paymentAmount.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label for="payment-period">
            Payment Period:
            <template slot="tooltip">Select the type or recurring payment period.</template>
          </calc-label>
          <calc-field>
            <select v-model="paymentPeriod" id="payment-period">
              <option value="year">Annually</option>
              <option value="month">Monthly</option>
              <option value="biweek">Biweekly</option>
              <option value="week">Weekly</option>
            </select>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.numberOfPayments.$error">
          <calc-label target="number-of-payments" :error="errorMessage('numberOfPayments')">
            Number of Payments:
            <template slot="tooltip">Enter the total number of payments that will be made.</template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="numberOfPayments" id="number-of-payments" type="number"
                   class="shortField" @change="$v.numberOfPayments.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.annualInterestRate.$error">
          <calc-label target="annual-interest-rate" :error="errorMessage('annualInterestRate')">
            Annual Discount Rate:
            <template slot="tooltip">
              Input the annual interest rate to use for the present value discount calculation.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="annualInterestRate" id="annual-interest-rate" type="text"
                   class="shortField" @change="$v.annualInterestRate.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Gross Sum of All Payments:</div>
        <div class="resultValue">{{ paymentAmount * numberOfPayments | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Present Value:</div>
        <div class="resultValue">{{ presentValue | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference:</div>
        <div class="resultValue">
          {{ paymentAmount * numberOfPayments - presentValue | currency }}
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import { filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, integer, between, minValue, decimal } from 'vuelidate/lib/validators';

  const periodFactors = {
    week: 365.24 / 7,
    biweek: 365.24 / 14,
    month: 12,
    year: 1
  };

  export default {
    mixins: [filters, methods, components, validationMixin],
    data() {
      return {
        paymentAmount: null,
        paymentPeriod: 'week',
        numberOfPayments: null,
        annualInterestRate: null,
        results: false
      }
    },
    computed: {
      presentValue() {
        return this.paymentAmount * (1 - Math.pow(1 + this.interestRatePerPeriod, -this.numberOfPayments)) / this.interestRatePerPeriod;
      },
      periodFactor() {
        return periodFactors[this.paymentPeriod];
      },
      interestRatePerPeriod() {
        return Math.pow((1 + this.annualInterestRate / 100), 1 / this.periodFactor) - 1;
      }
    },
    validations: {
      paymentAmount: { required, decimal },
      numberOfPayments: { required, integer, minValue: minValue(0) },
      annualInterestRate: { required, between: between(0, 100) }
    }
  }
</script>
