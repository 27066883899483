var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { staticClass: "fold", class: { expanded: _vm.group.expanded } },
    [
      _c("td", { attrs: { colspan: "4" } }, [
        _c(
          "div",
          { staticClass: "fold-content" },
          [
            _c("table", [
              _vm._m(0),
              _vm._v(" "),
              _vm._m(1),
              _vm._v(" "),
              _c(
                "tbody",
                _vm._l(_vm.group.calculators, function (calculator, index) {
                  return _c("calculator-row", {
                    key: calculator.id,
                    attrs: { index: index, calculator: calculator },
                    on: { delete: _vm.showDeleteModal },
                  })
                }),
                1
              ),
            ]),
            _vm._v(" "),
            _vm.deleteIndex !== null
              ? _c(
                  "modal",
                  [
                    _c("template", { slot: "body" }, [
                      _c("p", { staticClass: "message" }, [
                        _vm._v(
                          "\n            Are you sure you wish to delete this saved calculator?\n          "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c("template", { slot: "footer" }, [
                      _c(
                        "button",
                        {
                          staticClass: "button clear-button action",
                          on: { click: _vm.deleteCalculator },
                        },
                        [_vm._v("Yes - Delete")]
                      ),
                      _vm._v(" "),
                      _c(
                        "button",
                        {
                          staticClass: "button black-button action",
                          on: { click: _vm.cancelDelete },
                        },
                        [_vm._v("No - Don't Delete")]
                      ),
                    ]),
                  ],
                  2
                )
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticClass: "reportType" }),
      _vm._v(" "),
      _c("col", { staticClass: "drFirst" }),
      _vm._v(" "),
      _c("col", { staticClass: "drLast" }),
      _vm._v(" "),
      _c("col", { staticClass: "drReportDate" }),
      _vm._v(" "),
      _c("col", { staticClass: "actions" }),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Report Type")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Dr. First")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Dr. Last")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Dr. Report Date")]),
        _vm._v(" "),
        _c("th", { attrs: { scope: "col" } }, [_vm._v("Actions")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }