<template>
  <a :href="url" target="_blank">{{ text }}</a>
</template>

<script>
  import inflection from 'inflection';
  import i18n from '../../util/i18n';

  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      claimNumber: {
        type: String,
        default: null
      }
    },
    computed: {
      url() {
        const query = this.claimNumber ? `?claim_number=${encodeURIComponent(this.claimNumber)}` : '';
        return `/calculators/${inflection.dasherize(this.name)}${query}`;
      },
      text() {
        return i18n.t(`calculators.${inflection.camelize(this.name, true)}`);
      }
    }
  }
</script>
