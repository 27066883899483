var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "commutation-lp-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Calculate Commutation of:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Select the type of commutation you wish to perform:\n            "
                    ),
                    _c("ul", [
                      _c("li", [
                        _vm._v("\n                Choose "),
                        _c("b", [_vm._v("All Remaining Life Pension")]),
                        _vm._v(
                          " to calculate the lump-sum payment received in exchange for\n                forgoing all remaining Life Pension payments.\n              "
                        ),
                      ]),
                      _vm._v(" "),
                      _c("li", [
                        _vm._v("\n                Choose "),
                        _c("b", [_vm._v('Life Pension "Off the Side"')]),
                        _vm._v(
                          " to determine the reduction in recurring life pension\n                payments required to receive a specified lump-sum payment.\n              "
                        ),
                      ]),
                    ]),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calculationType,
                          expression: "calculationType",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "all-remaining",
                        value: "all-remaining",
                      },
                      domProps: {
                        checked: _vm._q(_vm.calculationType, "all-remaining"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calculationType = "all-remaining"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "all-remaining" },
                        slot: "label",
                      },
                      [
                        _vm._v(
                          "\n              All Remaining Life Pension\n            "
                        ),
                      ]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.calculationType,
                          expression: "calculationType",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        id: "portion-remaining",
                        value: "portion-remaining",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.calculationType,
                          "portion-remaining"
                        ),
                      },
                      on: {
                        change: function ($event) {
                          _vm.calculationType = "portion-remaining"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "portion-remaining" },
                        slot: "label",
                      },
                      [
                        _vm._v(
                          '\n              Life Pension "Off the Side"\n            '
                        ),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfBirth.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-birth",
                    error: _vm.errorMessage("dateOfBirth"),
                  },
                },
                [
                  _vm._v("\n          Date of Birth:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the Injured Worker's Date of Birth."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-birth",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfBirth.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfBirth,
                      callback: function ($$v) {
                        _vm.dateOfBirth = $$v
                      },
                      expression: "dateOfBirth",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                [
                  _vm._v("\n          Gender:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Enter the Injured Worker's gender. This is used in conjunction with Life Expectancy data to determine the\n            appropriate calculations for the commutation.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Male", id: "male" },
                      domProps: { checked: _vm._q(_vm.gender, "Male") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Male"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "male" }, slot: "label" },
                      [_vm._v("Male")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.gender,
                          expression: "gender",
                        },
                      ],
                      attrs: { type: "radio", value: "Female", id: "female" },
                      domProps: { checked: _vm._q(_vm.gender, "Female") },
                      on: {
                        change: function ($event) {
                          _vm.gender = "Female"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "female" },
                        slot: "label",
                      },
                      [_vm._v("Female")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customRatedAge.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.ratedAgeOption === "adjust",
                },
              },
            },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("customRatedAge") } },
                [
                  _vm._v('\n          Adjustment for "Rated Age"\n          '),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            To calculate the Injured Worker's life expectancy using a \"Rated Age,\" input the number of years the\n            person's life expectancy is anticipated to decrease as a result of illness or injury. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "14.83" } }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "none", id: "none" },
                      domProps: { checked: _vm._q(_vm.ratedAgeOption, "none") },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "none"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      { attrs: { slot: "label", for: "none" }, slot: "label" },
                      [_vm._v("None")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.ratedAgeOption,
                          expression: "ratedAgeOption",
                        },
                      ],
                      attrs: { type: "radio", value: "adjust", id: "adjust" },
                      domProps: {
                        checked: _vm._q(_vm.ratedAgeOption, "adjust"),
                      },
                      on: {
                        change: function ($event) {
                          _vm.ratedAgeOption = "adjust"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "adjust" },
                        slot: "label",
                      },
                      [_vm._v("Adjust")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customRatedAge,
                              expression: "customRatedAge",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Years Reduced" },
                          domProps: { value: _vm.customRatedAge },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customRatedAge = _vm._n($event.target.value)
                              },
                              function ($event) {
                                return _vm.$v.customRatedAge.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      "disabled-dates": {
                        to: new Date(1996, 0, 1),
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdCommencementDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "lp-start-date",
                    error: _vm.errorMessage("pdCommencementDate", {
                      min: "the Date of Injury",
                    }),
                  },
                },
                [
                  _vm._v("\n          PD Commencement Date:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the date on which permanent disability payments began."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "lp-start-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.pdCommencementDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.pdCommencementDate,
                      callback: function ($$v) {
                        _vm.pdCommencementDate = $$v
                      },
                      expression: "pdCommencementDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdRating.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "pd-rating",
                    error: _vm.errorMessage("pdRating"),
                  },
                },
                [
                  _vm._v("\n          PD Rating:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the PD % value. Obtain this from a calculated rating string or combined rating. If you need to\n            calculate the PD Rating, use the "
                      ),
                      _c("calculator-link", { attrs: { name: "pd" } }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.pdRating,
                      expression: "pdRating",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: {
                    type: "number",
                    id: "pd-rating",
                    min: "70",
                    max: "99",
                  },
                  domProps: { value: _vm.pdRating },
                  on: {
                    change: [
                      function ($event) {
                        _vm.pdRating = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.pdRating.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customAverageWeeklyEarnings.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.wageOption === "custom",
                },
              },
            },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    error: _vm.errorMessage("customAverageWeeklyEarnings"),
                  },
                },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("\n            Select "),
                    _c("b", [_vm._v("Custom")]),
                    _vm._v(
                      " to input the Average Weekly Earnings for the injured worker. If the AWE you enter is\n            outside applicable limits, it will be modified accordingly and the updated value displayed in the Results\n            section. Or, select "
                    ),
                    _c("b", [_vm._v("Max")]),
                    _vm._v(
                      " if you know that the AWE is high enough that the applicable statutory\n            limits would apply.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "max", id: "awe-max" },
                      domProps: { checked: _vm._q(_vm.wageOption, "max") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "max"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-max" },
                        slot: "label",
                      },
                      [_vm._v("Max")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "custom",
                        id: "awe-custom",
                      },
                      domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                      on: {
                        change: function ($event) {
                          _vm.wageOption = "custom"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-custom" },
                        slot: "label",
                      },
                      [_vm._v("Custom")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("abbr", [_vm._v("$")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customAverageWeeklyEarnings,
                              expression: "customAverageWeeklyEarnings",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Enter Amount" },
                          domProps: { value: _vm.customAverageWeeklyEarnings },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customAverageWeeklyEarnings = _vm._n(
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$v.customAverageWeeklyEarnings.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfCalculation.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-calculation",
                    error: _vm.errorMessage("dateOfCalculation"),
                  },
                },
                [
                  _vm._v("\n          Date of Commutation (DOC):\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the date of the commutation payment."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-calculation",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfCalculation.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfCalculation,
                      callback: function ($$v) {
                        _vm.dateOfCalculation = $$v
                      },
                      expression: "dateOfCalculation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.calculationType === "portion-remaining"
            ? _c(
                "calc-field-box",
                {
                  attrs: { error: _vm.$v.amountToCommute.$error },
                  on: {
                    change: function ($event) {
                      return _vm.$v.amountToCommute.$touch()
                    },
                  },
                },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "amount-to-commute",
                        error: _vm.errorMessage("amountToCommute"),
                      },
                    },
                    [
                      _vm._v("\n          Amount to Commute:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v(
                          "\n            Enter the specific lump sum payment to be received in exchange for commuting a portion of the payments.\n          "
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("calc-field", [
                    _c("span", [_vm._v("$")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.amountToCommute,
                          expression: "amountToCommute",
                          modifiers: { number: true, lazy: true },
                        },
                      ],
                      staticClass: "shortField",
                      attrs: { type: "text", id: "amount-to-commute" },
                      domProps: { value: _vm.amountToCommute },
                      on: {
                        input: function ($event) {
                          return _vm.$v.amountToCommute.$touch()
                        },
                        change: function ($event) {
                          _vm.amountToCommute = _vm._n($event.target.value)
                        },
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Commutation Type:"),
              ]),
              _vm._v(" "),
              _vm.calculationType === "all-remaining"
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v("All Remaining Life Pension"),
                  ])
                : _c("div", { staticClass: "resultValue" }, [
                    _vm._v('Life Pension "Off the Side"'),
                  ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.ratedAgeOption === "none"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Age on date of commutation:"),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _vm.ratedAgeOption === "adjust"
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v('"Rated Age" on date of commutation:'),
                  ])
                : _vm._e(),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("toFixed")(_vm.ageInYearsAtDoc, 2))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Average Weekly Earnings:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("currency")(_vm.averageWeeklyEarnings.awe.lp)
                    ) +
                    " " +
                    _vm._s(_vm.averageWeeklyEarnings.suffix.lp) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Life Pension start date:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm.americanDateFormatter(_vm.lpStartDate))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Life Pension weekly rate at LP start date:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.lpRate))),
              ]),
            ]),
            _vm._v(" "),
            _vm.dateOfCalculation > _vm.lpStartDate
              ? [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Days from commencement of LP through DOC:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm.daysBetweenLpStartDateAndDoc.toLocaleString()
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Weeks from commencement of LP through DOC:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("toFixed")(
                            _vm.daysBetweenLpStartDateAndDoc / 7,
                            2
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Life Pension weekly rate on DOC:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.lpRateOnDoc))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("LP paid prior to DOC:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.lpPaidPriorToDoc))),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationType === "all-remaining" && _vm.deferment <= 0
              ? _c("div", [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v(
                        "Commuted value of all Life Pension due after DOC:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.commutedValueOfAllLp))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v(
                        "Additional interest due per day for late payment after DOC:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            (_vm.commutedValueOfAllLp * 0.1) / 365
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationType === "all-remaining" && _vm.deferment > 0
              ? _c("div", [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Years from DOC until Life Pension begins:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm._f("toFixed")(_vm.deferment, 3))),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Commuted value of all Life Pension as of DOC:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.commutedValueOfAllLp))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v(
                        "Additional interest due per day for late payment after DOC:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            (_vm.commutedValueOfAllLp * 0.1) / 365
                          )
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationType === "portion-remaining" && _vm.deferment > 0
              ? _c("div", [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Years from DOC until Life Pension begins:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm._f("toFixed")(_vm.deferment, 3))),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.calculationType === "portion-remaining"
              ? _c("div", [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Amount of weekly reduction in Life Pension:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(_vm.weeklyReductionInLifePension)
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v(
                        "Starting Life Pension weekly rate after commutation:"
                      ),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.lpRateAfterCommutation))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Additional interest due for each day late:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")((_vm.amountToCommute * 0.1) / 365)
                        )
                      ),
                    ]),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "commutation-lp-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }