class AverageWeeklyEarnings {
  constructor(pdRating, wageOption, customEarnings, pdEarnings, lpMax) {
    this.pdRating = pdRating;
    this.wageOption = wageOption;
    this.customEarnings = customEarnings;
    this.pdEarnings = pdEarnings;
    this.lpMax = lpMax;
  }

  get pdLabel() {
    return this.pdRating < 100 ? 'PD' : 'PTD'
  }

  get awe() {
    const pdAwe = { max: this.pdEarnings.max, custom: this.customEarnings }[this.wageOption];
    const lpAwe = { max: this.lpMax.value, custom: this.customEarnings }[this.wageOption];

    return {
      pd: Math.max(Math.min(pdAwe, this.pdEarnings.max), this.pdEarnings.min),
      lp: Math.min(lpAwe, this.lpMax.value)
    }
  }

  get suffix() {
    const pd = {
      [this.pdEarnings.min]: `(${this.pdLabel} Statutory Min)`,
      [this.pdEarnings.max]: `(${this.pdLabel} Statutory Max)`,
      [this.customEarnings]: this.limitsApplied ? '(PD Actual Value)' : null
    }[this.awe.pd];

    const lp = {
      [this.lpMax.value]: '(Life Pension Statutory Max)',
      [this.customEarnings]: '(Life Pension Actual Value)'
    }[this.awe.lp];

    return { pd, lp }
  }

  get pdRate() {
    return this.awe.pd * 2 / 3;
  }

  get lpRate() {
    return (this.pdRating - 60) * 0.015 * this.awe.lp;
  }

  get limitsApplied() {
    return ![this.awe.pd, this.awe.lp].every(e => e === this.customEarnings);
  }
}

export default AverageWeeklyEarnings;
