<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="pv-of-ptd-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />
        <calc-field-box :error="$v.dateOfBirth.$error">
          <calc-label target="date-of-birth" :error="errorMessage('dateOfBirth')">
            Date of Birth:
            <template slot="tooltip">Enter the Injured Worker's Date of Birth.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfBirth" id="date-of-birth"
                         input-class="shortField" @input="$v.dateOfBirth.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Gender:
            <template slot="tooltip">
              Select the Injured Worker's gender. (This information is needed for the Life Expectancy lookup.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Male" id="male">
              <label for="male" slot="label">Male</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Female" id="female">
              <label for="female" slot="label">Female</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customRatedAge.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': ratedAgeOption === 'adjust' }">
          <calc-label :error="errorMessage('customRatedAge')">
            Adjustment for "Rated Age"
            <template slot="tooltip">
              To calculate the Injured Worker's life expectancy using a "Rated Age," input the number of years the
              person's life expectancy is anticipated to decrease as a result of illness or injury. See
              <soc-section section="14.83"/>
              .
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="none" id="none">
              <label for="none" slot="label">None</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="adjust" id="adjust">
              <label for="adjust" slot="label">Adjust</label>
              <div class="reveal-if-active" slot="custom">
                <input v-model.number.lazy="customRatedAge" class="conditional-input" type="text"
                       placeholder="Years Reduced" @change="$v.customRatedAge.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabled-dates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdCommencementDate.$error">
          <calc-label target="pd-commencement-date" :error="errorMessage('pdCommencementDate')">
            PTD Commencement Date:
            <template slot="tooltip">
              Enter the date on which PTD payments began. This is the Permanent and Stationary date (see
              <soc-section section="10.3"/>).
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="pd-commencement-date"
                         input-class="shortField" @input="$v.pdCommencementDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabledDates="{ to: dateOfInjury }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation" :error="errorMessage('dateOfCalculation')">
            Date of Calculation:
            <template slot="tooltip">Enter the date as of which you wish to calculate the present value.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ to: dateOfBirth }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select Custom to input the Average Weekly Earnings for the injured worker. If the AWE you enter is outside
              applicable limits, it will be modified accordingly and the updated value displayed in the Results section.
              (See <soc-section section="8.6"/> for more detail on AWE.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.interestRate.$error">
          <calc-label target="interest-rate" :error="errorMessage('interestRate')">
            Annual Discount Rate:
            <template slot="tooltip">Enter the interest rate for present value discounting.</template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="interestRate" type="text" id="interest-rate" class="shortField"
                   @change="$v.interestRate.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.assumedAnnualIncreases.$error">
          <calc-label target="assumed-annual-increases" :error="errorMessage('assumedAnnualIncreases')">
            Assumed COLA Increases:
            <template slot="tooltip">
              Enter an assumption for the average rate of annual COLA increases to be applied. See
              <soc-section section="10.61"/>.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="assumedAnnualIncreases" type="text" id="assumed-annual-increases"
                   class="shortField" @change="$v.assumedAnnualIncreases.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Calculation Mode:
            <template slot="tooltip">
              Choose the method for calculating the present value of PTD payments:
              <ul>
                <li>
                  <b>Reduce PTD for Mortality</b> - reduces the expected payout in each future year to account for the
                  portion of the Injured Worker's cohort that will no longer be alive in that year. This is the
                  actuarily accurate approach and results in a lower calculated present value.
                </li>
                <li>
                  <b>Full PTD through Life Expectancy</b> - assumes 100% of all PTD payments are received by the
                  Injured Worker in the future up to the date of the life expectancy cut-off. This results in a higher
                  calculated present value.
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="reducePtd" type="radio" value="yes" id="reduce-ptd">
              <label for="reduce-ptd" slot="label">Reduce PTD for Mortality</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="reducePtd" type="radio" value="no" id="no-reduce-ptd">
              <label for="no-reduce-ptd" slot="label">Full PTD through Life Expectancy</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel" v-if="ratedAgeOption === 'none'">Age on date of calculation:</div>
        <div class="resultLabel" v-if="ratedAgeOption === 'adjust'">"Rated Age" on date of calculation:</div>
        <div class="resultValue">{{ ageInYearsAtDoc | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Life Expectancy:</div>
        <div class="resultValue">{{ lifeExpectancy | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings:</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.pd | currency }} {{ averageWeeklyEarnings.suffix.pd }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="ptdRate.toFixed(2) !== ptdRateOnDoc.toFixed(2)">
          PTD weekly rate at start date:
        </div>
        <div class="resultLabel" v-else>PTD weekly rate:</div>
        <div class="resultValue">{{ ptdRate | currency }}</div>
      </div>
      <div class="resultContainer" v-if="ptdRate.toFixed(2) !== ptdRateOnDoc.toFixed(2)">
        <div class="resultLabel">PTD weekly rate on DOC:</div>
        <div class="resultValue">{{ ptdRateOnDoc | currency }}</div>
      </div>
      <div class="resultContainer" v-if="isDocAfterPtdStartDate">
        <div class="resultLabel">Weeks from commencement of PTD through DOC:</div>
        <div class="resultValue">{{ weeksFromPtdStartDateThroughDoc | toFixed(2) }}</div>
      </div>
      <div class="resultContainer" v-if="isDocAfterPtdStartDate">
        <div class="resultLabel">PTD paid prior to DOC:</div>
        <div class="resultValue">{{ ptdPaidPriorToDoc | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Gross Value of Remaining PTD Payments with COLA applied:</div>
        <div class="resultValue">{{ grossValueOfPtdPayments | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Present Value of Remaining PTD Payments with COLA applied:</div>
        <div class="resultValue">{{ presentValueOfPtdPayments | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference between Gross and Present Values:</div>
        <div class="resultValue">{{ differenceBetweenGrossAndPresent | currency }}</div>
      </div>

      <save-button form="pv-of-ptd-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import AverageWeeklyEarnings from './permanent-disability/average-weekly-earnings';
  import {
    differenceInDays,
    getYear,
    addDays,
    subHours,
    startOfDay,
    isAfter,
    isLeapYear,
    format as dateFormat
  } from 'date-fns';
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { props, provide, filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, minValue, between, decimal } from 'vuelidate/lib/validators';
  import { greaterThan } from './validators';
  import { totalPayments, PaymentYear } from './saww_payments';
  import { roundFloat } from '../util/float';
  import saveMixin from './saveable/pv-of-ptd';

  const resettableData = () => (
    {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      dateOfBirth: null,
      dateOfInjury: null,
      ratedAgeOption: 'none',
      customRatedAge: null,
      pdCommencementDate: null,
      dateOfCalculation: null,
      wageOption: 'custom',
      customAverageWeeklyEarnings: null,
      interestRate: 3.0,
      assumedAnnualIncreases: 3.0,
      reducePtd: 'yes',
      gender: 'Male',
      results: false,
      lifeTableLookup: null,
      futureValues: null,
      saveStatus: null,
      saveMessage: null
    }
  );

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    mounted() {
      axios.get('/state_average_weekly_wages').then(({ data }) => {
        this.wages = data.reduce((memo, { year, real_increase }) => {
          memo[year] = parseFloat(real_increase);
          return memo;
        }, {});
      });
    },
    data() {
      return Object.assign({
        wages: {},
      }, resettableData(), this.form);
    },
    asyncComputed: {
      ttdLimits: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/ttd_limits/lookup', { params })
            .then(({ data: { min, max }}) => ({ min: parseFloat(min) * 1.5, max: parseFloat(max) * 1.5 }));
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { min: 0, max: Infinity }
      },
      lifeTableLookup: {
        get() {
          const params = { age: Math.min(this.ageInYearsAtDoc, 109), gender: this.gender };
          return axios.get('/life_tables/interpolate', { params }).then(({ data: { value }}) => parseFloat(value));
        },
        shouldUpdate() {
          return !['dateOfBirth', 'customRatedAge', 'dateOfCalculation'].some(e => this.$v[e].$invalid);
        },
        default: { value: 0 }
      },
      futureValues: {
        get() {
          const params = {
            age: Math.min(this.ageInYearsAtDoc, 109),
            gender: this.gender,
            benefit_rate: this.benefitRate,
            interest_rate: this.interestRate / 100.0,
            assumed_annual_increase: this.assumedAnnualIncreases / 100.0,
            escalation_days: this.escalationDays
          };
          return axios.get('/life_tables/blended_future_values', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return !['dateOfBirth', 'dateOfCalculation', 'interestRate', 'assumedAnnualIncreases']
            .some(e => this.$v[e].$invalid)
        },
        default: {
          remaining: { future_value: 0, sum: 0 },
          escalation: { future_value: 0, sum: 0 },
          full_benefits: { future_value: 0, sum: 0 },
        }
      }
    },
    computed: {
      isDocAfterPtdStartDate() {
        return isAfter(this.dateOfCalculation, this.pdCommencementDate);
      },
      maxSawwYear() {
        return Math.max(...Object.keys(this.wages).map(year => parseInt(year, 10)));
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          100, this.wageOption, this.customAverageWeeklyEarnings, this.ttdLimits, {}
        )
      },
      adjustedDOB() {
        const factor = { none: 0, adjust: this.customRatedAge }[this.ratedAgeOption];
        return startOfDay(subHours(this.dateOfBirth, factor * 365.24 * 24));
      },
      daysBetweenDocAndDateOfBirth() {
        return differenceInDays(this.dateOfCalculation, this.adjustedDOB);
      },
      daysBetweenEscalationBeginDateAndDoc() {
        return differenceInDays(this.escalationBeginDate, this.dateOfCalculation);
      },
      ageInYearsAtDoc() {
        return this.daysBetweenDocAndDateOfBirth / 365.24;
      },
      lifeExpectancy() {
        return this.lifeTableLookup;
      },
      docPaymentYear() {
        const year = getYear(this.dateOfCalculation);
        return new PaymentYear(this.wages, year, this.ptdRate, this.pdCommencementDate, this.dateOfCalculation);
      },
      ptdRate() {
        return this.averageWeeklyEarnings.pdRate;
      },
      ptdRateOnDoc() {
        return this.docPaymentYear.rate;
      },
      ptdPaidPriorToDoc() {
        return totalPayments(this.wages, this.ptdRate, this.pdCommencementDate, this.dateOfCalculation);
      },
      weeksFromPtdStartDateThroughDoc() {
        return differenceInDays(this.dateOfCalculation, this.pdCommencementDate) / 7;
      },
      benefitRate() {
        return roundFloat(this.ptdRateOnDoc, 2) * 365.24 / 7;
      },
      escalationBeginDate () {
        return new Date(getYear(this.dateOfCalculation) + 1, 0, 1);
      },
      escalationDays() {
        const days = isLeapYear(this.dateOfCalculation) ? 366 : 365;
        return days - this.daysBetweenEscalationBeginDateAndDoc;
      },
      grossValueOfPtdPayments() {
        const key = this.reducePtd === 'yes' ? 'escalation' : 'full_benefits';
        return this.futureValues[key]['sum'];
      },
      presentValueOfPtdPayments() {
        const key = this.reducePtd === 'yes' ? 'escalation' : 'full_benefits';
        return this.futureValues[key]['future_value'];
      },
      differenceBetweenGrossAndPresent() {
        return this.grossValueOfPtdPayments - this.presentValueOfPtdPayments;
      }
    },
    methods: {
      clearForm() {
        Object.assign(this.$data, resettableData());
        this.$v.$reset();
      }
    },
    validations: {
      dateOfBirth: { required, minValue: minValue(new Date(1900, 0, 1)) },
      dateOfInjury: { required, minValue: minValue(new Date(2003, 0, 1)), maxValue: maxValue(methods.methods.endOfYear()) },
      pdCommencementDate: {
        required,
        minValue(value) {
          return minValue(addDays(this.dateOfInjury, 1))(value);
        }
      },
      customRatedAge: {
        required(value) { return this.ratedAgeOption === 'none' ? true : required(value) },
        greaterThan(value) { return this.ratedAgeOption === 'none' ? true : greaterThan(0)(value) }
      },
      dateOfCalculation: {
        required,
        minValue(value) { return minValue(this.dateOfInjury)(value) },
        maxValue(value) { return maxValue(new Date(this.maxSawwYear, 11, 31))(value) }
      },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value) : true },
        decimal,
        minValue: minValue(0)
      },
      interestRate: { required, between: between(0, 100) },
      assumedAnnualIncreases: { required }
    },
    components: { DatePicker }
  }
</script>
