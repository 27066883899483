import factory from './factory';

export const inputs = [
  'acceptPainTotal', 'acceptRatingSchedule', 'customAverageWeeklyEarnings', 'dateOfBirth', 'dateOfInjury', 'doctor',
  'impairments', 'jobOffered', 'occupationGroup', 'pdCommencementDate', 'reportDate', 'rtwDate', 'wageOption'
];

const outputs = ['pdRating'];

export default factory(...inputs, ...outputs);
