var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.billType.$error } },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("billType") } },
                [
                  _vm._v("\n          Bill Type:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Select to indicate whether the bill being paid was for services provided as\n            Medical Treatment ("
                      ),
                      _c("soc-section", { attrs: { section: "13.3" } }),
                      _vm._v(") or Medical-Legal ("),
                      _c("soc-section", { attrs: { section: "14.24" } }),
                      _vm._v(" and "),
                      _c("soc-section", { attrs: { section: "14.65" } }),
                      _vm._v(").\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.billType,
                          expression: "billType",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "medical-treatment",
                        id: "medical-treatment",
                      },
                      domProps: {
                        checked: _vm._q(
                          _vm.billType,
                          _vm._n("medical-treatment")
                        ),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.billType = _vm._n("medical-treatment")
                          },
                          _vm.billTypeChanged,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "medical-treatment" },
                        slot: "label",
                      },
                      [_vm._v("Medical Treatment")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number",
                          value: _vm.billType,
                          expression: "billType",
                          modifiers: { number: true },
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "medical-legal",
                        id: "medical-legal",
                      },
                      domProps: {
                        checked: _vm._q(_vm.billType, _vm._n("medical-legal")),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.billType = _vm._n("medical-legal")
                          },
                          _vm.billTypeChanged,
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "medical-legal" },
                        slot: "label",
                      },
                      [_vm._v("Medical-Legal")]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          !_vm.billType
            ? _c("div", { staticClass: "calcFieldBox" }, [_vm._m(0)])
            : _vm._e(),
          _vm._v(" "),
          _vm.billType === "medical-treatment"
            ? _c(
                "calc-field-box",
                [
                  _c(
                    "calc-label",
                    [
                      _vm._v("\n          Employer Type:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v(
                          "\n            Select to indicate whether the employer is a "
                        ),
                        _c("b", [_vm._v("Nonpublic Entity")]),
                        _vm._v(" (most employers) or a "),
                        _c("b", [_vm._v("Public Entity")]),
                        _vm._v(".\n          "),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.employerType,
                              expression: "employerType",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "nonpublic-entity",
                            id: "nonpublic-entity",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.employerType,
                              _vm._n("nonpublic-entity")
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.employerType = _vm._n("nonpublic-entity")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "nonpublic-entity" },
                            slot: "label",
                          },
                          [_vm._v("Nonpublic Entity")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.employerType,
                              expression: "employerType",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "public-entity",
                            id: "public-entity",
                          },
                          domProps: {
                            checked: _vm._q(
                              _vm.employerType,
                              _vm._n("public-entity")
                            ),
                          },
                          on: {
                            change: function ($event) {
                              _vm.employerType = _vm._n("public-entity")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "public-entity" },
                            slot: "label",
                          },
                          [_vm._v("Public Entity")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.billType === "medical-treatment"
            ? _c(
                "calc-field-box",
                [
                  _c(
                    "calc-label",
                    [
                      _vm._v("\n          Date Service Provided:\n          "),
                      _c("template", { slot: "tooltip" }, [
                        _vm._v(
                          "\n            Select to indicate whether the billed-for medical service was before or on/after 1/1/2004.\n          "
                        ),
                      ]),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.penaltyRate,
                              expression: "penaltyRate",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "0.1",
                            id: "before-2004",
                          },
                          domProps: {
                            checked: _vm._q(_vm.penaltyRate, _vm._n("0.1")),
                          },
                          on: {
                            change: function ($event) {
                              _vm.penaltyRate = _vm._n("0.1")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "before-2004" },
                            slot: "label",
                          },
                          [_vm._v("Before 2004")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number",
                              value: _vm.penaltyRate,
                              expression: "penaltyRate",
                              modifiers: { number: true },
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "0.15",
                            id: "after-2004",
                          },
                          domProps: {
                            checked: _vm._q(_vm.penaltyRate, _vm._n("0.15")),
                          },
                          on: {
                            change: function ($event) {
                              _vm.penaltyRate = _vm._n("0.15")
                            },
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "after-2004" },
                            slot: "label",
                          },
                          [_vm._v("On/After 2004")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.billReceived.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "bill-received",
                    error: _vm.errorMessage("billReceived"),
                  },
                },
                [
                  _vm._v("\n          Bill Received:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the date the bill was received."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "bill-received",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      disabledDates: { from: new Date() },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.billReceived.$touch()
                      },
                    },
                    model: {
                      value: _vm.billReceived,
                      callback: function ($$v) {
                        _vm.billReceived = $$v
                      },
                      expression: "billReceived",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.paymentIssued.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "payment-issued",
                    error: _vm.errorMessage("paymentIssued"),
                  },
                },
                [
                  _vm._v("\n          Payment Issued:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the date payment will be sent."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "payment-issued",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.paymentIssued.$touch()
                      },
                    },
                    model: {
                      value: _vm.paymentIssued,
                      callback: function ($$v) {
                        _vm.paymentIssued = $$v
                      },
                      expression: "paymentIssued",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.billAmount.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "bill-amount",
                    error: _vm.errorMessage("billAmount"),
                  },
                },
                [
                  _vm._v("\n          Bill Amount:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the amount of the bill."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("span", [_vm._v("$")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.billAmount,
                      expression: "billAmount",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "bill-amount" },
                  domProps: { value: _vm.billAmount },
                  on: {
                    change: [
                      function ($event) {
                        _vm.billAmount = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.billAmount.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c("section", { attrs: { id: "calculator-results" } }, [
          _c("h2", [_vm._v("Results")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [_vm._v("Bill type:")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.billTypeLabel)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v(
                "Days past due (" +
                  _vm._s(_vm.gracePeriod) +
                  " days after received):"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.daysPastDue)),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [_vm._v("Total days:")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm.totalDays)),
            ]),
          ]),
          _vm._v(" "),
          _c("h3", [_vm._v("Payment Owed")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [_vm._v("Bill:")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.billAmount))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v(
                _vm._s(_vm._f("toPercent")(_vm.penaltyRate)) + "% Penalty:"
              ),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(
                _vm._s(_vm._f("currency")(_vm.penalty)) +
                  " " +
                  _vm._s(_vm.applicableLabel)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v(_vm._s(_vm.interestLabel)),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(
                _vm._s(_vm._f("currency")(_vm.interest)) +
                  " " +
                  _vm._s(_vm.applicableLabel)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [_vm._v("Total Due:")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.total))),
            ]),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "formFieldNotation" }, [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v("\n          Make a selection to begin your analysis.\n        "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }