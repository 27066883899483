<template>
  <th class="sortable-header" scope="col" @click="$emit('sort', { [name]: direction })">
    <slot></slot>
    <i :class="classes"></i>
  </th>
</template>

<script>
  export default {
    props: {
      name: {
        type: String,
        required: true
      },
      current: {
        type: Object,
        required: true
      }
    },
    computed: {
      classes() {
        if (this.current.hasOwnProperty(this.name)) {
          const dir = this.current[this.name];
          return dir === 'asc' ? ['fa', 'fa-sort-up'] : ['fa', 'fa-sort-down'];
        } else {
          return ['far', 'fa-sort'];
        }
      },
      direction() {
        if (this.current.hasOwnProperty(this.name)) {
          const dir = this.current[this.name];
          return dir === 'asc' ? 'desc' : 'asc';
        } else {
          return 'desc';
        }
      }
    }
  }
</script>
