<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form @submit.prevent>
        <calc-field-box :error="$v.billType.$error">
          <calc-label :error="errorMessage('billType')">
            Bill Type:
            <template slot="tooltip">
              Select to indicate whether the bill being paid was for services provided as
              Medical Treatment (<soc-section section="13.3"/>) or Medical-Legal (<soc-section section="14.24"/> and <soc-section section="14.65"/>).
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="billType" type="radio" value="medical-treatment" id="medical-treatment"
                     @change="billTypeChanged">
              <label for="medical-treatment" slot="label">Medical Treatment</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="billType" type="radio" value="medical-legal" id="medical-legal"
                     @change="billTypeChanged">
              <label for="medical-legal" slot="label">Medical-Legal</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <div class="calcFieldBox" v-if="!billType">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Make a selection to begin your analysis.
          </div>
        </div>

        <calc-field-box v-if="billType === 'medical-treatment'">
          <calc-label>
            Employer Type:
            <template slot="tooltip">
              Select to indicate whether the employer is a <b>Nonpublic Entity</b> (most employers) or a <b>Public Entity</b>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="employerType" type="radio" value="nonpublic-entity" id="nonpublic-entity">
              <label for="nonpublic-entity" slot="label">Nonpublic Entity</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="employerType" type="radio" value="public-entity" id="public-entity">
              <label for="public-entity" slot="label">Public Entity</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="billType === 'medical-treatment'">
          <calc-label>
            Date Service Provided:
            <template slot="tooltip">
              Select to indicate whether the billed-for medical service was before or on/after 1/1/2004.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="penaltyRate" type="radio" value="0.1" id="before-2004">
              <label for="before-2004" slot="label">Before 2004</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="penaltyRate" type="radio" value="0.15" id="after-2004">
              <label for="after-2004" slot="label">On/After 2004</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.billReceived.$error">
          <calc-label target="bill-received" :error="errorMessage('billReceived')">
            Bill Received:
            <template slot="tooltip">Enter the date the bill was received.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="billReceived" id="bill-received"
                         input-class="shortField" @input="$v.billReceived.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ from: new Date() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.paymentIssued.$error">
          <calc-label target="payment-issued" :error="errorMessage('paymentIssued')">
            Payment Issued:
            <template slot="tooltip">Enter the date payment will be sent.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="paymentIssued" id="payment-issued"
                         input-class="shortField" @input="$v.paymentIssued.$touch()" :typeable="true"
                         placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.billAmount.$error">
          <calc-label target="bill-amount" :error="errorMessage('billAmount')">
            Bill Amount:
            <template slot="tooltip">Enter the amount of the bill.</template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="billAmount" type="text" id="bill-amount" class="shortField"
                   @change="$v.billAmount.$touch()">
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>

      <div class="resultContainer">
        <div class="resultLabel">Bill type:</div>
        <div class="resultValue">{{ billTypeLabel }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Days past due ({{ gracePeriod }} days after received):</div>
        <div class="resultValue">{{ daysPastDue }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Total days:</div>
        <div class="resultValue">{{ totalDays }}</div>
      </div>

      <h3>Payment Owed</h3>
      <div class="resultContainer">
        <div class="resultLabel">Bill:</div>
        <div class="resultValue">{{ billAmount | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">{{ penaltyRate | toPercent }}% Penalty:</div>
        <div class="resultValue">{{ penalty | currency }} {{ applicableLabel }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">{{ interestLabel }}</div>
        <div class="resultValue">{{ interest | currency }} {{ applicableLabel }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Total Due:</div>
        <div class="resultValue">{{ total | currency }}</div>
      </div>
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import { filters, methods, components } from './mixins';
  import { differenceInDays, isBefore } from 'date-fns';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, minValue } from 'vuelidate/lib/validators';

  export default {
    mixins: [filters, methods, components, validationMixin],
    data() {
      return {
        billType: null,
        billReceived: null,
        paymentIssued: null,
        billAmount: null,
        penaltyRate: 0.15,
        employerType: 'nonpublic-entity',
        results: false
      }
    },
    computed: {
      totalDays() {
        return differenceInDays(this.paymentIssued, this.billReceived);
      },
      billTypeLabel() {
        return { 'medical-treatment': 'Medical Treatment', 'medical-legal': 'Medical-Legal' }[this.billType];
      },
      gracePeriod() {
        return { 'medical-treatment': 45, 'medical-legal': 60 }[this.billType];
      },
      daysPastDue() {
        return this.totalDays - this.gracePeriod;
      },
      penalty() {
        const penaltyRate = this.daysPastDue <= 0 ? 0 : this.penaltyRate;
        return penaltyRate * this.billAmount;
      },
      interestRate() {
        if(this.billType === 'medical-treatment' && this.employerType === 'nonpublic-entity'){
          return 0.1;
        } else {
          return 0.07;
        }
      },
      interest() {
        if(this.daysPastDue <= 0) {
          return 0;
        }
        return Math.max(this.interestRate * this.billAmount * this.totalDays / 365.24, 0);
      },
      total() {
        return this.billAmount + this.penalty + this.interest;
      },
      interestLabel() {
        let interestRate = this.$options.filters.toFixed(this.$options.filters.toPercent(this.interestRate, 0))
        if(this.billType=== 'medical-legal') {
          return `Interest (${ interestRate }% per annum):`
        } else {
          let entitiesPart = this.employerType === 'public-entity' ? ' public entities' : 'nonpublic entities'
          return `Interest (${ interestRate }% per annum for ${entitiesPart}):`
        }
      },
      applicableLabel() {
        return this.daysPastDue <= 0 ? '(Not Applicable)' : ''
      }
    },
    methods: {
      billTypeChanged(event) {
        this.$v.billType.$touch();
        this.penaltyRate = { 'medical-treatment': 0.15, 'medical-legal': 0.1 }[event.target.value];
      }
    },
    validations: {
      billType: { required },
      billReceived: { required, maxValue(value) { return maxValue(new Date())(value) } },
      paymentIssued: { required, minValue(value) { return minValue(this.billReceived)(value) } },
      billAmount: { required, minValue: minValue(0) },
      penaltyRate: { required }
    },
    components: { DatePicker }
  }
</script>
