var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "commutation-db-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.benefitStartDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "benefit-start-date",
                    error: _vm.errorMessage("benefitStartDate"),
                  },
                },
                [
                  _vm._v("\n          Date of Death:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the date the Injured Worker died. This is used as the benefit start date. See\n            "
                      ),
                      _c("soc-section", { attrs: { section: "12.21" } }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "benefit-start-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      "open-date": _vm.dateOfInjury,
                      "disabled-dates": {
                        to: _vm.dateOfInjury,
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.benefitStartDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.benefitStartDate,
                      callback: function ($$v) {
                        _vm.benefitStartDate = $$v
                      },
                      expression: "benefitStartDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfCalculation.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-calculation",
                    error: _vm.errorMessage("dateOfCalculation"),
                  },
                },
                [
                  _vm._v("\n          Date of Commutation:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Enter the date on which you wish to calculate the commutation.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-calculation",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      "open-date": _vm.benefitStartDate,
                      "disabled-dates": { to: _vm.benefitStartDate },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfCalculation.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfCalculation,
                      callback: function ($$v) {
                        _vm.dateOfCalculation = $$v
                      },
                      expression: "dateOfCalculation",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.averageWeeklyEarnings.$error } },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("averageWeeklyEarnings") } },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Please enter the actual calculated Average Weekly Earnings. This is required because in some cases the\n            maximum statutory value will change over the course of the payout period, so the actual value is needed to\n            determine how the maximum would potentially be applied.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("span", [_vm._v("$")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.averageWeeklyEarnings,
                      expression: "averageWeeklyEarnings",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text" },
                  domProps: { value: _vm.averageWeeklyEarnings },
                  on: {
                    change: [
                      function ($event) {
                        _vm.averageWeeklyEarnings = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.averageWeeklyEarnings.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            [
              _c(
                "calc-label",
                { attrs: { target: "calculation-type" } },
                [
                  _vm._v("\n          Calculation Type:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            Select to indicate whether you will calculate the commutation based on the maximum benefits payable amount\n            or, if there is one or more totally dependent minor children, to enter the ending date for the payment of\n            benefits.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c(
                  "select",
                  {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.calculationType,
                        expression: "calculationType",
                      },
                    ],
                    attrs: { id: "calculation-type" },
                    on: {
                      change: function ($event) {
                        var $$selectedVal = Array.prototype.filter
                          .call($event.target.options, function (o) {
                            return o.selected
                          })
                          .map(function (o) {
                            var val = "_value" in o ? o._value : o.value
                            return val
                          })
                        _vm.calculationType = $event.target.multiple
                          ? $$selectedVal
                          : $$selectedVal[0]
                      },
                    },
                  },
                  [
                    _c("option", { attrs: { value: "maxBenefit" } }, [
                      _vm._v("Max Benefit"),
                    ]),
                    _vm._v(" "),
                    _c("option", { attrs: { value: "endDate" } }, [
                      _vm._v("End Date"),
                    ]),
                  ]
                ),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _vm.calculationType === "maxBenefit"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.benefitAmount.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "max-benefit-amount",
                        error: _vm.errorMessage("benefitAmount"),
                      },
                    },
                    [
                      _vm._v("\n          Max Benefit Amount:\n          "),
                      _c(
                        "template",
                        { slot: "tooltip" },
                        [
                          _vm._v(
                            "\n            Enter the maximum benefits payable in this matter. See "
                          ),
                          _c("soc-section", { attrs: { section: "12.18" } }),
                          _vm._v(" or the\n            "),
                          _c("calculator-link", {
                            attrs: { name: "death_benefits" },
                          }),
                          _vm._v(
                            " calculator if you need assistance determining what value to enter\n            .\n          "
                          ),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c("calc-field", [
                    _c("span", [_vm._v("$")]),
                    _vm._v(" "),
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model.number.lazy",
                          value: _vm.benefitAmount,
                          expression: "benefitAmount",
                          modifiers: { number: true, lazy: true },
                        },
                      ],
                      staticClass: "shortField",
                      attrs: { id: "max-benefit-amount", type: "text" },
                      domProps: { value: _vm.benefitAmount },
                      on: {
                        change: [
                          function ($event) {
                            _vm.benefitAmount = _vm._n($event.target.value)
                          },
                          function ($event) {
                            return _vm.$v.benefitAmount.$touch()
                          },
                        ],
                        blur: function ($event) {
                          return _vm.$forceUpdate()
                        },
                      },
                    }),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.calculationType === "endDate"
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.benefitEndDate.$error } },
                [
                  _c(
                    "calc-label",
                    {
                      attrs: {
                        target: "benefit-end-date",
                        error: _vm.errorMessage("benefitEndDate"),
                      },
                    },
                    [
                      _vm._v("\n          Benefit End Date:\n          "),
                      _c(
                        "template",
                        { slot: "tooltip" },
                        [
                          _vm._v(
                            "\n            If there is one or more totally dependent minor children, enter the date the youngest child will reach the\n            age of majority. For totally incapacitated minor children, use the\n            "
                          ),
                          _c("calculator-link", {
                            attrs: { name: "life_expectancy" },
                          }),
                          _vm._v(
                            " calculator and enter an appropriate date. See\n            "
                          ),
                          _c("soc-section", { attrs: { section: "12.19" } }),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("date-picker", {
                        attrs: {
                          format: _vm.americanDateFormatter,
                          id: "benefit-end-date",
                          "input-class": "shortField",
                          typeable: true,
                          placeholder: "mm/dd/yy",
                        },
                        on: {
                          input: function ($event) {
                            return _vm.$v.benefitEndDate.$touch()
                          },
                        },
                        model: {
                          value: _vm.benefitEndDate,
                          callback: function ($$v) {
                            _vm.benefitEndDate = $$v
                          },
                          expression: "benefitEndDate",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.rateOfIncrease.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "rate-of-increase",
                    error: _vm.errorMessage("rateOfIncrease"),
                  },
                },
                [
                  _vm._v("\n          Assumed Rate of Increase:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "\n            What percentage rate do you wish to assume for the yearly increase of the TTD maximum value? This value is\n            analogous to the COLA increase and like COLA, is based on the annual increase in the State Average Weekly\n            Wage.\n          "
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.rateOfIncrease,
                      expression: "rateOfIncrease",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "rate-of-increase" },
                  domProps: { value: _vm.rateOfIncrease },
                  on: {
                    change: function ($event) {
                      _vm.rateOfIncrease = _vm._n($event.target.value)
                    },
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Benefits paid prior to Date of Commutation:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.benefitsPaidPriorToDOC))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Gross value of outstanding payments owed:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(_vm.grossValueOfOutstandingPaymentsOwed)
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Commuted value of outstanding payments owed:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  _vm._s(
                    _vm._f("currency")(
                      _vm.commutedValueOfOutstandingPaymentsOwed
                    )
                  )
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Difference between gross and commuted value:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("currency")(
                        _vm.grossValueOfOutstandingPaymentsOwed -
                          _vm.commutedValueOfOutstandingPaymentsOwed
                      )
                    ) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Interest due for each day late:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.interestDue))),
              ]),
            ]),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "commutation-db-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          1
        )
      : _vm._e(),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c("div", { staticClass: "calc-detail-container" }, [
          _c("h2", [_vm._v("Cash Flow Summary")]),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "calcTableBox" },
            [_vm._m(0), _vm._v(" "), _c("benefit-table-before-doc")],
            1
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "calcTableBox" },
            [_vm._m(1), _vm._v(" "), _c("benefit-table-after-doc")],
            1
          ),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calcLabel" }, [
      _c("label", [_vm._v("Benefits Paid through DOC:")]),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "calcLabel" }, [
      _c("label", [_vm._v("Benefits to be Paid after DOC:")]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }