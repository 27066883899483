var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.pdRating !== 100
    ? _c("calc-field-box", [
        _c(
          "div",
          { staticClass: "formFieldNotation" },
          [
            _c("i", { staticClass: "far fa-arrow-up" }),
            _vm._v(" "),
            _vm.pdRating < 70
              ? [
                  _vm._v(
                    "\n      To determine the present value of PD, see the\n      "
                  ),
                  _c("calculator-link", {
                    attrs: {
                      name: "pv_of_pd_and_lp",
                      "claim-number": _vm.claimNumber,
                    },
                  }),
                  _vm._v(
                    " calculator. To calculate the commutation\n      of PD payments, see the "
                  ),
                  _c("calculator-link", {
                    attrs: {
                      name: "commutation_pd",
                      "claim-number": _vm.claimNumber,
                    },
                  }),
                  _vm._v(" calculator.\n    "),
                ]
              : [
                  _vm._v(
                    "\n      To determine the present value of Permanent Disability and Life Pension payments owed, see the\n      "
                  ),
                  _c("calculator-link", {
                    attrs: {
                      name: "pv_of_pd_and_lp",
                      "claim-number": _vm.claimNumber,
                    },
                  }),
                  _vm._v(" calculator.\n\n      "),
                  _c(
                    "div",
                    { staticStyle: { "margin-top": "10px" } },
                    [
                      _c("i", { staticClass: "far fa-arrow-up" }),
                      _vm._v(
                        "\n        To calculate the commutation of PD payments, see the\n        "
                      ),
                      _c("calculator-link", {
                        attrs: {
                          name: "commutation_pd",
                          "claim-number": _vm.claimNumber,
                        },
                      }),
                      _vm._v(
                        " calculator. To calculate a commutation of\n        the Life Pension see the "
                      ),
                      _c("calculator-link", {
                        attrs: {
                          name: "commutation_lp",
                          "claim-number": _vm.claimNumber,
                        },
                      }),
                      _vm._v(" calculator.\n      "),
                    ],
                    1
                  ),
                ],
          ],
          2
        ),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }