<template>
  <tr class="view" @click="$set(group, 'expanded', !group.expanded)" :class="{ expanded: group.expanded }">
    <td>{{ group.claim_number }}</td>
    <td>{{ group.worker_first_name }}</td>
    <td>{{ group.worker_last_name }}</td>
    <td>{{ group.updated_at | formatDate }}</td>
  </tr>
</template>

<script>
  import { filters } from '../mixins';

  export default {
    mixins: [filters],
    props: {
      group: {
        type: Object,
        required: true
      }
    }
  }
</script>
