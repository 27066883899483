<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form @submit.prevent>
        <calc-field-box :error="$v.age.$error">
          <calc-label target="age" :error="errorMessage('age')">
            Age:
            <template slot="tooltip">Enter the Injured Worker's Age.</template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="age" id="age" type="text" class="shortField" @change="$v.age.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.gender.$error">
          <calc-label :error="errorMessage('gender')">
            Gender:
            <template slot="tooltip">Enter the Injured Worker's Gender.</template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Male" id="male">
              <label for="male" slot="label">Male</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Female" id="female">
              <label for="female" slot="label">Female</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Expected Years Remaining:</div>
        <div class="resultValue">{{ ex | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Expected Weeks Remaining:</div>
        <div class="resultValue">{{ ex * 365.24 / 7 | commaSeparate(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Expected Days Remaining:</div>
        <div class="resultValue">{{ ex * 365.24 | commaSeparate(0) }}</div>
      </div>
      <p>
        The value shown is a general life expectancy and not reflective of a "rated age", which may be lower due to the
        existence of medical factors. See <soc-section section="14.82"/> and <soc-section section="14.83"/> for in-depth
        discussions regarding life expectancy and its relevance to settlement calculations.
      </p>
    </section>
  </div>
</template>

<script>
  import axios from 'axios';
  import { filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, between } from 'vuelidate/lib/validators';
  import { roundFloat } from '../util/float';

  export default {
    mixins: [filters, methods, components, validationMixin],
    asyncComputed: {
      result: {
        get() {
          const params = { age: this.age, gender: this.gender };
          return axios.get('/life_tables/lookup.json', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return !(this.$v.age.$invalid || this.$v.gender.$invalid);
        },
        default: { ex: 0 }
      }
    },
    computed: {
      ex() {
        return roundFloat(this.result.ex, 2);
      }
    },
    data() {
      return {
        age: null,
        gender: null,
        results: false,
        result: null
      }
    },
    validations: {
      age: { required, between: between(0, 109) },
      gender: { required }
    }
  }
</script>
