<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="money-chart-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm"/>
        <injured-worker v-model="worker" />
        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField"  :typeable="true"
                         placeholder="mm/dd/yy" :highlighted="{ dates: [new Date()] }" @input="initialFetchData()"
                         :disabled-dates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <div class="calcFieldBox">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            For Continuous Trauma claims, enter the ending date of the CT period or the date determined in accordance
            with LC 5412 (See <soc-section section="5.5"/>).
          </div>
        </div>

        <calc-field-box :error="$v.pdRating.$error">
          <calc-label target="pd-rating" :error="errorMessage('pdRating')">
            PD Rating:
            <template slot="tooltip">
              Enter the PD % value. Obtain this from a calculated rating string or combined rating. If you need to
              calculate the PD Rating, use the <calculator-link name="pd" :claim-number="claimNumber"/>.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="pdRating" type="number" id="pd-rating" min="1" max="100" class="shortField"
                   @change="initialFetchData()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select <b>Custom</b> to input the Average Weekly Earnings for the injured worker. If the AWE you enter is
              outside applicable limits, it will be modified accordingly and the updated value displayed in the Results
              section. Or, select <b>Max</b> if you know that the AWE is high enough that the applicable statutory
              limits would apply. Note that different limits apply to Permanent Disability and when applicable, Life
              Pension. If you are unsure whether the actual AWE value exceeds the limits for both, input the actual AWE
              value instead. (See <soc-section section="8.6"/> for more detail on AWE.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="wageOption === 'max'" :container-class="{ comment: true }">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can differ
            . To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.
          </div>
        </calc-field-box>

        <calc-field-box v-if="hasReturnToWorkAdjustment" :error="$v.jobOffered.$error">
          <calc-label :error="errorMessage('jobOffered')">
            Was a Job Offered within 60 Days of P&S Date?:
            <template slot="tooltip">
              Was an offer made to the Injured Worker to return to work by the employer within 60 days after the
              P&S date? (If the employer has fewer than 50 employees, select N/A.) See <soc-section section="11.6"/>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="yes" id="offered-yes" @change="$v.jobOffered.$touch()">
              <label for="offered-yes" slot="label">Yes</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="no" id="offered-no" @change="$v.jobOffered.$touch()">
              <label for="offered-no" slot="label">No</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="na" id="offered-na" @change="$v.jobOffered.$touch()">
              <label for="offered-na" slot="label">N/A</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <template v-if="hasReturnToWorkAdjustment && jobOffered === 'yes'">
          <calc-field-box :error="$v.pdCommencementDate.$error">
            <calc-label target="ps-date" :error="errorMessage('pdCommencementDate', { min: 'the Date of Injury (%{min})' })">
              Permanent and Stationary Date:
              <template slot="tooltip">
                What was the date the Injured Worker became Permanent & Stationary? (If you don't know, leave this
                blank.) See <soc-section section="10.3"/>.
              </template>
            </calc-label>
            <calc-field>
              <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="ps-date" input-class="shortField"
                           :typeable="true" placeholder="mm/dd/yy" @input="$v.pdCommencementDate.$touch()" :open-date="dateOfInjury"
                           :disabled-dates="{ to: dateOfInjury }">
              </date-picker>
            </calc-field>
          </calc-field-box>
          <calc-field-box v-if="pdCommencementDate" :error="$v.rtwDate.$error">
            <calc-label target="rtw-date"
                        :error="errorMessage('rtwDate', { min: 'the Permanent and Stationary Date',
                                                          max: '60 days thereafter (%{max})' })">
              Return to Work:
              <template slot="tooltip">
                What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,
                leave this blank.) See <soc-section section="11.6"/>.
              </template>
            </calc-label>
            <calc-field>
              <date-picker :format="americanDateFormatter" v-model="rtwDate" id="rtw-date" input-class="shortField"
                           :typeable="true" placeholder="mm/dd/yy" @input="$v.rtwDate.$touch()" :open-date="pdCommencementDate"
                           :disabled-dates="{ to: pdCommencementDate, from: addDays(pdCommencementDate, 60) }">
              </date-picker>
            </calc-field>
          </calc-field-box>
        </template>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>

      <div class="resultContainer" v-if="pdRating < 100">
        <div class="resultLabel">Total Weeks of PD:</div>
        <div class="resultValue">{{ totalWeeksOfPd }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings:</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.pd | currency }} {{ averageWeeklyEarnings.suffix.pd }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="pdRating === 100">PTD Weekly Rate:</div>
        <div class="resultLabel" v-else>PD Weekly Rate:</div>
        <div class="resultValue">{{ weeklyPdRate | currency }}</div>
      </div>
      <calc-field-box v-if="pdRating === 100" :container-class="{ comment: true }">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          To determine the present value of PTD payments owed, see the
          <calculator-link name="pv_of_ptd" :claim-number="claimNumber"/> calculator. To calculate a commutation of
          Permanent Total Disability see the <calculator-link name="commutation_ptd" :claim-number="claimNumber"/>
          calculator.
        </div>
      </calc-field-box>

      <template v-if="pdRating < 100">
        <div class="resultContainer">
          <div class="resultLabel">Total PD Payout:</div>
          <div class="resultValue">{{ totalWeeksOfPd * weeklyPdRate | currency }}</div>
        </div>

        <h3 style="margin-bottom: 15px;">Return to Work Adjustments</h3>
        <calc-field-box :container-class="{ comment: true }">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            <rtw-adjustments :job-offered="jobOffered" :date-of-injury="dateOfInjury"/>
          </div>
        </calc-field-box>
      </template>

      <template v-if="hasReturnToWorkAdjustment && rtwModifier">
        <div class="resultContainer">
          <div class="resultLabel">Weeks Paid at Base Rate</div>
          <div class="resultValue">
            {{ rtwWeeksPaidAtBaseRate.weeks }} Weeks, {{ rtwWeeksPaidAtBaseRate.days | toFixed(0) }} Days
          </div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">PD Paid at Base Rate</div>
          <div class="resultValue">{{ rtwPdPaidAtBaseRate | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">RTW Modifier Used</div>
          <div class="resultValue">{{ rtwModifier }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">PD Weekly Rate (after Adjustment)</div>
          <div class="resultValue">{{ rtwWeeklyPdCompensation | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Weeks of PD Remaining (after Adjustment)</div>
          <div class="resultValue">{{ rtwWeeksOfPdRemaining | toFixed(2) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">PD Indemnity Remaining (after Adjustment)</div>
          <div class="resultValue">{{ rtwPdIndemnityRemaining | currency }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Total PD Payout (after Adjustment)</div>
          <div class="resultValue">{{ rtwTotalPdPayout | currency }}</div>
        </div>
      </template>

      <div class="resultContainer" v-if="averageWeeklyEarnings.limitsApplied && isQualifiedForLp">
        <div class="resultLabel">Average Weekly Earnings</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.lp | currency }} {{ averageWeeklyEarnings.suffix.lp }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Life Pension Weekly Rate:</div>
        <div class="resultValue" v-if="isQualifiedForLp">
          {{ averageWeeklyEarnings.lpRate | currency }}
        </div>
        <div class="resultValue" v-else>{{ 0 | currency }}</div>
      </div>
      <calc-field-box v-if="pdRating < 70">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          PD is under 70%, so this rating does not qualify for a life pension.
        </div>
      </calc-field-box>
      <calc-field-box v-if="pdRating === 100">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          PD is 100%, so this rating does not qualify for a life pension.
        </div>
      </calc-field-box>
      <result-calculator-messages :pd-rating="pdRating" :claim-number="claimNumber"/>
      <save-button form="money-chart-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { format as dateFormat, addDays, differenceInDays } from 'date-fns';
  import { props, provide, filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, minValue, maxValue, between, integer } from 'vuelidate/lib/validators';
  import { roundFloat } from '../util/float';
  import rtwAdjustmentPeriod from './permanent-disability/rtw-adjustment-period';
  import RtwAdjustments from './permanent-disability/rtw-adjustment-text';
  import AverageWeeklyEarnings from './permanent-disability/average-weekly-earnings';
  import ResultCalculatorMessages from './permanent-disability/result-calculator-messages';
  import saveMixin from './saveable/money-chart';

  const initialData = () => {
    return {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      dateOfInjury: null,
      pdRating: null,
      totalWeeksOfPd: 0,
      lifePensionEarningMax: 0,
      pdEarningsLimit: {
        min: 0,
        max: Infinity
      },
      wageOption: 'custom',
      customAverageWeeklyEarnings: null,
      jobOffered: null,
      pdCommencementDate: null,
      rtwDate: null,
      results: false,
      saveStatus: null,
      saveMessage: null
    };
  };

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    data() {
      return Object.assign({}, initialData(), this.form);
    },
    mounted() {
      this.initialFetchData()
    },
    watch: {
      async pdRating(newPdRating) {
        if (!this.$v.pdRating.$invalid && !this.$v.dateOfInjury.$invalid) {
          this.pdEarningsLimit = await this.fetchPdEarningsLimit(newPdRating, this.dateOfInjury);
          if(newPdRating !== 100) {
            this.totalWeeksOfPd = await this.fetchTotalWeeksOfPd(newPdRating, this.dateOfInjury);
          }
        }
      },

      async dateOfInjury(newDateOfInjury) {
        if (!this.$v.pdRating.$invalid && !this.$v.dateOfInjury.$invalid) {
          this.pdEarningsLimit = await this.fetchPdEarningsLimit(this.pdRating, newDateOfInjury);
          this.lifePensionEarningMax = await this.fetchLifePensionEarningMax(newDateOfInjury);
          if(this.pdRating !== 100) {
            this.totalWeeksOfPd = await this.fetchTotalWeeksOfPd(this.pdRating, newDateOfInjury);
          }
        }
      },

    },
    computed: {
      isQualifiedForLp() {
        return this.pdRating >= 70 && this.pdRating !== 100;
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          this.pdRating, this.wageOption, this.customAverageWeeklyEarnings, this.pdEarningsLimit,
          this.lifePensionEarningMax
        );
      },
      weeklyPdRate() {
        return this.averageWeeklyEarnings.pdRate.toFixed(2);
      },
      hasReturnToWorkAdjustment() {
        return rtwAdjustmentPeriod(this.dateOfInjury) === 'during' && this.pdRating !== 100;
      },
      daysAfterPsDate() {
        if (this.pdCommencementDate && this.rtwDate && this.jobOffered === 'yes') {
          return differenceInDays(this.rtwDate, this.pdCommencementDate);
        } else {
          return 60;
        }
      },
      rtwWeeksPaidAtBaseRate() {
        const raw = this.daysAfterPsDate / 7;
        return { raw, weeks: Math.trunc(raw), days: this.daysAfterPsDate % 7 }
      },
      rtwPdPaidAtBaseRate() {
        const { days, weeks } = this.rtwWeeksPaidAtBaseRate;
        return weeks * this.weeklyPdRate + days * roundFloat(this.weeklyPdRate / 7, 2);
      },
      rtwModifier() {
        return { yes: 0.85, no: 1.15 }[this.jobOffered];
      },
      rtwWeeklyPdCompensation() {
        return this.weeklyPdRate * this.rtwModifier;
      },
      rtwWeeksOfPdRemaining() {
        return roundFloat(this.totalWeeksOfPd - this.rtwWeeksPaidAtBaseRate.raw, 5);
      },
      rtwPdIndemnityRemaining() {
        return this.rtwWeeksOfPdRemaining * this.rtwWeeklyPdCompensation;
      },
      rtwTotalPdPayout() {
        return this.rtwPdPaidAtBaseRate + this.rtwPdIndemnityRemaining;
      }
    },
    methods: {
      initialFetchData() {
        return new Promise(async (resolve, reject) => {
          if (!this.$v.pdRating.$invalid && !this.$v.dateOfInjury.$invalid) {
            this.pdEarningsLimit = await this.fetchPdEarningsLimit(this.pdRating, this.dateOfInjury);
            this.lifePensionEarningMax = await this.fetchLifePensionEarningMax(this.dateOfInjury);
            if(this.pdRating !== 100) {
              this.totalWeeksOfPd = await this.fetchTotalWeeksOfPd(this.pdRating, this.dateOfInjury);
            }
          }
        });
      },
      async fetchTotalWeeksOfPd(pdRating, dateOfInjury) {
        const params = { percent: pdRating, date: dateFormat(dateOfInjury, 'YYYY-MM-DD') };
        return axios.get('/pdd_durations/lookup', { params }).then(r => parseFloat(r.data));
      },
      async fetchPdEarningsLimit(pdRating, dateOfInjury) {
        const m = pdRating === 100 ? 1.5 : 1;
        const endpoint = pdRating === 100 ? 'ttd_limits' : 'ppd_earnings_limits';
        const params = { percent: pdRating, date: dateFormat(dateOfInjury, 'YYYY-MM-DD') };
        return axios.get(`/${endpoint}/lookup`, { params })
          .then(({ data: { min, max } }) => ({ min: parseFloat(min) * m, max: parseFloat(max) * m }));
      },
      async fetchLifePensionEarningMax(dateOfInjury) {
        const params = { date: dateFormat(dateOfInjury, 'YYYY-MM-DD') };
        return axios.get('/life_pension_limits/lookup', { params })
          .then(({ data: { value }}) => ({ value: parseFloat(value) }));
      },
      addDays
    },
    validations: {
      dateOfInjury: { required, minValue: minValue(new Date(1996, 0, 1)), maxValue:
        maxValue(methods.methods.endOfYear()) },
      pdRating: { required, integer, between: between(1, 100) },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value) : true },
        minValue: minValue(0)
      },
      jobOffered: { required(value) { return this.hasReturnToWorkAdjustment ? required(value) : true } },
      pdCommencementDate: { minValue(value) { return minValue(this.dateOfInjury)(value) } },
      rtwDate: { between(value) { return between(this.pdCommencementDate, addDays(this.pdCommencementDate, 60))(value) } }
    },
    components: { DatePicker, RtwAdjustments, ResultCalculatorMessages }
  }
</script>
