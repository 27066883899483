import Vue from 'vue/dist/vue.esm';
import Vuex from 'vuex/dist/vuex.esm';

import commutationOfDeathBenefits from './modules/commutation_of_death_benefits';

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    commutationOfDeathBenefits
  }
});
