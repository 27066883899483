<template>
  <div class="calcField" :class="containerClass">
    <slot></slot>
  </div>
</template>

<script>
  export default {
    props: {
      containerClass: {
        type: String
      }
    }
  }
</script>
