var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "th",
    {
      staticClass: "sortable-header",
      attrs: { scope: "col" },
      on: {
        click: function ($event) {
          return _vm.$emit("sort", { [_vm.name]: _vm.direction })
        },
      },
    },
    [_vm._t("default"), _vm._v(" "), _c("i", { class: _vm.classes })],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }