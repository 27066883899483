import { addDays, differenceInDays, isAfter } from 'date-fns';

class PdPayments {
  constructor({
                pdCommencementDate,
                rtwDate,
                dateOfCalculation,
                jobOffered,
                weeklyPdRate,
                totalWeeksOfPd,
                endOfPdDate
              }) {
    this.pdCommencementDate = pdCommencementDate;
    this.rtwDate = rtwDate;
    this.dateOfCalculation = dateOfCalculation;
    this.jobOffered = jobOffered;
    this.weeklyPdRate = weeklyPdRate;
    this.totalWeeksOfPd = totalWeeksOfPd;
    this.endOfPdDate = endOfPdDate;
  }

  get isPdTimeRemaining() {
    return isAfter(this.endOfPdDate, this.dateOfCalculation);
  }

  get weeksOfPdAlreadyPaid() {
    return this.isPdTimeRemaining ?
      (differenceInDays(this.dateOfCalculation, this.pdCommencementDate) + 1) / 7.0 : this.totalWeeksOfPd;
  }

  get daysAfterPsDate() {
    if (this.pdCommencementDate && this.rtwDate && this.jobOffered === 'yes') {
      return differenceInDays(this.rtwDate, this.pdCommencementDate);
    } else {
      return 60;
    }
  }

  get daysAtBaseRateAfterDoc() {
    return Math.max(
      0,
      differenceInDays(addDays(this.pdCommencementDate, this.daysAfterPsDate), this.dateOfCalculation)
    );
  }

  get weeksPaidAtBaseRate() {
    const raw = this.daysAfterPsDate / 7;
    return { raw, weeks: Math.trunc(raw), days: this.daysAfterPsDate % 7 };
  }

  get modifier() {
    return { yes: 0.85, no: 1.15 }[this.jobOffered];
  }

  get weeklyPdCompensation() {
    return this.weeklyPdRate * this.modifier;
  }

  get weeksOfPdRemaining() {
    return this.totalWeeksOfPd - this.weeksOfPdAlreadyPaid;
  }

  get weeksOfPdDuringBaseRate() {
    return (this.daysAfterPsDate - this.daysAtBaseRateAfterDoc + 1) / 7;
  }

  get weeksOfPdAfterAdjustment() {
    return this.weeksOfPdAlreadyPaid - this.weeksPaidAtBaseRate.raw;
  }

  get pdBenefitsAlreadyPaid() {
    return this.weeksOfPdDuringBaseRate * this.weeklyPdRate +
      Math.max(0, this.weeksOfPdAfterAdjustment) * this.weeklyPdCompensation;
  }

  get weeksOfPdRemainingAtBaseRate() {
    return this.daysAtBaseRateAfterDoc / 7;
  }

  get weeksOfPdRemainingAfterAdjustment() {
    return this.weeksOfPdRemaining - this.weeksOfPdRemainingAtBaseRate;
  }

  get balanceOfPdDueWithoutDiscount() {
    return this.weeksOfPdRemainingAtBaseRate * this.weeklyPdRate +
      this.weeksOfPdRemainingAfterAdjustment * this.weeklyPdCompensation;
  }

  get totalPd() {
    return this.pdBenefitsAlreadyPaid + this.balanceOfPdDueWithoutDiscount;
  }

  presentValueOfRemainingPd(presentValue, interestRate) {
    const n = this.daysAtBaseRateAfterDoc / 7;
    const r = Math.pow((1 + interestRate / 100), 7 / 365.24) - 1;
    return (presentValue * this.modifier) +
      (this.weeklyPdRate - this.weeklyPdCompensation) * (1 - Math.pow(1 + r, -n)) / r;
  }
}

export default PdPayments;
