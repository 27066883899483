var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.saveable
    ? _c("div", [
        _vm.status
          ? _c("div", { staticClass: "calcFieldBox" }, [
              _c(
                "div",
                { staticClass: "formFieldNotation success-notification" },
                [
                  _vm.status === "success"
                    ? _c("p", [
                        _vm._m(0),
                        _vm._v(
                          "\n        Your report has been saved and added to your "
                        ),
                        _c("a", { attrs: { href: "/calculators/saved" } }, [
                          _vm._v("Saved Reports"),
                        ]),
                        _vm._v(".\n      "),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.status === "failure"
                    ? _c("p", [
                        _vm._m(1),
                        _vm._v("\n        " + _vm._s(_vm.message) + "\n      "),
                      ])
                    : _vm._e(),
                ]
              ),
            ])
          : _vm._e(),
        _vm._v(" "),
        _c(
          "div",
          {
            staticClass: "buttonContainer",
            class: { "tooltip-top big-tip": !_vm.enabled },
            attrs: { "data-tooltip": _vm.tooltipText },
          },
          [
            _c(
              "button",
              {
                staticClass: "button black-button",
                class: { disabled: !_vm.enabled },
                attrs: {
                  type: "submit",
                  form: _vm.form,
                  disabled: !_vm.enabled,
                },
                on: { click: _vm.saveData },
              },
              [_vm._v("\n      Save Report\n    ")]
            ),
          ]
        ),
      ])
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-check-circle" }),
      _vm._v("Success."),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", [
      _c("i", { staticClass: "fas fa-times-circle" }),
      _vm._v("Failure."),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }