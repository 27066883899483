import valueCombiner from '../value-combiner';

const compare = (a, b) => b.rating - a.rating;

class CombinedRatingGroup {
  constructor() {
    this.impairmentRatings = [];
  }

  add(impairmentRating) {
    this.impairmentRatings.push(impairmentRating);
    this.impairmentRatings.sort(compare);
  }

  get ratingString() {
    return `${this.impairmentRatings.map(e => e.rating).join(' C ')} = ${this.rating}`;
  }

  get rating() {
    const ratings = this.impairmentRatings.map(e => e.rating / 100).reduce(valueCombiner, []);
    return Math.round(ratings[ratings.length - 1] * 100);
  }
}

export default CombinedRatingGroup;
