var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _vm.period === "before"
        ? [
            _c("i", { staticClass: "far fa-arrow-up" }),
            _vm._v(" "),
            _c("span", [
              _vm._v("No RTW Adjustments for injuries before 1/1/2005."),
            ]),
          ]
        : _vm.period === "after"
        ? [
            _c("i", { staticClass: "far fa-arrow-up" }),
            _vm._v(" "),
            _c("span", [
              _vm._v("No RTW Adjustments for injuries on/after 1/1/2013."),
            ]),
          ]
        : _vm.period === "during"
        ? [
            _vm.jobOffered === "na"
              ? _c(
                  "span",
                  [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n      No RTW adjustment applied for employers with fewer than 50 employees. (For more detail see\n      "
                    ),
                    _c("soc-section", { attrs: { section: "11.6" } }),
                    _vm._v(".)\n    "),
                  ],
                  1
                )
              : _c(
                  "section",
                  [
                    _vm.pdRating === 0 ? [_vm._m(0)] : _vm._e(),
                    _vm._v(" "),
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(" "),
                    _vm.jobOffered === "yes"
                      ? _c("span", [
                          _vm._v(
                            "15% decrease of indemnity payments following offer to return to work."
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.jobOffered === "no"
                      ? _c("span", [
                          _vm._v(
                            "\n        15% increase of indemnity payments after the first 60 days following the disability becoming Permanent &\n        Stationary.\n      "
                          ),
                        ])
                      : _vm._e(),
                  ],
                  2
                ),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("p", [
      _c("i", { staticClass: "far fa-arrow-up" }),
      _vm._v("\n        No RTW adjustment applied because PD is 0%.\n      "),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }