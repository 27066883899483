var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("table", { staticClass: "benefits-through-doc" }, [
    _vm._m(0),
    _vm._v(" "),
    _vm._m(1),
    _vm._v(" "),
    _c(
      "tbody",
      _vm._l(_vm.rows, function (row, index) {
        return _c("benefit-table-row", {
          key: index,
          attrs: {
            from: row.from,
            through: row.through,
            rate: row.rate,
            index: index,
          },
        })
      }),
      1
    ),
  ])
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col"),
      _vm._v(" "),
      _c("col"),
      _vm._v(" "),
      _c("col", { staticClass: "inputCol" }),
      _vm._v(" "),
      _c("col"),
      _vm._v(" "),
      _c("col"),
      _vm._v(" "),
      _c("col"),
      _vm._v(" "),
      _c("col"),
      _vm._v(" "),
      _c("col"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("thead", [
      _c("tr", [
        _c("th", [_vm._v("From")]),
        _vm._v(" "),
        _c("th", [_vm._v("Through")]),
        _vm._v(" "),
        _c("th", [_vm._v("Weekly Rate")]),
        _vm._v(" "),
        _c("th", [_vm._v("Number of Weeks")]),
        _vm._v(" "),
        _c("th", [_vm._v("Paid in Period")]),
        _vm._v(" "),
        _c("th", [_vm._v("Weeks from Start")]),
        _vm._v(" "),
        _c("th", [_vm._v("Paid from Start")]),
        _vm._v(" "),
        _c("th", [_vm._v("Balance Remaining")]),
      ]),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }