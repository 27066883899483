import axios from 'axios';

const buildImpairment = (
  { impairment_nr, description, title, fec_rank, adjustment_factor, chapter_nr, special_case_for }
) => (
  {
    value: impairment_nr,
    header: `${title} - ${description}`,
    label: `${impairment_nr} - ${title} - ${description}`,
    fecRank: fec_rank,
    adjustmentFactor: parseFloat(adjustment_factor),
    chapter: chapter_nr,
    specialCaseFor: special_case_for
  }
);

export const impairments = () => axios.get('/impairments.json').then(({ data }) => data.map(buildImpairment));

export const specialCaseOptions = (impairments) =>
  impairments.filter(({ specialCaseFor }) => specialCaseFor).reduce((memo, impairment) => {
    const { specialCaseFor } = impairment;
    memo[specialCaseFor] = memo[specialCaseFor] || [];
    memo[specialCaseFor].push(impairment);
    return memo;
  }, {});

export const occVariants = () => axios.get('/occ_variants.json').then(({ data }) => {
  return data.reduce((memo, { impairment_nr, groups }) => {
    memo[impairment_nr] = groups;
    return memo;
  }, {});
});

export const occupationCodes = () => axios.get('/occupation_codes.json').then(({ data }) => {
  const groups = {};

  return data.reduce((memo, { id, occupation, industry, group }) => {
    if (!groups.hasOwnProperty(group)) {
      groups[group] = true;
      memo.push({ group, label: `Group ${group}` });
    }
    if(occupation && industry) {
      memo.push({ id, group, label: `${occupation} - ${industry} (Group ${group})` });
    }
    else {
      memo.push({ id, group, label: `(Group ${group})` });
    }

    return memo;
  }, []);
});

export const occupationalAdjustments = () => axios.get('/occupational_adjustments.json').then(({ data }) => {
  return data.reduce((memo, { rating, rating_percent }) => {
    memo[rating] = rating_percent;
    return memo;
  }, {});
});

export const ageAdjustments = () => axios.get('/age_adjustments.json').then(({ data }) => {
  return data.reduce((memo, { rating, value, lower_age: min, upper_age: max }) => {
    memo[rating] = memo[rating] || [];
    memo[rating].push({ value, min, max });
    return memo;
  }, {});
});

export const gafToWpiConversions = () => axios.get('/gaf_to_wpi_conversions.json').then(({ data }) => {
  return data.reduce((memo, { gaf, wpi }) => {
    memo[gaf] = wpi;
    return memo;
  }, {});
});

