import { endOfYear, isBefore, addDays, subDays, startOfDay, compareAsc, isSameYear } from 'date-fns';

export const createAnnualPair = function(startDate, ...midDates) {
  let endDate = subDays(startDate, 1);
  midDates.sort(compareAsc);

  return {
    next() {
      let from = startOfDay(addDays(endDate, 1));
      let through = endOfYear(from);

      if (isSameYear(from, midDates[0]) && isBefore(from, midDates[0])) {
        through = midDates.shift()
      }

      endDate = through;

      return { from, through }
    }
  }
};
