<template>
  <div class="h-scroll-container">
    <table class="fold-table">
      <colgroup>
        <col class="claimID">
        <col class="firstName">
        <col class="lastName">
        <col class="dateModified">
      </colgroup>
      <thead>
      <tr>
        <th colspan="4" class="report-search">
          <span class="search-input">
            <input type="text" :value="query" @input="setQuery" placeholder="Enter search here">
            <span v-if="query" class="reset-button" @click="query = ''">&times;</span>
          </span>
          <button type="button" class="search-button">Search</button>
        </th>
      </tr>
      <tr>
        <sortable-header name="claim_number.raw" :current="currentSort" @sort="handleSort">
          Claim/Matter
        </sortable-header>
        <sortable-header name="worker_first_name.raw" :current="currentSort" @sort="handleSort">
          First Name
        </sortable-header>
        <sortable-header name="worker_last_name.raw" :current="currentSort" @sort="handleSort">
          Last Name
        </sortable-header>
        <sortable-header name="updated_at" :current="currentSort" @sort="handleSort">
          Date Modified
        </sortable-header>
      </tr>
      </thead>
      <tbody>
        <template v-for="cg in groups" :sort="currentSort">
          <calculator-group v-if="cg.calculators.length > 0" :key="cg.id" :group="cg" />
        </template>
      </tbody>
    </table>

    <paginate container-class="pagination"
              :page-count="response.meta.total_pages"
              :click-handler="handlePageClick"
              :first-last-button="true"
              prev-text="<i class='fa fa-play fa-rotate-180'></i>"
              next-text="<i class='fa fa-play'></i>"
              first-button-text="<i class='fa fa-fast-backward'></i>"
              last-button-text="<i class='fa fa-fast-forward'></i>">
    </paginate>
  </div>
</template>

<script>
  import axios from 'axios';
  import debounce from 'lodash.debounce';
  import CalculatorGroup from './saved-reports/group';
  import Paginate from 'vuejs-paginate';
  import SortableHeader from './saved-reports/sortable-header';

  export default {
    data() {
      return {
        currentSort: { updated_at: 'desc' },
        page: 1,
        query: '',
        response: null
      }
    },
    asyncComputed: {
      response: {
        get() {
          const sortName = Object.keys(this.currentSort)[0];
          const sortDir = this.currentSort[sortName];
          const sortKey = `sort[${sortName}]`;
          const params = { 'es[query]': encodeURIComponent(this.query), page: this.page, [sortKey]: sortDir };
          return axios.get('/calculators/saved.json', { params }).then(({ data }) => data);
        },
        default: { meta: { total_pages: 0 }, records: [] }
      }
    },
    computed: {
      groups() {
        return this.response ? this.response.records : [];
      }
    },
    methods: {
      setQuery: debounce(function(e) { this.query = e.target.value }, 500),
      handlePageClick(page) {
        this.page = page;
      },
      handleSort(sort) {
        this.currentSort = sort;
        this.$forceUpdate();
      },
    },
    components: {
      CalculatorGroup, Paginate, SortableHeader
    }
  }
</script>
