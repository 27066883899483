<template>
  <tr class="fold" :class="{ expanded: group.expanded }">
    <td colspan="4">
      <div class="fold-content">
        <table>
          <colgroup>
            <col class="reportType">
            <col class="drFirst">
            <col class="drLast">
            <col class="drReportDate">
            <col class="actions">
          </colgroup>
          <thead>
            <tr>
              <th scope="col">Report Type</th>
              <th scope="col">Dr. First</th>
              <th scope="col">Dr. Last</th>
              <th scope="col">Dr. Report Date</th>
              <th scope="col">Actions</th>
            </tr>
          </thead>
          <tbody>
            <calculator-row
              v-for="(calculator, index) in group.calculators"
              :key="calculator.id"
              :index="index"
              :calculator="calculator"
              @delete="showDeleteModal">
            </calculator-row>
          </tbody>
        </table>
        <modal v-if="deleteIndex !== null">
          <template slot="body">
            <p class="message">
              Are you sure you wish to delete this saved calculator?
            </p>
          </template>
          <template slot="footer">
            <button class="button clear-button action" @click="deleteCalculator">Yes - Delete</button>
            <button class="button black-button action" @click="cancelDelete">No - Don't Delete</button>
          </template>
        </modal>
      </div>
    </td>
  </tr>
</template>

<script>
  import CalculatorRow from './calculator-row';
  import Modal from '../modal';
  import axios from 'axios';

  export default {
    props: {
      group: {
        type: Object,
        required: true
      }
    },
    data() {
      return {
        deleteIndex: null
      }
    },
    methods: {
      showDeleteModal(index) {
        this.deleteIndex = index;
      },
      deleteCalculator() {
        const index = this.deleteIndex;
        this.deleteIndex = null;

        const { id, component } = this.group.calculators[index];
        const authenticity_token = document.querySelector('meta[name=csrf-token]').content;
        axios.delete(`/calculators/${component}/${id}`, { data: { authenticity_token } }).then(() => {
          this.$delete(this.group.calculators, index);
        });
      },
      cancelDelete() {
        this.deleteIndex = null;
      }
    },
    components: {
      CalculatorRow, Modal
    }
  }
</script>
