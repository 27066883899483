<template>
  <div class="pretty p-default p-round p-has-focus" :class="containerClass" :style="elStyle">
    <slot></slot>
    <div class="state p-success-o">
      <slot name="label"></slot>
    </div>
    <slot name="custom"></slot>
  </div>
</template>

<script>
  export default {
    props: {
      containerClass: String,
      elStyle: String
    }
  }
</script>
