import { getYear, addYears, differenceInDays } from 'date-fns';

const leapYearsBefore = (date) => {
  const year = getYear(date) - 1;
  return Math.trunc(year / 4) - Math.trunc(year / 100) + Math.trunc(year / 400);
};

export const leapYearsBetween = (start, end) => {
  return leapYearsBefore(end) - leapYearsBefore(addYears(start, 1));
};

export const yearsBetween = (start, end) => {
  return (differenceInDays(end, start) - leapYearsBetween(start, end) - 1) / 365;
};
