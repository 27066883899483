import { addDays, subDays, differenceInHours, startOfDay, addHours } from 'date-fns';

class Dates {
  constructor(pdStartDate, totalWeeksOfPd) {
    this.pdStartDate = pdStartDate;
    this.totalWeeksOfPd = totalWeeksOfPd;
  }

  get totalDaysOfPd() {
    return this.totalWeeksOfPd * 7;
  }

  get endOfPdDate() {
    return subDays(addHours(this.pdStartDate, this.totalDaysOfPd * 24), 1);
  }

  get lpStartDate() {
    return startOfDay(addDays(this.endOfPdDate, 1));
  }

  differenceInDaysToLpStartDate(date) {
    return differenceInHours(this.endOfPdDate, date) / 24;
  }
}

export default Dates;
