<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form @submit.prevent>
        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ to: defaultToDate(), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">Enter the Average Weekly Earnings for the Injured Worker.</template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="min" id="awe-min"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-min" slot="label">Min</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" id="average-weekly-wage" type="text"
                       class="conditional-input" placeholder="Custom"
                       @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings:</div>
        <div class="resultValue">{{ averageWeeklyEarnings | currency }} {{ wageSuffix }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">TD Daily Rate:</div>
        <div class="resultValue">{{ tdDailyRate | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">TD Weekly Rate:</div>
        <div class="resultValue">{{ tdWeeklyRate | currency }}</div>
      </div>
      <div class="resultContainer" v-if="dateOfInjury >= new Date(2004, 3, 19)">
        <div class="resultLabel">104 Weeks TD Total:</div>
        <div class="resultValue">{{ tdWeeklyRate * 104 | currency }}</div>
      </div>

      <h4>The following statutory limit applies to the date of injury entered:</h4>

      <div class="calcFieldBox">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          <template v-if="dateOfInjury < new Date(2004, 3, 19)">No effective statutory limit on total TTD.</template>
          <template v-if="dateOfInjury >= new Date(2004, 3, 19) && dateOfInjury <= new Date(2007, 11, 31)">
            TTD benefits limited to 104 weeks from the date TTD is commenced.
          </template>
          <template v-if="dateOfInjury >= new Date(2008, 0, 1)">
            TTD benefits paid up to 104 weeks within a period of five years from DOI.
          </template>
        </div>
      </div>

      <div class="calcFieldBox">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          See <soc-section section="9.14"/> for details on statutory limits for TTD.
        </div>
      </div>

      <div class="calcFieldBox">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          See the <calculator-link name="date_wheel"/> calculator to determine starting and ending dates for payment
          periods.
        </div>
      </div>
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import { format as dateFormat } from 'date-fns';
  import { filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, minValue, maxValue, decimal } from 'vuelidate/lib/validators';

  export default {
    mixins: [filters, methods, components, validationMixin],
    data() {
      return {
        results: false,
        wageOption: 'max',
        customAverageWeeklyEarnings: null,
        dateOfInjury: null,
        ttdLimits: null
      }
    },
    asyncComputed: {
      ttdLimits: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/ttd_limits/lookup', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { min: 0, max: Infinity }
      }
    },
    computed: {
      wageSuffix() {
        return {
          [this.minAverageWeeklyEarnings]: '(Statutory Min)',
          [this.maxAverageWeeklyEarnings]: '(Statutory Max)',
        }[this.averageWeeklyEarnings]
      },
      minAverageWeeklyEarnings() {
        return this.ttdLimits.min * 1.5;
      },
      maxAverageWeeklyEarnings() {
        return this.ttdLimits.max * 1.5;
      },
      averageWeeklyEarnings() {
        return {
          min: this.minAverageWeeklyEarnings,
          max: this.maxAverageWeeklyEarnings,
          custom: Math.min(
            Math.max(this.customAverageWeeklyEarnings, this.minAverageWeeklyEarnings), this.maxAverageWeeklyEarnings
          )
        }[this.wageOption]
      },
      tdWeeklyRate() {
        return this.averageWeeklyEarnings * 2 / 3;
      },
      tdDailyRate() {
        return this.tdWeeklyRate / 7;
      },
      boundaryMode() {
        return ['min', 'max'].includes(this.wageOption);
      }
    },
    validations: {
      wageOption: { required },
      dateOfInjury: {
        required,
        minValue: minValue(methods.methods.defaultToDate()),
        maxValue: maxValue(methods.methods.endOfYear())
      },
      customAverageWeeklyEarnings: {
        required(value) {
          return this.boundaryMode ? true : required(value)
        },
        decimal
      }
    },
    components: { DatePicker }
  }
</script>
