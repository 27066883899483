var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "money-chart-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      highlighted: { dates: [new Date()] },
                      "disabled-dates": {
                        to: new Date(1996, 0, 1),
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.initialFetchData()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "calcFieldBox" }, [
            _c(
              "div",
              { staticClass: "formFieldNotation" },
              [
                _c("i", { staticClass: "far fa-arrow-up" }),
                _vm._v(
                  "\n          For Continuous Trauma claims, enter the ending date of the CT period or the date determined in accordance\n          with LC 5412 (See "
                ),
                _c("soc-section", { attrs: { section: "5.5" } }),
                _vm._v(").\n        "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.pdRating.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "pd-rating",
                    error: _vm.errorMessage("pdRating"),
                  },
                },
                [
                  _vm._v("\n          PD Rating:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the PD % value. Obtain this from a calculated rating string or combined rating. If you need to\n            calculate the PD Rating, use the "
                      ),
                      _c("calculator-link", {
                        attrs: { name: "pd", "claim-number": _vm.claimNumber },
                      }),
                      _vm._v(".\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.pdRating,
                      expression: "pdRating",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: {
                    type: "number",
                    id: "pd-rating",
                    min: "1",
                    max: "100",
                  },
                  domProps: { value: _vm.pdRating },
                  on: {
                    change: [
                      function ($event) {
                        _vm.pdRating = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.initialFetchData()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
                _vm._v(" "),
                _c("span", [_vm._v("%")]),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customAverageWeeklyEarnings.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.wageOption === "custom",
                },
              },
            },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    error: _vm.errorMessage("customAverageWeeklyEarnings"),
                  },
                },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v("\n            Select "),
                      _c("b", [_vm._v("Custom")]),
                      _vm._v(
                        " to input the Average Weekly Earnings for the injured worker. If the AWE you enter is\n            outside applicable limits, it will be modified accordingly and the updated value displayed in the Results\n            section. Or, select "
                      ),
                      _c("b", [_vm._v("Max")]),
                      _vm._v(
                        " if you know that the AWE is high enough that the applicable statutory\n            limits would apply. Note that different limits apply to Permanent Disability and when applicable, Life\n            Pension. If you are unsure whether the actual AWE value exceeds the limits for both, input the actual AWE\n            value instead. (See "
                      ),
                      _c("soc-section", { attrs: { section: "8.6" } }),
                      _vm._v(" for more detail on AWE.)\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "max", id: "awe-max" },
                      domProps: { checked: _vm._q(_vm.wageOption, "max") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "max"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-max" },
                        slot: "label",
                      },
                      [_vm._v("Max")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "custom",
                        id: "awe-custom",
                      },
                      domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                      on: {
                        change: function ($event) {
                          _vm.wageOption = "custom"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-custom" },
                        slot: "label",
                      },
                      [_vm._v("Custom")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("abbr", [_vm._v("$")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customAverageWeeklyEarnings,
                              expression: "customAverageWeeklyEarnings",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: { type: "text", placeholder: "Enter Amount" },
                          domProps: { value: _vm.customAverageWeeklyEarnings },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customAverageWeeklyEarnings = _vm._n(
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$v.customAverageWeeklyEarnings.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.wageOption === "max"
            ? _c(
                "calc-field-box",
                { attrs: { "container-class": { comment: true } } },
                [
                  _c("div", { staticClass: "formFieldNotation" }, [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n          Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can differ\n          . To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.\n        "
                    ),
                  ]),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasReturnToWorkAdjustment
            ? _c(
                "calc-field-box",
                { attrs: { error: _vm.$v.jobOffered.$error } },
                [
                  _c(
                    "calc-label",
                    { attrs: { error: _vm.errorMessage("jobOffered") } },
                    [
                      _vm._v(
                        "\n          Was a Job Offered within 60 Days of P&S Date?:\n          "
                      ),
                      _c(
                        "template",
                        { slot: "tooltip" },
                        [
                          _vm._v(
                            "\n            Was an offer made to the Injured Worker to return to work by the employer within 60 days after the\n            P&S date? (If the employer has fewer than 50 employees, select N/A.) See "
                          ),
                          _c("soc-section", { attrs: { section: "11.6" } }),
                          _vm._v(".\n          "),
                        ],
                        1
                      ),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    "calc-field",
                    [
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "yes",
                            id: "offered-yes",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "yes") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "yes"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-yes" },
                            slot: "label",
                          },
                          [_vm._v("Yes")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "no",
                            id: "offered-no",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "no") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "no"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-no" },
                            slot: "label",
                          },
                          [_vm._v("No")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("pretty-radio", [
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model",
                              value: _vm.jobOffered,
                              expression: "jobOffered",
                            },
                          ],
                          attrs: {
                            type: "radio",
                            value: "na",
                            id: "offered-na",
                          },
                          domProps: { checked: _vm._q(_vm.jobOffered, "na") },
                          on: {
                            change: [
                              function ($event) {
                                _vm.jobOffered = "na"
                              },
                              function ($event) {
                                return _vm.$v.jobOffered.$touch()
                              },
                            ],
                          },
                        }),
                        _vm._v(" "),
                        _c(
                          "label",
                          {
                            attrs: { slot: "label", for: "offered-na" },
                            slot: "label",
                          },
                          [_vm._v("N/A")]
                        ),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.hasReturnToWorkAdjustment && _vm.jobOffered === "yes"
            ? [
                _c(
                  "calc-field-box",
                  { attrs: { error: _vm.$v.pdCommencementDate.$error } },
                  [
                    _c(
                      "calc-label",
                      {
                        attrs: {
                          target: "ps-date",
                          error: _vm.errorMessage("pdCommencementDate", {
                            min: "the Date of Injury (%{min})",
                          }),
                        },
                      },
                      [
                        _vm._v(
                          "\n            Permanent and Stationary Date:\n            "
                        ),
                        _c(
                          "template",
                          { slot: "tooltip" },
                          [
                            _vm._v(
                              "\n              What was the date the Injured Worker became Permanent & Stationary? (If you don't know, leave this\n              blank.) See "
                            ),
                            _c("soc-section", { attrs: { section: "10.3" } }),
                            _vm._v(".\n            "),
                          ],
                          1
                        ),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "calc-field",
                      [
                        _c("date-picker", {
                          attrs: {
                            format: _vm.americanDateFormatter,
                            id: "ps-date",
                            "input-class": "shortField",
                            typeable: true,
                            placeholder: "mm/dd/yy",
                            "open-date": _vm.dateOfInjury,
                            "disabled-dates": { to: _vm.dateOfInjury },
                          },
                          on: {
                            input: function ($event) {
                              return _vm.$v.pdCommencementDate.$touch()
                            },
                          },
                          model: {
                            value: _vm.pdCommencementDate,
                            callback: function ($$v) {
                              _vm.pdCommencementDate = $$v
                            },
                            expression: "pdCommencementDate",
                          },
                        }),
                      ],
                      1
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _vm.pdCommencementDate
                  ? _c(
                      "calc-field-box",
                      { attrs: { error: _vm.$v.rtwDate.$error } },
                      [
                        _c(
                          "calc-label",
                          {
                            attrs: {
                              target: "rtw-date",
                              error: _vm.errorMessage("rtwDate", {
                                min: "the Permanent and Stationary Date",
                                max: "60 days thereafter (%{max})",
                              }),
                            },
                          },
                          [
                            _vm._v(
                              "\n            Return to Work:\n            "
                            ),
                            _c(
                              "template",
                              { slot: "tooltip" },
                              [
                                _vm._v(
                                  "\n              What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,\n              leave this blank.) See "
                                ),
                                _c("soc-section", {
                                  attrs: { section: "11.6" },
                                }),
                                _vm._v(".\n            "),
                              ],
                              1
                            ),
                          ],
                          2
                        ),
                        _vm._v(" "),
                        _c(
                          "calc-field",
                          [
                            _c("date-picker", {
                              attrs: {
                                format: _vm.americanDateFormatter,
                                id: "rtw-date",
                                "input-class": "shortField",
                                typeable: true,
                                placeholder: "mm/dd/yy",
                                "open-date": _vm.pdCommencementDate,
                                "disabled-dates": {
                                  to: _vm.pdCommencementDate,
                                  from: _vm.addDays(_vm.pdCommencementDate, 60),
                                },
                              },
                              on: {
                                input: function ($event) {
                                  return _vm.$v.rtwDate.$touch()
                                },
                              },
                              model: {
                                value: _vm.rtwDate,
                                callback: function ($$v) {
                                  _vm.rtwDate = $$v
                                },
                                expression: "rtwDate",
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            : _vm._e(),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        2
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _vm.pdRating < 100
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Total Weeks of PD:"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(_vm._s(_vm.totalWeeksOfPd)),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Average Weekly Earnings:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm._f("currency")(_vm.averageWeeklyEarnings.awe.pd)
                    ) +
                    " " +
                    _vm._s(_vm.averageWeeklyEarnings.suffix.pd) +
                    "\n      "
                ),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _vm.pdRating === 100
                ? _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PTD Weekly Rate:"),
                  ])
                : _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("PD Weekly Rate:"),
                  ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.weeklyPdRate))),
              ]),
            ]),
            _vm._v(" "),
            _vm.pdRating === 100
              ? _c(
                  "calc-field-box",
                  { attrs: { "container-class": { comment: true } } },
                  [
                    _c(
                      "div",
                      { staticClass: "formFieldNotation" },
                      [
                        _c("i", { staticClass: "far fa-arrow-up" }),
                        _vm._v(
                          "\n        To determine the present value of PTD payments owed, see the\n        "
                        ),
                        _c("calculator-link", {
                          attrs: {
                            name: "pv_of_ptd",
                            "claim-number": _vm.claimNumber,
                          },
                        }),
                        _vm._v(
                          " calculator. To calculate a commutation of\n        Permanent Total Disability see the "
                        ),
                        _c("calculator-link", {
                          attrs: {
                            name: "commutation_ptd",
                            "claim-number": _vm.claimNumber,
                          },
                        }),
                        _vm._v("\n        calculator.\n      "),
                      ],
                      1
                    ),
                  ]
                )
              : _vm._e(),
            _vm._v(" "),
            _vm.pdRating < 100
              ? [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Total PD Payout:"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(
                          _vm._f("currency")(
                            _vm.totalWeeksOfPd * _vm.weeklyPdRate
                          )
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("h3", { staticStyle: { "margin-bottom": "15px" } }, [
                    _vm._v("Return to Work Adjustments"),
                  ]),
                  _vm._v(" "),
                  _c(
                    "calc-field-box",
                    { attrs: { "container-class": { comment: true } } },
                    [
                      _c(
                        "div",
                        { staticClass: "formFieldNotation" },
                        [
                          _c("i", { staticClass: "far fa-arrow-up" }),
                          _vm._v(" "),
                          _c("rtw-adjustments", {
                            attrs: {
                              "job-offered": _vm.jobOffered,
                              "date-of-injury": _vm.dateOfInjury,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.hasReturnToWorkAdjustment && _vm.rtwModifier
              ? [
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Weeks Paid at Base Rate"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.rtwWeeksPaidAtBaseRate.weeks) +
                          " Weeks, " +
                          _vm._s(
                            _vm._f("toFixed")(
                              _vm.rtwWeeksPaidAtBaseRate.days,
                              0
                            )
                          ) +
                          " Days\n        "
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("PD Paid at Base Rate"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.rtwPdPaidAtBaseRate))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("RTW Modifier Used"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm.rtwModifier)),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("PD Weekly Rate (after Adjustment)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.rtwWeeklyPdCompensation))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Weeks of PD Remaining (after Adjustment)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("toFixed")(_vm.rtwWeeksOfPdRemaining, 2))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("PD Indemnity Remaining (after Adjustment)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(
                        _vm._s(_vm._f("currency")(_vm.rtwPdIndemnityRemaining))
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultContainer" }, [
                    _c("div", { staticClass: "resultLabel" }, [
                      _vm._v("Total PD Payout (after Adjustment)"),
                    ]),
                    _vm._v(" "),
                    _c("div", { staticClass: "resultValue" }, [
                      _vm._v(_vm._s(_vm._f("currency")(_vm.rtwTotalPdPayout))),
                    ]),
                  ]),
                ]
              : _vm._e(),
            _vm._v(" "),
            _vm.averageWeeklyEarnings.limitsApplied && _vm.isQualifiedForLp
              ? _c("div", { staticClass: "resultContainer" }, [
                  _c("div", { staticClass: "resultLabel" }, [
                    _vm._v("Average Weekly Earnings"),
                  ]),
                  _vm._v(" "),
                  _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("currency")(_vm.averageWeeklyEarnings.awe.lp)
                        ) +
                        " " +
                        _vm._s(_vm.averageWeeklyEarnings.suffix.lp) +
                        "\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Life Pension Weekly Rate:"),
              ]),
              _vm._v(" "),
              _vm.isQualifiedForLp
                ? _c("div", { staticClass: "resultValue" }, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm._f("currency")(_vm.averageWeeklyEarnings.lpRate)
                        ) +
                        "\n      "
                    ),
                  ])
                : _c("div", { staticClass: "resultValue" }, [
                    _vm._v(_vm._s(_vm._f("currency")(0))),
                  ]),
            ]),
            _vm._v(" "),
            _vm.pdRating < 70
              ? _c("calc-field-box", [
                  _c("div", { staticClass: "formFieldNotation" }, [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n        PD is under 70%, so this rating does not qualify for a life pension.\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _vm.pdRating === 100
              ? _c("calc-field-box", [
                  _c("div", { staticClass: "formFieldNotation" }, [
                    _c("i", { staticClass: "far fa-arrow-up" }),
                    _vm._v(
                      "\n        PD is 100%, so this rating does not qualify for a life pension.\n      "
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c("result-calculator-messages", {
              attrs: {
                "pd-rating": _vm.pdRating,
                "claim-number": _vm.claimNumber,
              },
            }),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "money-chart-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          2
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }