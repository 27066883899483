var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "calc-field-box",
    [
      _c(
        "calc-label",
        { attrs: { target: "claim-number" } },
        [
          _vm._v("\n    Claim/Matter Number:\n    "),
          _c(
            "template",
            { slot: "tooltip" },
            [
              _vm._v(
                "\n      Enter the claim or matter number for the report you are rating.\n      "
              ),
              _vm.saveable
                ? [
                    _vm._v(
                      "\n        This input is required only if you wish to save this report.\n        "
                    ),
                    _c("br"),
                    _c("br"),
                    _vm._v(
                      "\n        To view or edit an existing saved calculator, go to the\n        "
                    ),
                    _c(
                      "a",
                      {
                        attrs: { href: "/calculators/saved", target: "_blank" },
                      },
                      [_vm._v("Saved Reports")]
                    ),
                    _vm._v(".\n      "),
                  ]
                : _vm._e(),
            ],
            2
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c(
        "calc-field",
        {
          attrs: {
            "container-class": _vm.saveable ? "v-select half-width" : "",
          },
        },
        [
          !_vm.saveable
            ? _c("input", { attrs: { id: "claim-number", type: "text" } })
            : _vm._e(),
          _vm._v(" "),
          _vm.saveable
            ? _c("v-select", {
                attrs: {
                  value: _vm.value,
                  options: _vm.options,
                  clearable: false,
                  selectOnTab: true,
                  "get-option-label": _vm.getOptionLabel,
                  taggable: "",
                },
                on: { input: _vm.selectionChanged },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }