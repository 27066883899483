import beforeDoc from './modules/before_doc';
import afterDoc from './modules/after_doc';
import { differenceInDays, isAfter, isEqual } from 'date-fns';
import { endDateRows, maxBenefitRows } from '../../../commutations/death_benefits/rows';
import accumulate from '../../../../util/accumulate';

const initialState = () => {
  return {
    claimNumber: null,
    worker: { firstName: null, lastName: null },
    dateOfInjury: null,
    benefitStartDate: null,
    dateOfCalculation: null,
    calculationType: 'maxBenefit',
    benefitAmount: null,
    benefitEndDate: null,
    averageWeeklyEarnings: null,
    interestRate: 3,
    rateOfIncrease: 3.0,
    results: false,
    saveStatus: null,
    saveMessage: null,
    wageLimits: []
  }
};

const mutations = {
  updateFormInput(state, { name, value }) {
    state[name] = value;
  },
  updateWageLimits(state, values) {
    state.wageLimits = values;
  },
  resetState(state) {
    Object.assign(state, initialState(), { wageLimits: state.wageLimits });
  },
  clearState(state) {
    Object.assign(state, initialState());
  }
};

const getters = {
  afterDocRowIndex(state, getters) {
    const { dateOfCalculation } = state;
    const index = getters.rows.findIndex(({ from }) => isAfter(from, dateOfCalculation) || isEqual(from, dateOfCalculation));
    return index === -1 ? getters.rows.length : index;
  },
  rows(state) {
    const { calculationType, wageLimits, results } = state;
    if (!results || wageLimits.length === 0) { return [] }
    return { maxBenefit: maxBenefitRows, endDate: endDateRows }[calculationType](state);
  },
  intervalWeeks(state, getters) {
    return getters.rows.map(({ intervalWeeks }) => intervalWeeks);
  },
  intervalAmount(state, getters) {
    return getters.rows.map(({ rate }, index) => {
      return getters.intervalWeeks[index] * (rate || 0);
    });
  },
  cumulativeWeeks(state, getters) {
    return accumulate(getters.intervalWeeks);
  },
  cumulativeAmount(state, getters) {
    return accumulate(getters.intervalAmount);
  },
  balanceDue(state, getters) {
    const length = getters.cumulativeAmount.length;
    if (length === 0) { return 0 }
    const balance = getters.cumulativeAmount[length - 1];
    return getters.cumulativeAmount.map(amount => balance - amount);
  },
  weeklyInterestRate(state) {
    const interestRate = state.interestRate / 100;
    return Math.pow(1 + interestRate, 7 / 365.24) - 1;
  },
  presentValue(state, getters) {
    return getters.rows.map(({ from, intervalWeeks, rate }) => {
      const doc = state.dateOfCalculation;
      const n = (1 - Math.pow(1 + getters.weeklyInterestRate, -intervalWeeks)) / getters.weeklyInterestRate;
      const d = Math.pow(1 + getters.weeklyInterestRate, differenceInDays(from, doc) / 7);
      return rate * n / d;
    });
  }
};

export default {
  namespaced: true,
  state: initialState(),
  mutations,
  getters,
  modules: {
    beforeDoc,
    afterDoc
  }
}
