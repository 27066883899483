var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.dateOfInjury.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "date-of-injury",
                    error: _vm.errorMessage("dateOfInjury"),
                  },
                },
                [
                  _vm._v("\n          Date of Injury:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Enter the Injured Worker's Date of Injury. See "
                      ),
                      _c("soc-section", { attrs: { section: "5.5" } }),
                      _vm._v(
                        " for instructions on finding\n            the date to use for continuous trauma injuries.\n          "
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "date-of-injury",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      disabledDates: {
                        to: _vm.defaultToDate(),
                        from: _vm.endOfYear(),
                      },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.dateOfInjury.$touch()
                      },
                    },
                    model: {
                      value: _vm.dateOfInjury,
                      callback: function ($$v) {
                        _vm.dateOfInjury = $$v
                      },
                      expression: "dateOfInjury",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            {
              attrs: {
                error: _vm.$v.customAverageWeeklyEarnings.$error,
                "container-class": {
                  "expanding-radio": true,
                  "expanding-radio-margin": _vm.wageOption === "custom",
                },
              },
            },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    error: _vm.errorMessage("customAverageWeeklyEarnings"),
                  },
                },
                [
                  _vm._v("\n          Average Weekly Earnings:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v(
                      "Enter the Average Weekly Earnings for the Injured Worker."
                    ),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "min", id: "awe-min" },
                      domProps: { checked: _vm._q(_vm.wageOption, "min") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "min"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-min" },
                        slot: "label",
                      },
                      [_vm._v("Min")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: { type: "radio", value: "max", id: "awe-max" },
                      domProps: { checked: _vm._q(_vm.wageOption, "max") },
                      on: {
                        change: [
                          function ($event) {
                            _vm.wageOption = "max"
                          },
                          function ($event) {
                            _vm.customAverageWeeklyEarnings = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-max" },
                        slot: "label",
                      },
                      [_vm._v("Max")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.wageOption,
                          expression: "wageOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "custom",
                        id: "awe-custom",
                      },
                      domProps: { checked: _vm._q(_vm.wageOption, "custom") },
                      on: {
                        change: function ($event) {
                          _vm.wageOption = "custom"
                        },
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "awe-custom" },
                        slot: "label",
                      },
                      [_vm._v("Custom")]
                    ),
                    _vm._v(" "),
                    _c(
                      "div",
                      {
                        staticClass: "reveal-if-active",
                        attrs: { slot: "custom" },
                        slot: "custom",
                      },
                      [
                        _c("abbr", [_vm._v("$")]),
                        _vm._v(" "),
                        _c("input", {
                          directives: [
                            {
                              name: "model",
                              rawName: "v-model.number.lazy",
                              value: _vm.customAverageWeeklyEarnings,
                              expression: "customAverageWeeklyEarnings",
                              modifiers: { number: true, lazy: true },
                            },
                          ],
                          staticClass: "conditional-input",
                          attrs: {
                            id: "average-weekly-wage",
                            type: "text",
                            placeholder: "Custom",
                          },
                          domProps: { value: _vm.customAverageWeeklyEarnings },
                          on: {
                            change: [
                              function ($event) {
                                _vm.customAverageWeeklyEarnings = _vm._n(
                                  $event.target.value
                                )
                              },
                              function ($event) {
                                return _vm.$v.customAverageWeeklyEarnings.$touch()
                              },
                            ],
                            blur: function ($event) {
                              return _vm.$forceUpdate()
                            },
                          },
                        }),
                      ]
                    ),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c("section", { attrs: { id: "calculator-results" } }, [
          _c("h2", [_vm._v("Results")]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("Average Weekly Earnings:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(
                _vm._s(_vm._f("currency")(_vm.averageWeeklyEarnings)) +
                  " " +
                  _vm._s(_vm.wageSuffix)
              ),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("TD Daily Rate:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.tdDailyRate))),
            ]),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "resultContainer" }, [
            _c("div", { staticClass: "resultLabel" }, [
              _vm._v("TD Weekly Rate:"),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultValue" }, [
              _vm._v(_vm._s(_vm._f("currency")(_vm.tdWeeklyRate))),
            ]),
          ]),
          _vm._v(" "),
          _vm.dateOfInjury >= new Date(2004, 3, 19)
            ? _c("div", { staticClass: "resultContainer" }, [
                _c("div", { staticClass: "resultLabel" }, [
                  _vm._v("104 Weeks TD Total:"),
                ]),
                _vm._v(" "),
                _c("div", { staticClass: "resultValue" }, [
                  _vm._v(_vm._s(_vm._f("currency")(_vm.tdWeeklyRate * 104))),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c("h4", [
            _vm._v(
              "The following statutory limit applies to the date of injury entered:"
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "calcFieldBox" }, [
            _c(
              "div",
              { staticClass: "formFieldNotation" },
              [
                _c("i", { staticClass: "far fa-arrow-up" }),
                _vm._v(" "),
                _vm.dateOfInjury < new Date(2004, 3, 19)
                  ? [_vm._v("No effective statutory limit on total TTD.")]
                  : _vm._e(),
                _vm._v(" "),
                _vm.dateOfInjury >= new Date(2004, 3, 19) &&
                _vm.dateOfInjury <= new Date(2007, 11, 31)
                  ? [
                      _vm._v(
                        "\n          TTD benefits limited to 104 weeks from the date TTD is commenced.\n        "
                      ),
                    ]
                  : _vm._e(),
                _vm._v(" "),
                _vm.dateOfInjury >= new Date(2008, 0, 1)
                  ? [
                      _vm._v(
                        "\n          TTD benefits paid up to 104 weeks within a period of five years from DOI.\n        "
                      ),
                    ]
                  : _vm._e(),
              ],
              2
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "calcFieldBox" }, [
            _c(
              "div",
              { staticClass: "formFieldNotation" },
              [
                _c("i", { staticClass: "far fa-arrow-up" }),
                _vm._v("\n        See "),
                _c("soc-section", { attrs: { section: "9.14" } }),
                _vm._v(" for details on statutory limits for TTD.\n      "),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c("div", { staticClass: "calcFieldBox" }, [
            _c(
              "div",
              { staticClass: "formFieldNotation" },
              [
                _c("i", { staticClass: "far fa-arrow-up" }),
                _vm._v("\n        See the "),
                _c("calculator-link", { attrs: { name: "date_wheel" } }),
                _vm._v(
                  " calculator to determine starting and ending dates for payment\n        periods.\n      "
                ),
              ],
              1
            ),
          ]),
        ])
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }