<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="pv-of-pd-and-lp-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />

        <calc-field-box :error="$v.dateOfBirth.$error">
          <calc-label target="date-of-birth" :error="errorMessage('dateOfBirth')">
            Date of Birth:
            <template slot="tooltip">Enter the Injured Worker's Date of Birth.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfBirth" id="date-of-birth"
                         input-class="shortField" @input="$v.dateOfBirth.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.gender.$error">
          <calc-label :error="errorMessage('gender')">
            Gender:
            <template slot="tooltip">
              Select the Injured Worker's gender. (This information is needed for the Life Expectancy lookup.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Male" id="male" @change="$v.gender.$touch()">
              <label for="male" slot="label">Male</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="gender" type="radio" value="Female" id="female" @change="$v.gender.$touch()">
              <label for="female" slot="label">Female</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customRatedAge.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': ratedAgeOption === 'adjust' }">
          <calc-label :error="errorMessage('customRatedAge')">
            Adjustment for "Rated Age"
            <template slot="tooltip">
              To calculate the Injured Worker's life expectancy using a "Rated Age," input the number of years the
              person's life expectancy is anticipated to decrease as a result of illness or injury. See
              <soc-section section="14.83"/>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="none" id="none">
              <label for="none" slot="label">None</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="ratedAgeOption" type="radio" value="adjust" id="adjust">
              <label for="adjust" slot="label">Adjust</label>
              <div class="reveal-if-active" slot="custom">
                <input v-model.number.lazy="customRatedAge" class="conditional-input" type="text"
                       placeholder="Years Reduced" @change="$v.customRatedAge.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabledDates="{ to: dateOfBirth, from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="hasReturnToWorkAdjustment" :error="$v.jobOffered.$error">
          <calc-label :error="errorMessage('jobOffered')">
            Was a Job Offered within 60 Days of P&S Date?:
            <template slot="tooltip">
              Was an offer made to the Injured Worker to return to work by the employer within 60 days after the
              P&S date? (If the employer has fewer than 50 employees, select N/A.) See <soc-section section="11.6"/>.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="yes" id="offered-yes" @change="$v.jobOffered.$touch()">
              <label for="offered-yes" slot="label">Yes</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="no" id="offered-no" @change="$v.jobOffered.$touch()">
              <label for="offered-no" slot="label">No</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="jobOffered" type="radio" value="na" id="offered-na" @change="$v.jobOffered.$touch()">
              <label for="offered-na" slot="label">N/A</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdCommencementDate.$error">
          <calc-label target="pd-commencement-date" :error="errorMessage('pdCommencementDate')">
            PD Commencement Date:
            <template slot="tooltip">Enter the date that Permanent Disability payments began.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="pd-commencement-date"
                         input-class="shortField" @input="$v.pdCommencementDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabledDates="{ to: dateOfInjury }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="hasReturnToWorkAdjustment && jobOffered === 'yes'" :error="$v.rtwDate.$error">
          <calc-label target="rtw-date"
                      :error="errorMessage('rtwDate', { min: 'the PD Commencement Date',
                                                        max: '60 days thereafter (%{max})' })">
            Return to Work:
            <template slot="tooltip">
              What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,
              leave this blank.) See <soc-section section="11.6"/>.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="rtwDate" id="rtw-date" input-class="shortField"
                         :typeable="true" placeholder="mm/dd/yyyy" @input="$v.rtwDate.$touch()"
                         :open-date="pdCommencementDate"
                         :disabled-dates="{ to: pdCommencementDate, from: addDays(pdCommencementDate, 60) }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.pdRating.$error">
          <calc-label target="pd-rating" :error="errorMessage('pdRating')">
            PD Rating:
            <template slot="tooltip">Enter the PD rating as a percentage.</template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="pdRating" type="number" id="pd-rating" min="1" max="99" class="shortField"
                   @change="$v.pdRating.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfCalculation.$error">
          <calc-label target="date-of-calculation"
                      :error="errorMessage('dateOfCalculation', { min: 'PD Commencement Date (%{min})' })">
            Date of Calculation:
            <template slot="tooltip">Enter the date from which you are calculating the present value.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfCalculation" id="date-of-calculation"
                         input-class="shortField" @input="$v.dateOfCalculation.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabledDates="{ to: pdCommencementDate }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select Custom to input the Average Weekly Earnings for the injured worker. If the AWE you enter is outside
              applicable limits, it will be modified accordingly and the updated value displayed in the Results section.
              Or, select Max if you know that the AWE is high enough that the applicable statutory limits would apply.
              Note that different limits apply to Permanent Disability and when applicable, Life Pension. If you are
              unsure whether the actual AWE value exceeds the limits for both, input the actual AWE value instead. (See
              <soc-section section="8.6"/> for more detail on AWE.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max"
                     @change="customAverageWeeklyEarnings = null">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="wageOption === 'max'">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can
            differ. To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.
          </div>
        </calc-field-box>

        <calc-field-box :error="$v.interestRate.$error">
          <calc-label target="interest-rate" :error="errorMessage('interestRate')">
            Annual Discount Rate:
            <template slot="tooltip">
              Input the annual interest rate to use for the present value discount calculation.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="interestRate" type="text" id="interest-rate" class="shortField"
                   @change="$v.interestRate.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.assumedAnnualIncreases.$error">
          <calc-label target="assumed-annual-increases" :error="errorMessage('assumedAnnualIncreases')">
            Assumed COLA Increases:
            <template slot="tooltip">
              Input the average annual increase you expect will apply to COLA calculations.
            </template>
          </calc-label>
          <calc-field>
            <input v-model.number.lazy="assumedAnnualIncreases" type="text" id="assumed-annual-increases"
                   class="shortField" @change="$v.assumedAnnualIncreases.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Calculation Mode:
            <template slot="tooltip">
              Choose the method for calculating the present value of PD payments:
              <ul>
                <li>
                  <b>Reduce for Mortality</b> - reduces the expected payout in each future year to account for the
                  portion of the Injured Worker's cohort that will no longer be alive in that year. This is the
                  actuarily accurate approach and results in a lower calculated present value.
                </li>
                <li>
                  <b>Full Value through Life Expectancy</b> - assumes 100% of all PD and LP payments are received by the
                  Injured Worker in the future up to the date of the life expectancy cut-off. This results in a higher
                  calculated present value.
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="reducePd" type="radio" value="yes" id="reduce-pd">
              <label for="reduce-pd" slot="label">Reduce for Mortality</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="reducePd" type="radio" value="no" id="no-reduce-pd">
              <label for="no-reduce-pd" slot="label">Full Value through Life Expectancy</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>

      <div class="resultContainer">
        <div class="resultLabel">Calculation Mode</div>
        <div class="resultValue" v-if="reducePd === 'yes'">Reduce for Mortality</div>
        <div class="resultValue" v-if="reducePd === 'no'">Full Value through Life Expectancy</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="ratedAgeOption === 'none'">Age on date of calculation</div>
        <div class="resultLabel" v-if="ratedAgeOption === 'adjust'">"Rated Age" on date of calculation</div>
        <div class="resultValue">{{ ageOnDateOfCalculation | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Life Expectancy on date of calculation</div>
        <div class="resultValue">{{ lifeTableLookup | toFixed(2) }}</div>
      </div>

      <template v-if="hasReturnToWorkAdjustment">
        <h3>Return to Work Adjustments</h3>
        <calc-field-box>
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            <rtw-adjustments :date-of-injury="dateOfInjury" :job-offered="jobOffered" />
          </div>
        </calc-field-box>
        <template v-if="rtwModifier">
          <calc-field-box class="comment">
            <div class="formFieldNotation">
              <i class="far fa-arrow-up"></i>
              PD values shown below reflect RTW adjustments.
            </div>
          </calc-field-box>
          <div class="resultContainer">
            <div class="resultLabel">Weeks Paid at Base Rate</div>
            <div class="resultValue">
              {{ rtwCalculations.weeksPaidAtBaseRate.weeks }} Weeks,
              {{ rtwCalculations.weeksPaidAtBaseRate.days | toFixed(0) }} Days
            </div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">RTW Modifier Used</div>
            <div class="resultValue">{{ rtwModifier }}</div>
          </div>
        </template>
      </template>

      <h3>Permanent Disability</h3>
      <div class="resultContainer">
        <div class="resultLabel">Total weeks of Permanent Disability</div>
        <div class="resultValue">{{ totalWeeksOfPd }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Average Weekly Earnings</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.pd | currency }} {{ averageWeeklyEarnings.suffix.pd }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="hasReturnToWorkAdjustment && rtwModifier">PD weekly rate during base period</div>
        <div class="resultLabel" v-else>PD weekly rate</div>
        <div class="resultValue">{{ weeklyPdRate | currency }}</div>
      </div>
      <div class="resultContainer" v-if="hasReturnToWorkAdjustment && rtwModifier">
        <div class="resultLabel">PD weekly rate after RTW adjustment</div>
        <div class="resultValue">{{ rtwCalculations.weeklyPdCompensation | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="hasReturnToWorkAdjustment && rtwModifier">Total all PD</div>
        <div class="resultLabel" v-else>Total PD</div>
        <div class="resultValue">{{ totalPd | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Weeks of PD already paid</div>
        <div class="resultValue">{{ weeksOfPdAlreadyPaid | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">PD benefits already paid</div>
        <div class="resultValue">{{ pdBenefitsAlreadyPaid | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Weeks of PD remaining</div>
        <div class="resultValue">{{ weeksOfPdRemaining | toFixed(2) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Balance of PD due (without PV discount)</div>
        <div class="resultValue">{{ balanceOfPdDueWithoutDiscount | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Present Value of remaining Permanent Disability</div>
        <div class="resultValue">{{ presentValueOfRemainingPd | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference between Balance Due and PV of remaining PD</div>
        <div class="resultValue">{{ differenceBalanceDueAndPV | currency }}</div>
      </div>

      <h3>Life Pension</h3>
      <div class="resultContainer" v-if="averageWeeklyEarnings.limitsApplied && pdRating >= 70">
        <div class="resultLabel">Average Weekly Earnings</div>
        <div class="resultValue">
          {{ averageWeeklyEarnings.awe.lp | currency }} {{ averageWeeklyEarnings.suffix.lp }}
        </div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Date Life Pension benefits start</div>
        <div class="resultValue">{{ americanDateFormatter(lpStartDate) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="isDOCAfterLpStartDate">Life Pension weekly rate at start date</div>
        <div class="resultLabel" v-else>Life Pension weekly rate</div>
        <div class="resultValue">{{ lpRate | currency }}</div>
      </div>
      <template v-if="pdRating >= 70">
        <template v-if="isDOCAfterLpStartDate">
          <div class="resultContainer">
            <div class="resultLabel">Life Pension weekly rate on date of calculation</div>
            <div class="resultValue">{{ lpRateOnDoc | currency }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">Weeks of Life Pension already paid</div>
            <div class="resultValue">{{ weeksOfLpAlreadyPaid | toFixed(2) }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">Life Pension benefits already paid</div>
            <div class="resultValue">{{ lpBenefitsAlreadyPaid | currency }}</div>
          </div>
        </template>
      </template>
      <div class="resultContainer">
        <div class="resultLabel" v-if="isDOCAfterLpStartDate">
          Gross value of expected remaining Life Pension payments with COLA applied
        </div>
        <div class="resultLabel" v-else>Gross value of expected Life Pension payments with COLA applied</div>
        <div class="resultValue">{{ sumOfLifePensionWithCola | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="isDOCAfterLpStartDate">
          Present Value of expected remaining Life Pension payments with COLA applied
        </div>
        <div class="resultLabel" v-else>Present Value of expected Life Pension payments with COLA applied</div>
        <div class="resultValue">{{ pvOfLifePensionWithCola | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference between Gross and Present Values of expected LP payments</div>
        <div class="resultValue">{{ differenceGrossAndPresentValuesExpectedLP | currency }}</div>
      </div>

      <calc-field-box v-if="pdRating < 70">
        <div class="formFieldNotation">
          <i class="far fa-arrow-up"></i>
          PD is under 70%, so this rating does not qualify for a life pension.
        </div>
      </calc-field-box>

      <h3>Total Value Future Payments</h3>
      <div class="resultContainer">
        <div class="resultLabel">Gross value of PD and Life Pension with COLA applied</div>
        <div class="resultValue">{{ grossTotalValue | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Gross total present value of PD and Life Pension with COLA applied</div>
        <div class="resultValue">{{ grossTotalPresentValueWithCola | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Difference between Gross and PV of expected future PD and LP payments</div>
        <div class="resultValue">{{ differenceGrossAndPVexpectedFuturePD | currency }}</div>
      </div>

      <save-button form="pv-of-pd-and-lp-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import AverageWeeklyEarnings from './permanent-disability/average-weekly-earnings';
  import DatePicker from 'vuejs-datepicker';
  import axios from 'axios';
  import {
    format as dateFormat,
    subHours,
    startOfDay,
    isAfter,
    addDays,
    differenceInDays,
    getYear
  } from 'date-fns';
  import { props, provide, filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, minValue, between, integer, decimal } from 'vuelidate/lib/validators';
  import { greaterThan } from './validators';
  import { roundFloat } from '../util/float';
  import PdDates from './permanent-disability/dates';
  import rtwAdjustmentPeriod from './permanent-disability/rtw-adjustment-period';
  import RtwAdjustments from './permanent-disability/rtw-adjustment-text';
  import { yearsBetween } from '../util/dates';
  import RtwCalculations from './permanent-disability/rtw-calculations';
  import { PaymentYear, totalPayments } from './saww_payments';
  import saveMixin from './saveable/pv-of-pd-and-lp';

  const resettableData = () => (
    {
      claimNumber: null,
      worker: { firstName: null, lastName: null },
      dateOfBirth: null,
      dateOfInjury: null,
      jobOffered: null,
      pdCommencementDate: null,
      rtwDate: null,
      dateOfCalculation: null,
      wageOption: 'custom',
      customAverageWeeklyEarnings: null,
      pdRating: null,
      gender: 'Male',
      interestRate: 3.0,
      assumedAnnualIncreases: 3.0,
      reducePd: 'yes',
      ratedAgeOption: 'none',
      customRatedAge: null,
      results: false,
      saveStatus: null,
      saveMessage: null
    }
  );

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    mounted() {
      axios.get('/state_average_weekly_wages').then(({ data }) => {
        this.wages = data.reduce((memo, { year, real_increase }) => {
          memo[year] = parseFloat(real_increase);
          return memo;
        }, {});
      });
    },
    data() {
      return Object.assign({
        wages: {}
      }, resettableData(), this.form);
    },
    asyncComputed: {
      lifeTableLookup: {
        get() {
          const params = { age: Math.min(this.ageOnDateOfCalculation, 100), gender: this.gender };
          return axios.get('/life_tables/interpolate', { params }).then(({ data: { value }}) => parseFloat(value));
        },
        shouldUpdate() {
          return !(this.$v.dateOfBirth.$invalid || this.$v.dateOfCalculation.$invalid);
        },
        default: 0
      },
      totalWeeksOfPd: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/pdd_durations/lookup', { params }).then(r => parseFloat(r.data));
        },
        shouldUpdate() {
          return !(this.$v.pdRating.$invalid || this.$v.dateOfInjury.$invalid);
        },
        default: 0
      },
      ppdEarningsLimit: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/ppd_earnings_limits/lookup', { params })
            .then(({ data: { min, max } }) => ({ min: parseFloat(min), max: parseFloat(max) }));
        },
        shouldUpdate() {
          return !(this.$v.pdRating.$invalid || this.$v.dateOfInjury.$invalid)
        },
        default: { min: 0, max: Infinity }
      },
      lifePensionEarningMax: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/life_pension_limits/lookup', { params })
            .then(({ data: { value }}) => ({ value: parseFloat(value) }));
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { value: 0 }
      },
      futureValues: {
        get() {
          const params = {
            age: Math.min(this.ageOnDateOfCalculation, 100),
            gender: this.gender,
            pd_rate: this.weeklyPdRate,
            lp_rate: roundFloat(this.effectiveLpRate, 2),
            years_of_remaining_pd: this.yearsOfRemainingPd,
            interest_rate: this.interestRate / 100.0,
            assumed_annual_increase: this.assumedAnnualIncreases / 100.0,
            years_to_escalation: this.yearsBetweenDateOfCalculationAndEscalationStartDate,
            escalation_days: this.daysOfEscalationInFirstYear
          };
          return axios.get('/pv_of_pd_and_life_pensions/future_values', { params }).then(r => r.data);
        },
        shouldUpdate() {
          return !this.$v.$invalid;
        },
        default: { pd_benefits: {}, reduced_pd_benefits: {}, reduced_lp_benefits: {} }
      }
    },
    computed: {
      maxSawwYear() {
        return Math.max(...Object.keys(this.wages).map(year => parseInt(year, 10)));
      },
      hasReturnToWorkAdjustment() {
        return rtwAdjustmentPeriod(this.dateOfInjury) === 'during';
      },
      rtwCalculations() {
        return new RtwCalculations({ ...this.$data, endOfPdDate: this.endOfPdDate, weeklyPdRate: this.weeklyPdRate });
      },
      rtwModifier() {
        return this.rtwCalculations.modifier;
      },
      isReducedPd() {
        return this.reducePd === 'yes';
      },
      adjustedDOB() {
        const factor = { none: 0, adjust: this.customRatedAge }[this.ratedAgeOption];
        return startOfDay(subHours(this.dateOfBirth, factor * 365.24 * 24));
      },
      ageOnDateOfCalculation() {
        return differenceInDays(this.dateOfCalculation, this.adjustedDOB) / 365.24;
      },
      pdDates() {
        return new PdDates(this.pdCommencementDate, this.totalWeeksOfPd);
      },
      totalDaysOfPd() {
        return this.pdDates.totalDaysOfPd;
      },
      endOfPdDate() {
        return this.pdDates.endOfPdDate;
      },
      lpStartDate() {
        return this.pdDates.lpStartDate;
      },
      isPdTimeRemaining() {
        return isAfter(this.endOfPdDate, this.dateOfCalculation);
      },
      isDOCAfterLpStartDate() {
        return isAfter(this.dateOfCalculation, this.lpStartDate);
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          this.pdRating, this.wageOption, this.customAverageWeeklyEarnings, this.ppdEarningsLimit,
          this.lifePensionEarningMax
        )
      },
      weeklyPdRate() {
        return this.averageWeeklyEarnings.pdRate;
      },
      lpRate() {
        return this.pdRating >= 70 ? Math.max(roundFloat(this.averageWeeklyEarnings.lpRate, 2), 0) : 0;
      },
      lpRateOnDoc() {
        const year = getYear(this.dateOfCalculation);
        const paymentYear = new PaymentYear(this.wages, year, this.lpRate, this.lpStartDate, this.dateOfCalculation);
        return paymentYear.rate;
      },
      effectiveLpRate() {
        return this.isDOCAfterLpStartDate ? this.lpRateOnDoc : this.lpRate;
      },
      weeksOfPdAlreadyPaid() {
        return this.isPdTimeRemaining ?
          (differenceInDays(this.dateOfCalculation, this.pdCommencementDate) + 1) / 7.0 : this.totalWeeksOfPd;
      },
      weeksOfPdRemaining() {
        return this.totalWeeksOfPd - this.weeksOfPdAlreadyPaid;
      },
      weeksOfLpAlreadyPaid() {
        return differenceInDays(this.dateOfCalculation, this.lpStartDate) / 7.0;
      },
      pdBenefitsAlreadyPaid() {
        if (this.hasReturnToWorkAdjustment && this.rtwModifier) {
          return this.rtwCalculations.pdBenefitsAlreadyPaid;
        } else {
          return this.weeklyPdRate * this.weeksOfPdAlreadyPaid
        }
      },
      totalPd() {
        if (this.hasReturnToWorkAdjustment && this.rtwModifier) {
          return this.rtwCalculations.totalPd;
        } else {
          return this.totalWeeksOfPd * this.weeklyPdRate;
        }
      },
      lpBenefitsAlreadyPaid() {
        return totalPayments(this.wages, this.lpRate, this.lpStartDate, this.dateOfCalculation);
      },
      escalationStartDate() {
        return new Date(this.lpStartDate.getFullYear() + 1, 0, 1)
      },
      yearsBetweenDateOfCalculationAndEscalationStartDate() {
        return yearsBetween(this.dateOfCalculation, this.escalationStartDate);
      },
      daysOfEscalationInFirstYear() {
        return differenceInDays(
          new Date(
            this.escalationStartDate.getFullYear(),
            this.dateOfCalculation.getMonth(),
            this.dateOfCalculation.getDate()
          ), this.escalationStartDate
        );
      },
      yearsOfRemainingPd() {
        return this.weeksOfPdRemaining / (365.24 / 7);
      },
      balanceOfPdDueWithoutDiscount() {
        if (this.hasReturnToWorkAdjustment && this.rtwModifier) {
          return this.rtwCalculations.balanceOfPdDueWithoutDiscount;
        } else {
          return this.futureValues['pd_benefits']['sum'];
        }
      },
      pvOfPermanentDisability() {
        const key = this.isReducedPd ? 'reduced_pd_benefits' : 'pd_benefits';
        return this.futureValues[key]['future_value'];
      },
      presentValueOfRemainingPd() {
        if (this.hasReturnToWorkAdjustment && this.rtwModifier) {
          return this.rtwCalculations.presentValueOfRemainingPd(this.pvOfPermanentDisability, this.interestRate);
        } else {
          return this.pvOfPermanentDisability;
        }
      },
      pvOfLifePensionWithCola() {
        const key = this.isReducedPd ? 'reduced_lp_benefits' : 'lp_benefits';
        return this.futureValues[key]['future_value'];
      },
      sumOfLifePensionWithCola() {
        const key = this.isReducedPd ? 'reduced_lp_benefits' : 'lp_benefits';
        return this.futureValues[key]['sum'];
      },
      grossTotalValue() {
        return this.balanceOfPdDueWithoutDiscount + this.sumOfLifePensionWithCola;
      },
      grossTotalPresentValueWithCola() {
        return this.presentValueOfRemainingPd + this.pvOfLifePensionWithCola;
      },
      differenceGrossAndPVexpectedFuturePD() {
        return this.grossTotalValue - this.grossTotalPresentValueWithCola;
      },
      differenceBalanceDueAndPV() {
        return this.balanceOfPdDueWithoutDiscount - this.presentValueOfRemainingPd;
      },
      differenceGrossAndPresentValuesExpectedLP() {
        return this.sumOfLifePensionWithCola - this.pvOfLifePensionWithCola;
      }
    },
    methods: {
      addDays,
      clearForm() {
        Object.assign(this.$data, resettableData());
        this.$v.$reset();
      }
    },
    validations: {
      dateOfBirth: { required, minValue: minValue(new Date(1900, 0, 1)) },
      dateOfInjury: {
        required,
        minValue: minValue(new Date(2003, 0, 1)),
        maxValue: maxValue(methods.methods.endOfYear())
      },
      jobOffered: { required(value) { return this.hasReturnToWorkAdjustment ? required(value) : true } },
      gender: { required },
      pdCommencementDate: { required, minValue(value) { return minValue(this.dateOfInjury)(value) } },
      rtwDate: {
        between(value) { return between(this.pdCommencementDate, addDays(this.pdCommencementDate, 60))(value) }
      },
      dateOfCalculation: {
        required,
        minValue(value) { return minValue(this.pdCommencementDate)(value); },
        maxValue(value) { return maxValue(new Date(this.maxSawwYear, 11, 31))(value) }
      },
      pdRating: { required, integer, between: between(1, 99) },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value) : true },
        minValue: minValue(0)
      },
      interestRate: { required, minValue: minValue(0), decimal },
      assumedAnnualIncreases: { required },
      customRatedAge: {
        required(value) { return this.ratedAgeOption === 'adjust' ? required(value) : true },
        greaterThan(value) { return this.ratedAgeOption === 'none' ? true : greaterThan(0)(value) }
      }
    },
    components: { DatePicker, RtwAdjustments }
  }
</script>
