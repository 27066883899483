import View from './group-view';
import Fold from './group-fold';

export default {
  functional: true,
  props: {
    group: {
      type: Object,
      required: true
    }
  },
  render(createElement, context) {
    const view = createElement(View, { props: context.props });
    const fold = createElement(Fold, { props: context.props });

    return [view, fold];
  }
}
