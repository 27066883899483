<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="awards-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />

        <calc-field-box :error="$v.awardDate.$error">
          <calc-label target="award-date" :error="errorMessage('awardDate')">
            Date of Award:
            <template slot="tooltip">The date the award was issued.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="awardDate" id="award-date" input-class="shortField"
                         @input="$v.awardDate.$touch()" :typeable="true" placeholder="mm/dd/yy"
                         :disabledDates="{ from: new Date() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.paymentDate.$error">
          <calc-label target="payment-date" :error="errorMessage('paymentDate')">
            Date of Payment:
            <template slot="tooltip">Enter the date payment will be sent.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="paymentDate" id="payment-date"
                         input-class="shortField" @input="$v.paymentDate.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabledDates="{ to: awardDate }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.awardAmount.$error">
          <calc-label target="amount-of-award" :error="errorMessage('awardAmount')">
            Amount of Award:
            <template slot="tooltip">Enter the amount of the award.</template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="awardAmount" type="text" id="amount-of-award" class="shortField"
                   @change="$v.awardAmount.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.customInterestRate.$error">
          <calc-label :error="errorMessage('customInterestRate')">
            Annual Interest Rate:
            <template slot="tooltip">
              Select the interest rate to be used in calculating the dollar amount of interest owed on an award.
              <ul>
                <li>Select 7% if the employer is a local public entity.</li>
                <li>Select 10% for all other employers.</li>
              </ul>
              See <soc-section section="16.43"></soc-section> for more detail.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="interestRateOption" type="radio" value="seven" id="rate-seven"
                     @change="customInterestRate = null">
              <label for="rate-seven" slot="label">7%</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="interestRateOption" type="radio" value="ten" id="rate-ten"
                     @change="customInterestRate = null">
              <label for="rate-ten" slot="label">10%</label>
            </pretty-radio>
            <pretty-radio el-style="margin-right: 0">
              <input v-model="interestRateOption" type="radio" value="custom" id="rate-custom">
              <label for="rate-custom" slot="label">&nbsp;</label>
            </pretty-radio>
            <input v-model.number.lazy="customInterestRate" id="custom-interest-rate" type="text"
                   class="shortField" placeholder="Custom" :disabled="presetMode"
                   @change="$v.customInterestRate.$touch()">
            <span>&percnt;</span>
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="resultContainer">
        <div class="resultLabel">Number of days between award and payment:</div>
        <div class="resultValue">{{ daysBetweenAwardAndPayment.toLocaleString() }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Award Amount:</div>
        <div class="resultValue">{{ awardAmount | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Interest Amount:</div>
        <div class="resultValue">{{ interest | currency }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">Total Payment:</div>
        <div class="resultValue">{{ total | currency }}</div>
      </div>

      <save-button form="awards-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import { differenceInDays } from 'date-fns';
  import { props, provide, filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, maxValue, minValue } from 'vuelidate/lib/validators';
  import saveMixin from './saveable/awards';

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    data() {
      return Object.assign({}, {
        claimNumber: null,
        worker: { firstName: null, lastName: null },
        awardDate: null,
        paymentDate: null,
        awardAmount: null,
        interestRateOption: 'ten',
        customInterestRate: null,
        results: false,
        saveStatus: null,
        saveMessage: null
      }, this.form);
    },
    computed: {
      presetMode() {
        return ['seven', 'ten'].includes(this.interestRateOption);
      },
      interestRate() {
        return { 'seven': 7, 'ten': 10, 'custom': this.customInterestRate }[this.interestRateOption];
      },
      daysBetweenAwardAndPayment() {
        return differenceInDays(this.paymentDate, this.awardDate);
      },
      interest() {
        return this.interestRate * this.daysBetweenAwardAndPayment * this.awardAmount / 36524;
      },
      total() {
        return this.interest + this.awardAmount;
      }
    },
    validations: {
      awardDate: { required, maxValue(value) { return maxValue(new Date())(value) } },
      paymentDate: { required, minValue(value) { return minValue(this.awardDate)(value) } },
      awardAmount: { required },
      customInterestRate: { required(value) { return this.presetMode ? true : required(value) } }
    },
    components: { DatePicker }
  }
</script>
