<template>

  <div>
    <section id="calculator-form">
      <form id="pd-form" @submit.prevent>
        <h2>Report Information</h2>

        <claim-number v-model="claimNumber" @data="populateForm" />

        <calc-field-box>
          <calc-label>
            Name of Doctor:
            <template slot="tooltip">Enter the name of the doctor whose report you rating.</template>
          </calc-label>
          <calc-field>
            <input v-model="doctor.firstName" type="text" id="doctor-first-name" placeholder="First"
                   style="width: 45%; float: left">
            <input v-model="doctor.lastName" type="text" id="doctor-last-name" placeholder="Last"
                   style="width: 45%; float: right">
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Date of Doctor's Report:
            <template slot="tooltip">Enter the date of the report that you are rating.</template>
          </calc-label>
          <calc-field>
            <date-picker v-model="reportDate" :format="americanDateFormatter" id="date-of-report"
                         input-class="shortField" :typeable="true" placeholder="mm/dd/yy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <h2>Employee Information</h2>
        <injured-worker v-model="worker" />

        <calc-field-box :error="$v.dateOfBirth.$error">
          <calc-label target="date-of-birth" :error="errorMessage('dateOfBirth')">
            Date of Birth:
            <template slot="tooltip">Enter the Injured Worker's Date of Birth.</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfBirth" id="date-of-birth"
                         input-class="shortField" @input="$v.dateOfBirth.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy" :disabled-dates="{ to: new Date(1996, 0, 1), from: endOfYear() }">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <div class="calcFieldBox comment">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            For Continuous Trauma claims, enter the ending date of the CT period or the date determined in accordance
            with LC 5412 (see <soc-section section="5.5"/>).
          </div>
        </div>

        <div v-if="dateOfInjury" class="descriptionTextArea" style="margin: 10px 0 20px 0">
          This rating is calculated using the {{ scheduleYear }} Permanent Disability Rating Schedule
        </div>

        <calc-field-box :error="$v.occupationGroup.$error">
          <calc-label :error="errorMessage('occupationGroup')">
            Occupation
            <template slot="tooltip">Begin typing and then select the group number or occupation by name.</template>
          </calc-label>
          <calc-field container-class="v-select">
            <v-select v-model="occupationGroup" :options="occupationCodes" :loading="occupationCodes.length === 0"
                      :filterBy="loosenedSearchFilter" :clearable="false"
                      placeholder="Search Group Numbers or Occupation Names">
            </v-select>
          </calc-field>
        </calc-field-box>

        <h2>Earnings Information</h2>
        <calc-field-box :error="$v.customAverageWeeklyEarnings.$error"
                        :container-class="{ 'expanding-radio': true, 'expanding-radio-margin': wageOption === 'custom' }">
          <calc-label :error="errorMessage('customAverageWeeklyEarnings')">
            Average Weekly Earnings:
            <template slot="tooltip">
              Select <b>Custom</b> to input the Average Weekly Earnings for the injured worker. If the AWE you enter is
              outside applicable limits, it will be modified accordingly and the updated value displayed in the Results
              section. Or, select <b>Max</b> if you know that the AWE is high enough that the applicable statutory
              limits would apply. Note that different limits apply to Permanent Disability and when applicable,
              Life Pension. If you are unsure whether the actual AWE value exceeds the limits for both, input the actual
              AWE value instead. (See <soc-section section="8.6"/> for more detail on AWE.)
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="max" id="awe-max">
              <label for="awe-max" slot="label">Max</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model="wageOption" type="radio" value="custom" id="awe-custom">
              <label for="awe-custom" slot="label">Custom</label>
              <div class="reveal-if-active" slot="custom">
                <abbr>&dollar;</abbr>
                <input v-model.number.lazy="customAverageWeeklyEarnings" class="conditional-input" type="text"
                       placeholder="Enter Amount" @change="$v.customAverageWeeklyEarnings.$touch()">
              </div>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="wageOption === 'max'">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Caution: The statutory maximum earnings for the purposes of Permanent Disability and Life Pension can
            differ. To ensure accurate calculations, it is best to input the actual Average Weekly Earnings.
          </div>
        </calc-field-box>

        <template v-if="!$v.dateOfInjury.$invalid && adjustmentPeriod === 'during'">
          <h2>Return to Work Adjustments</h2>
          <calc-field-box>
            <calc-label>
              Was a Job Offered within 60 Days of P&S Date?:
              <template slot="tooltip">
                Was an offer made to the Injured Worker to return to work by the employer within 60 days after the P&S
                date? (If the employer has fewer than 50 employees, select N/A.) See <soc-section section="11.6"/>.
              </template>
            </calc-label>
            <calc-field>
              <pretty-radio>
                <input v-model="jobOffered" type="radio" value="yes" id="job-offered-yes">
                <label for="job-offered-yes" slot="label">Yes</label>
              </pretty-radio>
              <pretty-radio>
                <input v-model="jobOffered" type="radio" value="no" id="job-offered-no">
                <label for="job-offered-no" slot="label">No</label>
              </pretty-radio>
              <pretty-radio>
                <input v-model="jobOffered" type="radio" value="na" id="job-offered-na">
                <label for="job-offered-na" slot="label">N/A</label>
              </pretty-radio>
            </calc-field>
          </calc-field-box>

          <template v-if="jobOffered === 'yes'">
            <calc-field-box :error="$v.pdCommencementDate.$error">
              <calc-label target="ps-date" :error="errorMessage('pdCommencementDate', { min: 'the Date of Injury (%{min})' })">
                Permanent and Stationary Date:
                <template slot="tooltip">
                  What was the date the Injured Worker became Permanent & Stationary? (If you don't know, leave this
                  blank.) See <soc-section section="10.3"/>.
                </template>
              </calc-label>
              <calc-field>
                <date-picker :format="americanDateFormatter" v-model="pdCommencementDate" id="ps-date" input-class="shortField"
                             :typeable="true" placeholder="mm/dd/yyyy" :open-date="dateOfInjury"
                             :disabled-dates="{ to: dateOfInjury }"
                             @input="$v.pdCommencementDate.$touch()">
                </date-picker>
              </calc-field>
            </calc-field-box>

            <calc-field-box v-if="pdCommencementDate" :error="$v.rtwDate.$error">
              <calc-label target="rtw-date"
                          :error="errorMessage('rtwDate', { min: 'the Permanent and Stationary Date',
                                                            max: '60 days thereafter (%{max})' })">
                Return to Work:
                <template slot="tooltip">
                  What was the date that the Return to Work offer was made to the Injured Worker? (If you don't know,
                  leave this blank.) See <soc-section section="11.6"/>.
                </template>
              </calc-label>
              <calc-field>
                <date-picker :format="americanDateFormatter" v-model="rtwDate" id="rtw-date" input-class="shortField"
                             :typeable="true" placeholder="mm/dd/yyyy" :open-date="pdCommencementDate"
                             :disabled-dates="{ to: pdCommencementDate, from: addDays(pdCommencementDate, 60) }" @input="$v.rtwDate.$touch()">
                </date-picker>
              </calc-field>
            </calc-field-box>
          </template>
        </template>

        <div class="saved-string" v-for="(impairment, index) in impairments" :key="index">
          <template v-if="!($v.impairmentDeps.$invalid || $v.impairments.$each[index].$invalid)">
            <h3 style="display: block;">{{ impairmentRatings[index].ratingString }}</h3>
            <h3>{{ impairmentRatings[index].title }}</h3>
            <div class="show-hide-container" style="text-align: right">
              <p class="reveal" v-if="!impairment.collapsed" @click="toggle(impairment)">Hide ▲</p>
              <p class="reveal" v-if="impairment.collapsed" @click="toggle(impairment)">Show ▼</p>
            </div>
          </template>
          <div v-if="!impairment.collapsed">
            <h2>Impairment Information</h2>
            <calc-field-box :error="$v.impairments.$each[index].method.$error">
              <calc-label :error="errorMessage(`impairments.$each.${index}.method`)">
                Method
                <template slot="tooltip">
                  Begin typing and select the Impairment Number that matches the Injured Worker's body part from the
                  report you are rating. See <soc-section section="10.15"/>.
                </template>
              </calc-label>
              <calc-field container-class="v-select select-method">
                <v-select v-model="impairment.method" :options="availableImpairmentOptions()"
                          :clearable="false" :loading="impairmentOptions.length === 0"
                          :filterBy="loosenedSearchFilter"
                          @input="$v.impairments.$each[index].method.$touch()"
                          placeholder="Enter Method Number or Search Terms">
                </v-select>
              </calc-field>
            </calc-field-box>

            <calc-field-box v-if="[16, 17].includes(get(impairment, 'method.chapter'))"
                            :error="$v.impairments.$each[index].sideOfBody.$error">
              <calc-label :error="errorMessage(`impairments.$each.${index}.sideOfBody`)">
                Side of Body
                <template slot="tooltip">Select side of body</template>
              </calc-label>
              <calc-field container-class="v-select shortField">
                <v-select v-model="impairment.sideOfBody" :options="availableSidesOfBody(impairment)"
                          :clearable="false" @input="$v.impairments.$each[index].sideOfBody.$touch()">
                </v-select>
              </calc-field>
            </calc-field-box>

            <template v-if="specialCaseOptions.hasOwnProperty(get(impairment, 'method.value'))">
              <div class="descriptionTextArea" style="margin-top: 0" v-if="!impairment.specialCase">
                This impairment affects several other impairments. Select the option that most closely applies.
              </div>

              <calc-field-box :error="$v.impairments.$each[index].specialCase.$error">
                <calc-label>&nbsp;</calc-label>
                <calc-field container-class="v-select">
                  <v-select v-model="impairment.specialCase" :options="specialCaseOptions[impairment.method.value]"
                            @input="$v.impairments.$each[index].specialCase.$touch()">
                  </v-select>
                </calc-field>
              </calc-field-box>
            </template>

            <template v-if="get(impairment, 'method.value') === '11.01.01.00'">
              <calc-field-box>
                <calc-label>
                  Scale:
                  <template slot="tooltip">
                    Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose
                    either <b>Binaural Hearing Impairment</b> or <b>WPI (Whole Person Impairment)</b> based on the
                    scale identified in the report you are rating.
                  </template>
                </calc-label>
                <calc-field>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="bhi" id="wpi-bhi"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-bhi" slot="label">Binaural Hearing Impairment</label>
                  </pretty-radio>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="wpi" id="wpi-wpi"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-wpi" slot="label">WPI</label>
                  </pretty-radio>
                </calc-field>
              </calc-field-box>

              <calc-field-box v-if="impairment.wpiOption === 'bhi'" :error="$v.impairments.$each[index].bhi.$error">
                <calc-label :error="errorMessage(`impairments.$each.${index}.bhi`)">
                  Binaural Hearing Impairment
                  <template slot="tooltip">

                  </template>
                </calc-label>
                <calc-field>
                  <input type="number" v-model.number="impairment.bhi" class="shortField"
                         @input="bhiOrVsiChanged($event, index)">
                </calc-field>
              </calc-field-box>

              <div class="calcFieldBox" v-if="impairment.wpi === 0">
                <div class="formFieldNotation">
                  <i class="far fa-arrow-up"></i>
                  This Binaural Hearing Impairment value does not result in any Whole Person Impairment.
                </div>
              </div>
            </template>

            <template v-if="get(impairment, 'method.value') === '11.03.04.00'">
              <calc-field-box>
                <calc-label>
                  Scale:
                  <template slot="tooltip">
                    Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose
                    either <b>Voice/Speech Impairment</b> or <b>WPI (Whole Person Impairment)</b> based on the scale
                    identified in the report you are rating.
                  </template>
                </calc-label>
                <calc-field>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="vsi" id="wpi-vsi"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-vsi" slot="label">Voice/Speech Impairment</label>
                  </pretty-radio>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="wpi" id="wpi-wpi"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-wpi" slot="label">WPI</label>
                  </pretty-radio>
                </calc-field>
              </calc-field-box>

              <calc-field-box v-if="impairment.wpiOption === 'vsi'" :error="$v.impairments.$each[index].vsi.$error">
                <calc-label :error="errorMessage(`impairments.$each.${index}.vsi`)">
                  Voice/Speech Impairment
                  <template slot="tooltip">

                  </template>
                </calc-label>
                <calc-field>
                  <input type="number" v-model.number="impairment.vsi" class="shortField"
                         @input="bhiOrVsiChanged($event, index)">
                </calc-field>
              </calc-field-box>

              <div class="calcFieldBox" v-if="impairment.wpi === 0">
                <div class="formFieldNotation">
                  <i class="far fa-arrow-up"></i>
                  This Voice/Speech Impairment value does not result in any Whole Person Impairment.
                </div>
              </div>
            </template>

            <template v-if="get(impairment, 'method.value') === '14.01.00.00' && dateOfInjury >= new Date(2005, 0, 1)">
              <calc-field-box>
                <calc-label>
                  Scale:
                  <template slot="tooltip">
                    Select the scoring method used by the doctor to quantify the injured worker's impairment. Chose
                    either <b>GAF (Global Assessment of Functioning)</b> or <b>WPI (Whole Person Impairment)</b> based
                    on the scale identified in the report you are rating. See <soc-section section="10.15"/>.
                  </template>
                </calc-label>
                <calc-field>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="gaf" id="wpi-gaf"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-gaf" slot="label">GAF</label>
                  </pretty-radio>
                  <pretty-radio>
                    <input v-model="impairment.wpiOption" type="radio" value="wpi" id="wpi-wpi"
                           @change="wpiOptionChanged(index)">
                    <label for="wpi-wpi" slot="label">WPI</label>
                  </pretty-radio>
                </calc-field>
              </calc-field-box>

              <calc-field-box v-if="impairment.wpiOption === 'gaf'" :error="$v.impairments.$each[index].gaf.$error">
                <calc-label :error="errorMessage(`impairments.$each.${index}.gaf`)">
                  Global Assessment of Functioning (GAF)
                  <template slot="tooltip">
                    Locate the Global Assessment of Functioning (GAF) score in the report you are rating.
                    See <soc-section section="10.15"/>.
                  </template>
                </calc-label>
                <calc-field>
                  <input type="number" v-model.number="impairment.gaf" class="shortField"
                         @input="gafChanged($event, index)">
                </calc-field>
              </calc-field-box>

              <div class="calcFieldBox" v-if="impairment.wpi === 0">
                <div class="formFieldNotation">
                  <i class="far fa-arrow-up"></i>
                  This GAF score does not result in any psychiatric impairment.
                </div>
              </div>
            </template>

            <calc-field-box :error="$v.impairments.$each[index].wpi.$error">
              <calc-label :error="errorMessage(`impairments.$each.${index}.wpi`)">
                Whole Person Impairment (WPI)
                <template slot="tooltip">
                  Locate the Whole Person Impairment (WPI) in the report you are rating. See
                  <soc-section section="10.15"/>.
                </template>
              </calc-label>
              <calc-field>
                <input type="number" v-model.lazy.number="impairment.wpi" class="shortField"
                       :readonly="WPIReadOnly(impairment)" @change="$asyncComputed.totalWeeksOfPd.update() && $v.impairments.$each[index].wpi.$touch()">
                <span>&percnt;</span>
              </calc-field>
            </calc-field-box>

            <calc-field-box v-if="get(impairment, 'method.value') !== '14.01.00.00'"
                            :error="$v.impairments.$each[index].painAddon.$error">
              <calc-label :error="errorMessage(`impairments.$each.${index}.painAddon`)">
                Add-on for Pain
                <template slot="tooltip">
                  Locate the Whole Person Impairment (WPI) in the report you are rating. If the doctor has included an
                  add-on for pain, enter it in this field. See <soc-section section="10.15"/>.
                </template>
              </calc-label>
              <calc-field>
                <input type="number" v-model.lazy.number="impairment.painAddon" class="shortField"
                       @change="painAddonChanged(index)">
                <span>&percnt;</span>
              </calc-field>
            </calc-field-box>

            <calc-field-box :error="$v.impairments.$each[index].industrialCausation.$error">
              <calc-label :error="errorMessage(`impairments.$each.${index}.industrialCausation`)">
                Percent Industrial Causation
                <template slot="tooltip">
                  If apportionment applies, set this value to indicate the percentage that remains industrial. So, if no
                  apportionment applies, set this value to 100% because it is fully industrial. See
                  <soc-section section="10.31"/> through <soc-section section="10.37"/>.
                </template>
              </calc-label>
              <calc-field>
                <input type="number" v-model.lazy.number="impairment.industrialCausation" class="shortField"
                       @change="$asyncComputed.totalWeeksOfPd.update() && $v.impairments.$each[index].industrialCausation.$touch()">
                <span>&percnt;</span>
              </calc-field>
            </calc-field-box>
            <div class="descriptionTextArea rating-string"
                 v-if="!($v.impairmentDeps.$invalid || $v.impairments.$each[index].$invalid)">
              {{ ratingStrings[index] }}
              <i class="copy far fa-copy" @click="copyRatingString(index)"></i>
              <transition name="fade">
                <span class="success-text" v-if="impairment.copied">Copied!</span>
              </transition>
            </div>
            <button class="button clear-button fitted" @click="removeImpairment(index)" type="button">
              Remove Impairment
            </button>
          </div>
        </div>

        <div class="buttonContainer add-impairment">
          <button type="button" class="button clear-button fitted" :class="{ disabled: !impairmentsValid }"
                  v-on="impairmentsValid ? { click: addImpairment } : null">
            Add Impairment
          </button>
        </div>

        <calculate-button @click="calculatePd()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <div class="descriptionTextArea rating-summary" v-if="impairments.length > 1 || isSpecialGafResult">
        <div class="title">Rating Summary</div>
        <template v-for="combinedRatingGroup in sortedRatingGroups">
          <template v-for="impairmentRating in combinedRatingGroup.impairmentRatings">
            <div class="combined-rating">
              <div>{{ impairmentRating.ratingString }}</div>
              <div>{{ impairmentRating.title }}</div>
            </div>
          </template>
        </template>
        <div class="combined-rating">
          <template v-for="combinedRatingGroup in sortedRatingGroups">
            <div v-if="combinedRatingGroup.impairmentRatings.length > 1">
              {{ combinedRatingGroup.ratingString }}
            </div>
          </template>
          <div>{{ combinedRatingGroupsSummary }}</div>
        </div>
        <i class="copy far fa-copy" @click="copyRatingSummary"></i>
        <transition name="fade">
          <span class="success-text" v-if="summaryCopied">Copied!</span>
        </transition>
      </div>
      <div class="resultContainer">
        <div class="resultLabel" v-if="impairments.length > 1">Combined Rating</div>
        <div class="resultLabel" v-else>Final Rating</div>
        <div class="resultValue">{{ pdRating | toFixed(0) }}%</div>
      </div>
      <template v-if="isSpecialGafResult">
        <div class="calcFieldBox">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            This GAF score does not result in any psychiatric impairment.
          </div>
        </div>
      </template>
      <template v-else>
        <div class="resultContainer">
          <div class="resultLabel">Total of All Add-ons for Pain</div>
          <div class="resultValue">{{ painAddonTotal }}%</div>
        </div>
        <calc-field-box v-if="painAddonTotal > 3">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            This result exceeds the maximum allowable add-on for pain, which is 3%. See <soc-section section="10.15"/>.
          </div>
        </calc-field-box>
        <div class="resultContainer" v-if="pdRating < 100">
          <div class="resultLabel">Total Weeks of PD</div>
          <div class="resultValue">{{ totalWeeksOfPd | toFixed(2) }}</div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Age on DOI</div>
          <div class="resultValue">{{ age }}</div>
        </div>
        <div class="calcFieldBox comment" v-if="age > 100">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            Ages over 100 are rated at 100 to match the assumption used by the Age Adjustment table in the PD Rating
            Schedule.
          </div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Average Weekly Earnings</div>
          <div class="resultValue">
            {{ averageWeeklyEarnings.awe.pd | currency }} {{ averageWeeklyEarnings.suffix.pd }}
          </div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel" v-if="pdRating === 100">PTD Weekly Rate:</div>
          <div class="resultLabel" v-else>PD Weekly Rate:</div>
          <div class="resultValue">{{ weeklyPdRate | currency }}</div>
        </div>

        <div class="calcFieldBox comment" v-if="pdRating === 100">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            To determine the present value of PTD payments owed, see the
            <calculator-link name="pv_of_ptd" :claim-number="claimNumber"/> calculator. To calculate a commutation of
            Permanent Total Disability see the <calculator-link name="commutation_ptd" :claim-number="claimNumber"/>
            calculator.
          </div>
        </div>

        <template v-if="pdRating < 100">
          <div class="resultContainer">
            <div class="resultLabel">Total PD Payout</div>
            <div class="resultValue">{{ totalPdPayout | currency }}</div>
          </div>
          <p class="sub-header">Return to Work Adjustments</p>
          <calc-field-box>
            <div class="formFieldNotation">
              <rtw-adjustments :job-offered="jobOffered" :date-of-injury="dateOfInjury" :pd-rating="pdRating"/>
            </div>
          </calc-field-box>
        </template>

        <template v-if="hasRtwAdjustmentPeriod && rtwModifier && pdRating != 0">
          <div class="resultContainer">
            <div class="resultLabel">Weeks Paid at Base Rate</div>
            <div class="resultValue">
              {{ rtwWeeksPaidAtBaseRate.weeks }} weeks, {{ rtwWeeksPaidAtBaseRate.days }} days
            </div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">PD Paid at Base Rate</div>
            <div class="resultValue">{{ rtwPdPaidAtBaseRate | currency }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">RTW Modifier Used</div>
            <div class="resultValue">{{ rtwModifier }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">Weekly PD Compensation (after Adjustment)</div>
            <div class="resultValue">{{ rtwWeeklyPdCompensation | currency }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">Weeks of PD Remaining (after Adjustment)</div>
            <div class="resultValue">{{ rtwWeeksOfPdRemaining | toFixed(2) }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">PD Indemnity Remaining (after Adjustment)</div>
            <div class="resultValue">{{ rtwPdIndemnityRemaining | currency }}</div>
          </div>
          <div class="resultContainer">
            <div class="resultLabel">Total PD Payout (after Adjustment)</div>
            <div class="resultValue">{{ rtwTotalPdPayout | currency }}</div>
          </div>
        </template>
        <div class="resultContainer" v-if="averageWeeklyEarnings.limitsApplied && isQualifiedForLp">
          <div class="resultLabel">Average Weekly Earnings</div>
          <div class="resultValue">
            {{ averageWeeklyEarnings.awe.lp | currency }} {{ averageWeeklyEarnings.suffix.lp }}
          </div>
        </div>
        <div class="resultContainer">
          <div class="resultLabel">Life Pension Weekly Rate</div>
          <div class="resultValue" v-if="isQualifiedForLp">
            {{ averageWeeklyEarnings.lpRate | currency }}
          </div>
          <div class="resultValue" v-else>{{ 0 | currency }}</div>
        </div>
        <calc-field-box v-if="pdRating < 70">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            PD is under 70%, so this rating does not qualify for a life pension.
          </div>
        </calc-field-box>
        <calc-field-box v-if="pdRating === 100">
          <div class="formFieldNotation">
            <i class="far fa-arrow-up"></i>
            PD is 100%, so this rating does not qualify for a life pension.
          </div>
        </calc-field-box>
        <result-calculator-messages :pd-rating="pdRating" :claim-number="claimNumber"/>
      </template>

      <save-button form="pd-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>

    <modal v-if="showPainModal && !acceptPainTotal">
      <template slot="body">
        <p class="message">
          Your entries exceed 3%, which is the maximum allowance for pain resulting from a single injury.
        </p>
        <p class="prompt">Do you wish to continue?</p>
      </template>
      <template slot="footer">
        <button class="button clear-button action" @click="exceedPainAddonTotal">Yes - Let Me Exceed 3%</button>
        <button class="button black-button action" @click="capPainAddOnTotal">No - Cap Pain at 3%</button>
      </template>
    </modal>

    <modal v-if="showScheduleModal && !acceptRatingSchedule">
      <template slot="body">
        <p class="message">
          This Date of Injury is before 1/1/2005 but you may rate this claim using the 2005 ratings manual.
        </p>
        <p class="prompt">Do you wish to continue?</p>
      </template>
      <template slot="footer">
        <button class="button clear-button action" @click="continueWith2005Schedule">Yes - Continue</button>
        <button class="button black-button action" @click="clearForm">No - Clear Form</button>
      </template>
    </modal>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import vSelect from 'vue-select';
  import axios from 'axios';
  import get from 'lodash.get';
  import Modal from './modal';
  import ResultCalculatorMessages from './permanent-disability/result-calculator-messages';
  import AverageWeeklyEarnings from './permanent-disability/average-weekly-earnings';
  import rtwAdjustmentPeriod from './permanent-disability/rtw-adjustment-period';
  import RtwAdjustments from './permanent-disability/rtw-adjustment-text';
  import ImpairmentRating from './permanent-disability/impairment-rating';
  import CombinedRatingGroup from './permanent-disability/combined-rating-group';
  import valueCombiner from './value-combiner';
  import { roundFloat } from '../util/float';
  import { addDays, differenceInDays, format as dateFormat, isBefore, max as maxDate } from 'date-fns';
  import { props, provide, components, filters, methods } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { between, integer, minValue, maxValue, required } from 'vuelidate/lib/validators';
  import { inputs } from './saveable/pd';
  import pick from '../util/pick';
  import {
    impairments, occVariants, occupationCodes, occupationalAdjustments, ageAdjustments, gafToWpiConversions,
    specialCaseOptions
  } from './permanent-disability/fetch-data';

  const newImpairment = () => (
    {
      method: undefined, specialCase: undefined, wpiOption: 'wpi', gaf: undefined, wpi: undefined, bhi: undefined,
      industrialCausation: 100, painAddon: 0, collapsed: false, copied: false, vsi: undefined
    }
  );

  const resettableData = () => (
    {
      claimNumber: null,
      doctor: { firstName: null, lastName: null },
      worker: { firstName: null, lastName: null },
      reportDate: null,
      dateOfBirth: undefined,
      dateOfInjury: undefined,
      wageOption: 'custom',
      customAverageWeeklyEarnings: undefined,
      jobOffered: 'na',
      pdCommencementDate: undefined,
      rtwDate: undefined,
      occupationGroup: undefined,
      impairments: [newImpairment()],
      results: false,
      summaryCopied: false,
      dirtyPainAddonIndex: -1,
      acceptPainTotal: false,
      acceptRatingSchedule: false,
      saveStatus: null,
      saveMessage: null
    }
  );

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin],
    created() {
      // We need to set the claimNumber before the claim-number component is rendered so the selection does not change
      // when we load the form data.
      this.claimNumber = this.form.claimNumber;

      axios.all([
        impairments(), occVariants(), occupationCodes(), occupationalAdjustments(), ageAdjustments(),
        gafToWpiConversions()
      ]).then(axios.spread(
        (impairments, occVariants, occupationCodes, occupationalAdjustments, ageAdjustments, gafToWpiConversions) => {
          this.impairmentOptions = impairments;
          this.specialCaseOptions = specialCaseOptions(impairments);
          this.occVariants = occVariants;
          this.occupationCodes = occupationCodes;
          this.occupationalAdjustments = occupationalAdjustments;
          this.ageAdjustments = ageAdjustments;
          this.gafToWpiConversions = gafToWpiConversions;

          Object.assign(this.$data, resettableData(), pick(this.form, ...Object.keys(this.$data)));
        }));
    },
    data() {
      return Object.assign({
        occupationCodes: [],
        occupationalAdjustments: {},
        ageAdjustments: {},
        impairmentOptions: [],
        specialCaseOptions: {},
        occVariants: {},
        gafToWpiConversions: {}
      }, resettableData());
    },
    asyncComputed: {
      totalWeeksOfPd: {
        get() {
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/pdd_durations/lookup', { params }).then(r => roundFloat(r.data));
        },
        shouldUpdate() {
          return !(this.$v.impairmentDeps.$invalid || this.$v.impairments.$invalid);
        },
        default: 0
      },
      pdEarningsLimit: {
        get() {
          const m = this.pdRating === 100 ? 1.5 : 1;
          const endpoint = this.pdRating === 100 ? 'ttd_limits' : 'ppd_earnings_limits';
          const params = { percent: this.pdRating, date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get(`/${endpoint}/lookup`, { params })
            .then(({ data: { min, max } }) => ({ min: parseFloat(min) * m, max: parseFloat(max) * m }));
        },
        shouldUpdate() {
          return !(this.$v.impairmentDeps.$invalid || this.$v.impairments.$invalid || this.pdRating === 0);
        },
        default: { min: 0, max: Infinity }
      },
      lifePensionEarningMax: {
        get() {
          const params = { date: dateFormat(this.dateOfInjury, 'YYYY-MM-DD') };
          return axios.get('/life_pension_limits/lookup', { params })
            .then(({ data: { value }}) => ({ value: parseFloat(value) }));
        },
        shouldUpdate() {
          return !this.$v.dateOfInjury.$invalid;
        },
        default: { value: 0 }
      },
    },
    computed: {
      currentImpairmentOptions() {
        return this.impairments.filter(({ method }) => method);
      },
      isSpecialGafResult() {
        return this.impairments.length === 1 && this.impairments.some(({ gaf, wpi }) => gaf && wpi === 0);
      },
      isQualifiedForLp() {
        return this.pdRating >= 70 && this.pdRating !== 100;
      },
      WPIReadOnly() {
        return impairment => ['gaf', 'bhi', 'vsi'].includes(impairment.wpiOption);
      },
      impairmentsValid() {
        return !(this.$v.impairmentDeps.$invalid || this.$v.impairments.$each.$invalid);
      },
      scheduleYear() {
        return isBefore(this.dateOfInjury, new Date(2013, 0, 1)) ? 2005 : 2013;
      },
      age() {
        if (['dateOfInjury', 'dateOfBirth'].some(e => this.$v[e].$invalid)) { return }
        return Math.trunc(differenceInDays(this.dateOfInjury, this.dateOfBirth) / 365.24);
      },
      impairmentRatings() {
        return this.impairments.map((impairment, index) => (
          new ImpairmentRating(impairment, this.computeRatingVariables(impairment, index))
        ));
      },
      combinedRatingGroups() {
        return this.impairmentRatings.reduce((memo, impairmentRating) => {
          const key = impairmentRating.combinedRatingKey;
          memo[key] = memo[key] || new CombinedRatingGroup();
          memo[key].add(impairmentRating);
          return memo;
        }, {});
      },
      ratingStrings() {
        return this.impairmentRatings.map(e => e.ratingString);
      },
      sortedRatingGroups() {
        return Object.keys(this.combinedRatingGroups).reduce((memo, key) => {
          const combinedRatingGroup = this.combinedRatingGroups[key];
          memo.push(combinedRatingGroup);
          return memo;
        }, []).sort((a, b) => b.rating - a.rating);
      },
      combinedRatingGroupsSummary() {
        return `${this.sortedRatingGroups.map(e => e.rating).join(' C ')} = ${this.pdRating}`;
      },
      pdRating() {
        const ratings = this.sortedRatingGroups.map(e => e.rating / 100).reduce(valueCombiner, []);
        return Math.round(ratings[ratings.length - 1] * 100);
      },
      painAddonTotal() {
        return this.impairments.map(({ painAddon }) => painAddon || 0).reduce((memo, e) => memo + e, 0);
      },
      averageWeeklyEarnings() {
        return new AverageWeeklyEarnings(
          this.pdRating, this.wageOption, this.customAverageWeeklyEarnings, this.pdEarningsLimit,
          this.lifePensionEarningMax
        );
      },
      weeklyPdRate() {
        return this.averageWeeklyEarnings.pdRate.toFixed(2);
      },
      totalPdPayout() {
        return this.weeklyPdRate * this.totalWeeksOfPd;
      },
      adjustmentPeriod() {
        return rtwAdjustmentPeriod(this.dateOfInjury);
      },
      hasRtwAdjustmentPeriod() {
        return this.adjustmentPeriod === 'during' && this.pdRating !== 100;
      },
      daysAfterPsDate() {
        if (this.pdCommencementDate && this.rtwDate && this.jobOffered === 'yes') {
          return differenceInDays(this.rtwDate, this.pdCommencementDate);
        } else {
          return 60;
        }
      },
      rtwWeeksPaidAtBaseRate() {
        const raw = this.daysAfterPsDate / 7;
        return { raw, weeks: Math.trunc(raw), days: this.daysAfterPsDate % 7 }
      },
      rtwPdPaidAtBaseRate() {
        const { days, weeks } = this.rtwWeeksPaidAtBaseRate;
        return weeks * this.weeklyPdRate + days * roundFloat(this.weeklyPdRate / 7, 2);
      },
      rtwModifier() {
        return { yes: 0.85, no: 1.15 }[this.jobOffered];
      },
      rtwWeeklyPdCompensation() {
        return (this.weeklyPdRate * this.rtwModifier).toFixed(2);
      },
      rtwWeeksOfPdRemaining() {
        return roundFloat(this.totalWeeksOfPd - this.rtwWeeksPaidAtBaseRate.raw, 5);
      },
      rtwPdIndemnityRemaining() {
        return this.rtwWeeksOfPdRemaining * this.rtwWeeklyPdCompensation;
      },
      rtwTotalPdPayout() {
        return this.rtwPdPaidAtBaseRate + this.rtwPdIndemnityRemaining;
      },
      showPainModal() {
        return this.painAddonTotal > 3 &&
          this.impairments.every((e, index) => !this.$v.impairments.$each[index].painAddon.$invalid);
      },
      showScheduleModal() {
        return !this.$v.dateOfInjury.$invalid && isBefore(this.dateOfInjury, new Date(2005, 0, 1));
      }
    },
    methods: {
      computeRatingVariables(impairment, index) {
        if (this.$v.impairmentDeps.$invalid || this.$v.impairments.$each[index].$invalid) { return { v10: 0 } }

        const age = Math.min(Math.max(this.age, 0), 110);
        const { method, specialCase, wpi, industrialCausation, painAddon } = impairment;
        const variantMethod = specialCase ? specialCase.value : method.value;

        const before2013 = isBefore(this.dateOfInjury, new Date(2013, 0, 1));
        const v1 = industrialCausation;
        const v2 = method.value;
        const v3 = wpi + (painAddon || 0);
        const v4 = before2013 ? method.fecRank : 1.4;
        const v5 = Math.round(Math.min(100, v3 * (before2013 ? method.adjustmentFactor : 1.4)));
        const v6 = this.occupationGroup.group;
        const v7 = this.occVariants[variantMethod][v6] || '';
        const v8 = this.occupationalAdjustments[v5][v7] || 0;
        const ageAdjustments = this.ageAdjustments[v8] || [{ min: 0, max: Infinity, value: 0 }];
        const ageAdjustment = ageAdjustments.find(({ min, max }) => age >= min && age <= max);
        const v9 = ageAdjustment.value;
        const v10 = Math.round(v9 * v1 / 100);
        return { v1, v2, v3, v4, v5, v6, v7, v8, v9, v10 };
      },
      calculatePd() {
        this.$asyncComputed.totalWeeksOfPd.update();
        this.calculate();
      },
      addDays,
      get,
      addImpairment() {
        this.impairments.forEach(e => e.collapsed = true);
        this.impairments.push(newImpairment());

        // This is not idiomatic Vue but the v-select component does not expose an API for focus
        this.$nextTick(() => this.$el.querySelector('.saved-string .v-select.select-method input').focus());
      },
      removeImpairment(index) {
        this.$v.impairments.$each[index].$reset();
        this.impairments.splice(index, 1);
      },
      toggle(impairment) {
        impairment.collapsed = !impairment.collapsed;
      },
      copyRatingString(index) {
        this.$copyText(this.ratingStrings[index]).then(() => {
          this.$set(this.impairments[index], 'copied', true);
          setTimeout(() => this.$set(this.impairments[index], 'copied', false), 1000);
        });
      },
      copyRatingSummary() {
        const summary = this.sortedRatingGroups.reduce((memo, crg) => {
          crg.impairmentRatings.forEach(({ ratingString, title }) => {
            memo.push(`${ratingString}\n${title}\n\n`);
          });
          return memo;
        }, []);

        this.sortedRatingGroups.filter(crg => crg.impairmentRatings.length > 1).forEach(crg => {
          summary.push(`${crg.ratingString}\n`)
        });

        summary.push(this.combinedRatingGroupsSummary);

        this.$copyText(summary.join('')).then(() => {
          this.summaryCopied = true;
          setTimeout(() => this.summaryCopied = false, 1500);
        });
      },
      clearForm() {
        Object.assign(this.$data, resettableData());
        this.$v.$reset();
      },
      painAddonChanged(index) {
        this.$asyncComputed.totalWeeksOfPd.update();
        this.$v.impairments.$each[index].painAddon.$touch();
        this.dirtyPainAddonIndex = index;
      },
      gafChanged(event, index) {
        this.$set(this.impairments[index], 'wpi', this.gafToWpiConversions[parseInt(event.target.value)]);
        this.$v.impairments.$each[index].gaf.$touch();
      },
      bhiOrVsiChanged(event, index) {
        let impairment = this.$v.impairments.$each[index];
        if(impairment.bhi.$error || impairment.vsi.$error) {
          this.$set(this.impairments[index], 'wpi', '');
        } else {
          this.$set(this.impairments[index], 'wpi', Math.round(parseInt(event.target.value)*0.35));
        }
        impairment.bhi.$touch()
        impairment.vsi.$touch()
        impairment.wpi.$touch();
      },
      wpiOptionChanged(index) {
        this.$asyncComputed.totalWeeksOfPd.update();
        const impairment = this.impairments[index];
        this.$set(impairment, 'wpi', undefined);
      },
      exceedPainAddonTotal() {
        this.acceptPainTotal = true;
      },
      capPainAddOnTotal() {
        const currentImpairment = this.impairments[this.dirtyPainAddonIndex];
        const { painAddon } = currentImpairment;
        const excess = this.painAddonTotal - 3;
        this.$set(currentImpairment, 'painAddon', painAddon - excess);
      },
      continueWith2005Schedule() {
        this.acceptRatingSchedule = true;
      },
      availableSidesOfBody(impairment) {
        if (!impairment.method || ![16, 17].includes(impairment.method.chapter)) {
          return [];
        }
        const { value: currentValue } = impairment.method;
        const selectedSides = this.currentImpairmentOptions
          .filter(({ method: { value }, sideOfBody }) => value === currentValue && sideOfBody)
          .map(({ sideOfBody }) => sideOfBody);
        return ['Left', 'Right', 'NA or Unknown'].filter(e => !selectedSides.includes(e));
      },
      loosenedSearchFilter(option, label, search) {
        return (search || "").split(' ').every((element) => {
          return label.toLowerCase().includes(element.toLowerCase());
        })
      },
      availableImpairmentOptions() {
        const currentValues = this.currentImpairmentOptions.map(({ method: { value } }) => value);
        return this.impairmentOptions.map(e => ({ method: e }))
          .filter(impairment =>
            !currentValues.includes(impairment.method.value) || this.availableSidesOfBody(impairment).length > 0)
          .sort((a, b) => a.method.value !== b.method.value ? a.method.value < b.method.value ? -1 : 1 : 0 )
          .map(({ method }) => method);
      },
      extractInputData() {
        return Object.assign({}, pick(this.$data, ...inputs), { pdRating: this.pdRating });
      }
    },
    validations: {
      dateOfBirth: { required, minValue: minValue(new Date(1900, 0, 1)) },
      dateOfInjury: {
        required,
        minValue(value) {
          return minValue(maxDate(this.dateOfBirth, new Date(1983, 0, 1)))(value);
        },
        maxValue(value) {
          return maxValue(methods.methods.endOfYear())(value);
        }
      },
      customAverageWeeklyEarnings: {
        required(value) { return this.wageOption === 'custom' ? required(value): true },
        minValue: minValue(0)
      },
      occupationGroup: { required },
      pdCommencementDate: { minValue(value) { return minValue(this.dateOfInjury)(value) } },
      rtwDate: { between(value) { return between(this.pdCommencementDate, addDays(this.pdCommencementDate, 60))(value) } },
      impairments: {
        required,
        $each: {
          method: { required },
          specialCase: {
            required(value, impairment) {
              return this.specialCaseOptions.hasOwnProperty(get(impairment, 'method.value')) ? required(value) : true
            }
          },
          sideOfBody: {
            required(value, impairment) {
              return [16, 17].includes(get(impairment, 'method.chapter')) ? required(value) : true
            }
          },
          gaf: {
            required(value, impairment) {
              return impairment.wpiOption === 'gaf' ? required(value) : true;
            },
            between: between(1, 100),
            integer
          },
          bhi: {
            required(value, impairment) {
              return impairment.wpiOption === 'bhi' ? required(value) : true;
            },
            between: between(1, 100),
            integer
          },
          vsi: {
            required(value, impairment) {
              return impairment.wpiOption === 'vsi' ? required(value) : true;
            },
            between: between(1, 100),
            integer
          },
          wpi: {
            required,
            integer,
            between: between(0, 100),
            minValue(value, impairment) {
              const min = ['14.01.00.00', '11.01.01.00', '11.03.04.00'].includes(get(impairment, 'method.value')) ? 0 : 1;
              return minValue(min)(value);
            }
          },
          painAddon: { integer, between: between(0, 3) },
          industrialCausation: { required, integer, between: between(0, 100) }
        }
      },
      impairmentDeps: ['dateOfBirth', 'dateOfInjury', 'occupationGroup']
    },
    components: { DatePicker, vSelect, RtwAdjustments, ResultCalculatorMessages, Modal }
  }
</script>
