<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form id="death-benefits-form" @submit.prevent>
        <claim-number v-model="claimNumber" @data="populateForm" />
        <injured-worker v-model="worker" />
        <calc-field-box :error="$v.dateOfInjury.$error">
          <calc-label target="date-of-injury" :error="errorMessage('dateOfInjury')">
            Date of Injury:
            <template slot="tooltip">
              Enter the Injured Worker's Date of Injury. See <soc-section section="5.5"/> for instructions on finding
              the date to use for continuous trauma injuries.
            </template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="dateOfInjury" id="date-of-injury"
                         input-class="shortField" @input="$v.dateOfInjury.$touch()" :typeable="true"
                         placeholder="mm/dd/yy" :disabled-dates="{ to: new Date(1996, 0, 1) }"
                         >
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box :error="$v.annualSpend.$error">
          <calc-label target="annual-spend" :error="errorMessage('annualSpend')">
            Amount spent annually to support any partial dependents:
            <template slot="tooltip">
              Enter the amount annually devoted to the support of the partial dependents. See
              <soc-section section="12.18"/> for details.
            </template>
          </calc-label>
          <calc-field>
            <span>&dollar;</span>
            <input v-model.number.lazy="annualSpend" type="text" id="annual-spend" class="shortField"
                   @change="$v.annualSpend.$touch()">
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>
      <h4>The Death Benefits owed are as follows under each of the indicated scenarios.</h4>
      <div class="longResultContainer">
        <div class="resultLabel">One total dependent and no partial dependents:</div>
        <div class="resultValue">{{ 250000 | currency }}</div>
      </div>
      <div class="longResultContainer">
        <div class="resultLabel">Two total dependents (and any number of partial dependents):</div>
        <div class="resultValue">{{ 290000 | currency }}</div>
      </div>
      <div class="longResultContainer">
        <div class="resultLabel">
          Three or more total dependents (and any number of partial dependents):
        </div>
        <div class="resultValue">{{ 320000 | currency }}</div>
      </div>
      <div class="longResultContainer">
        <div class="resultLabel">One total dependent plus one or more partial dependents:</div>
        <div class="resultValue" v-if="oneTotalDependentOnePlusPartial > 290000">
          {{ 290000 | currency }} maximum benefits
        </div>
        <div class="resultValue" v-else>
          {{ 250000 | currency }} + (4 &times; {{ annualSpend | currency }}) = {{ oneTotalDependentOnePlusPartial | currency }}
        </div>
      </div>
      <div class="longResultContainer">
        <div class="resultLabel">No total and one or more partial dependents:</div>
        <div class="resultValue" v-if="noTotalDependentsOnePlusPartial > 250000">
          {{ 250000 | currency }} maximum benefits
        </div>
        <div class="resultValue" v-else>
          8 &times; {{ annualSpend | currency }} = {{ noTotalDependentsOnePlusPartial | currency }}
        </div>
      </div>

      <save-button form="death-benefits-form" :status="saveStatus" :message="saveMessage" :enabled="!!claimNumber" />
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import { props, provide, filters, methods, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, minValue, maxValue } from 'vuelidate/lib/validators';
  import saveMixin from './saveable/death-benefits';

  export default {
    mixins: [props, provide, filters, methods, components, validationMixin, saveMixin],
    data() {
      return Object.assign({}, {
        claimNumber: null,
        worker: { firstName: null, lastName: null },
        dateOfInjury: null,
        annualSpend: null,
        results: false,
        saveStatus: null,
        saveMessage: null
      }, this.form);
    },
    computed: {
      oneTotalDependentOnePlusPartial() {
        return 250000 + (4 * this.annualSpend || 0);
      },
      noTotalDependentsOnePlusPartial() {
        return 8 * (this.annualSpend || 0);
      }
    },
    validations: {
      dateOfInjury: {
        required,
        minValue(value) {
          return minValue(new Date(2006, 0, 1))(value)
        },
        maxValue(value) {
          return maxValue(methods.methods.endOfYear())(value);
        }
      },
      annualSpend: { required }
    },
    components: { DatePicker }
  }
</script>
