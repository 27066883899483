var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("transition", { attrs: { name: "modal" } }, [
    _c("div", { staticClass: "modal-mask" }, [
      _c("div", { staticClass: "modal-wrapper" }, [
        _c("div", { staticClass: "modal-container" }, [
          _c("img", {
            staticClass: "header-logo",
            attrs: {
              src: "/images/soc-logo-black.svg",
              alt: "Sullivan On Comp Logo",
            },
          }),
          _vm._v(" "),
          _c("div", { staticClass: "modal-body" }, [_vm._t("body")], 2),
          _vm._v(" "),
          _c("div", { staticClass: "modal-footer" }, [_vm._t("footer")], 2),
        ]),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }