<template>
  <tr>
    <td>{{ formatDate(from) }}</td>
    <td>{{ formatDate(through) }}</td>
    <td>{{ rate | currency }}</td>
    <td>{{ intervalWeeks[index] | toFixed(2) }}</td>
    <td>{{ intervalAmount[index] | currency }}</td>
    <td>{{ cumulativeWeeks[index] | toFixed(2) }}</td>
    <td>{{ cumulativeAmount[index] | currency }}</td>
    <td>{{ balanceDue[index] | currency }}</td>
    <td>{{ presentValue[index] | currency }}</td>
  </tr>
</template>

<script>
  import { filters } from '../../../mixins';
  import { format as dateFormat, differenceInDays } from 'date-fns';

  import { createNamespacedHelpers } from 'vuex';

  const { mapGetters } = createNamespacedHelpers('commutationOfDeathBenefits');

  export default {
    mixins: [filters],
    props: {
      from: {
        type: Date,
        required: true
      },
      through: {
        type: Date,
        required: true
      },
      rate: {
        type: Number,
        required: true
      },
      index: {
        type: Number,
        required: true
      }
    },
    computed: {
      ...mapGetters([
        'intervalWeeks',
        'intervalAmount',
        'cumulativeWeeks',
        'cumulativeAmount',
        'presentValue',
        'balanceDue'
      ])
    },
    methods: {
      formatDate(value) {
        return dateFormat(value, 'MM/DD/YY');
      }
    }
  }
</script>
