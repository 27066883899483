var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "h-scroll-container" },
    [
      _c("table", { staticClass: "fold-table" }, [
        _vm._m(0),
        _vm._v(" "),
        _c("thead", [
          _c("tr", [
            _c(
              "th",
              { staticClass: "report-search", attrs: { colspan: "4" } },
              [
                _c("span", { staticClass: "search-input" }, [
                  _c("input", {
                    attrs: { type: "text", placeholder: "Enter search here" },
                    domProps: { value: _vm.query },
                    on: { input: _vm.setQuery },
                  }),
                  _vm._v(" "),
                  _vm.query
                    ? _c(
                        "span",
                        {
                          staticClass: "reset-button",
                          on: {
                            click: function ($event) {
                              _vm.query = ""
                            },
                          },
                        },
                        [_vm._v("×")]
                      )
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  "button",
                  { staticClass: "search-button", attrs: { type: "button" } },
                  [_vm._v("Search")]
                ),
              ]
            ),
          ]),
          _vm._v(" "),
          _c(
            "tr",
            [
              _c(
                "sortable-header",
                {
                  attrs: { name: "claim_number.raw", current: _vm.currentSort },
                  on: { sort: _vm.handleSort },
                },
                [_vm._v("\n        Claim/Matter\n      ")]
              ),
              _vm._v(" "),
              _c(
                "sortable-header",
                {
                  attrs: {
                    name: "worker_first_name.raw",
                    current: _vm.currentSort,
                  },
                  on: { sort: _vm.handleSort },
                },
                [_vm._v("\n        First Name\n      ")]
              ),
              _vm._v(" "),
              _c(
                "sortable-header",
                {
                  attrs: {
                    name: "worker_last_name.raw",
                    current: _vm.currentSort,
                  },
                  on: { sort: _vm.handleSort },
                },
                [_vm._v("\n        Last Name\n      ")]
              ),
              _vm._v(" "),
              _c(
                "sortable-header",
                {
                  attrs: { name: "updated_at", current: _vm.currentSort },
                  on: { sort: _vm.handleSort },
                },
                [_vm._v("\n        Date Modified\n      ")]
              ),
            ],
            1
          ),
        ]),
        _vm._v(" "),
        _c(
          "tbody",
          [
            _vm._l(_vm.groups, function (cg) {
              return [
                cg.calculators.length > 0
                  ? _c("calculator-group", { key: cg.id, attrs: { group: cg } })
                  : _vm._e(),
              ]
            }),
          ],
          2
        ),
      ]),
      _vm._v(" "),
      _c("paginate", {
        attrs: {
          "container-class": "pagination",
          "page-count": _vm.response.meta.total_pages,
          "click-handler": _vm.handlePageClick,
          "first-last-button": true,
          "prev-text": "<i class='fa fa-play fa-rotate-180'></i>",
          "next-text": "<i class='fa fa-play'></i>",
          "first-button-text": "<i class='fa fa-fast-backward'></i>",
          "last-button-text": "<i class='fa fa-fast-forward'></i>",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("colgroup", [
      _c("col", { staticClass: "claimID" }),
      _vm._v(" "),
      _c("col", { staticClass: "firstName" }),
      _vm._v(" "),
      _c("col", { staticClass: "lastName" }),
      _vm._v(" "),
      _c("col", { staticClass: "dateModified" }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }