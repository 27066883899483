import pick from '../../util/pick';

export default function (...inputs) {
  return {
    methods: {
      extractInputData() {
        return pick(this.$data, ...inputs);
      }
    }
  };
}
