<template>
  <div>
    <section id="calculator-form">
      <h2>Inputs</h2>
      <form @submit.prevent>
        <calc-field-box>
          <calc-label target="mode">
            Calculation Type:
            <template slot="tooltip">Select the type of calculation you wish to perform.</template>
          </calc-label>
          <calc-field>
            <select v-model="mode" id="mode" @change="resetData()">
              <option value="difference">Calculate Difference in Days</option>
              <option value="start">Calculate a Starting Date</option>
              <option value="end">Calculate an Ending Date</option>
            </select>
          </calc-field>
        </calc-field-box>

        <calc-field-box>
          <calc-label>
            Counting Method:
            <template slot="tooltip">
              Select to determine whether or not to count the first date in a range as one day on its own:
              <ul>
                <li>
                  Select <b>1st day counted</b> to include the starting date in the count of days. (This is useful when
                  counting days of payment.)
                </li>
                <li>
                  Select <b>24 hours per day</b> to measure the number of days that span a date range. In this approach,
                  it takes 24 hours to reach a count of one. So for example, the period from the first date in a range
                  until the next date on the calendar are counted as the first day, and so on. (This is useful when
                  counting the number of days until a deadline.)
                </li>
              </ul>
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="countingOffset" type="radio" value="1" id="first-day">
              <label for="first-day" slot="label">1st day counted</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="countingOffset" type="radio" value="0" id="twenty-four-hours">
              <label for="twenty-four-hours" slot="label">24 hours per day</label>
            </pretty-radio>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="mode !== 'start'" :error="$v.startDateInput.$error">
          <calc-label target="start-date" :error="errorMessage('startDateInput')">
            Starting Date:
            <template slot="tooltip">Please enter a starting date</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="startDateInput" id="start-date"
                         input-class="shortField" @input="$v.startDateInput.$touch()" :typeable="true"
                         placeholder="mm/dd/yyyy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="mode === 'start'" :error="$v.intervalInput.$error">
          <calc-label target="interval" :error="errorMessage('intervalInput')">
            Length of Time:
            <template slot="tooltip">
              Select either <b>Number of Days</b> or <b>Number of Weeks</b> and then specify the length of time for the
              period you wish to calculate.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="intervalOption" type="radio" value="days" id="days">
              <label for="days" slot="label">Number of Days</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="intervalOption" type="radio" value="weeks" id="weeks">
              <label for="weeks" slot="label">Number of Weeks</label>
            </pretty-radio>
            <input v-model.number.lazy="intervalInput" type="text" id="interval" class="shortField"
                   @change="$v.intervalInput.$touch()">
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="mode !== 'end'" :error="$v.endDateInput.$error">
          <calc-label target="end-date" :error="errorMessage('endDateInput')">
            Ending Date:
            <template slot="tooltip">Please enter an ending date</template>
          </calc-label>
          <calc-field>
            <date-picker :format="americanDateFormatter" v-model="endDateInput" id="end-date" input-class="shortField"
                         @input="$v.endDateInput.$touch()" :typeable="true" placeholder="mm/dd/yyyy">
            </date-picker>
          </calc-field>
        </calc-field-box>

        <calc-field-box v-if="mode === 'end'" :error="$v.intervalInput.$error">
          <calc-label target="interval" :error="errorMessage('intervalInput')">
            Length of Time:
            <template slot="tooltip">
              Select either <b>Number of Days</b> or <b>Number of Weeks</b> and then specify the length of time for the
              period you wish to calculate.
            </template>
          </calc-label>
          <calc-field>
            <pretty-radio>
              <input v-model.number="intervalOption" type="radio" value="days" id="days">
              <label for="days" slot="label">Number of Days</label>
            </pretty-radio>
            <pretty-radio>
              <input v-model.number="intervalOption" type="radio" value="weeks" id="weeks">
              <label for="weeks" slot="label">Number of Weeks</label>
            </pretty-radio>
            <input v-model.number.lazy="intervalInput" type="text" id="interval" class="shortField"
                   @change="$v.intervalInput.$touch()">
          </calc-field>
        </calc-field-box>

        <calculate-button @click="calculate()" @clear="clearForm()"></calculate-button>
      </form>
    </section>

    <section id="calculator-results" v-if="!$v.$invalid && results">
      <h2>Results</h2>

      <div class="resultContainer">
        <div class="resultLabel">Starting Date:</div>
        <div class="resultValue">{{ humanDateFormatter(startDate) }}</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">Difference in Days:</div>
        <div class="resultValue">{{ numberOfDays.toLocaleString() }}</div>
      </div>

      <div class="resultContainer" v-if="numberOfDays >= 7">
        <div class="resultLabel">Difference in Weeks:</div>
        <div class="resultValue">{{ numberOfWeeks.weeks }} Weeks, {{ numberOfWeeks.days | toFixed(2) }} Days</div>
      </div>

      <div class="resultContainer">
        <div class="resultLabel">Ending Date:</div>
        <div class="resultValue">{{ humanDateFormatter(endDate) }}</div>
      </div>

      <h4>Mailbox Rule Extensions from Ending Date</h4>
      <p>
        If a party receives a document by mail that has a time limit for a response, that limit may be extended. This
        is known as the mailbox rule. The length of the extension, calculated from the ending date, is as follows:
      </p>
      <div class="resultContainer">
        <div class="resultLabel">If the party being served is w/in CA (+5 days):</div>
        <div class="resultValue">{{ humanDateFormatter(addToEndDate(5)) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">If the party being served is w/in USA but outside CA (+10 days):</div>
        <div class="resultValue">{{ humanDateFormatter(addToEndDate(10)) }}</div>
      </div>
      <div class="resultContainer">
        <div class="resultLabel">If the party being served is outside USA (+20 days):</div>
        <div class="resultValue">{{ humanDateFormatter(addToEndDate(20)) }}</div>
      </div>
      <p>
        If any of the indicated dates fall on a weekend or on a holiday for which the offices of the appeals board are
        closed, the act or response may be performed or exercised the next business day. (For details see
        <soc-section section="15.15"/>)
      </p>
    </section>
  </div>
</template>

<script>
  import DatePicker from 'vuejs-datepicker';
  import { differenceInDays, subDays, addDays } from 'date-fns';
  import { methods, filters, components } from './mixins';
  import { validationMixin } from 'vuelidate';
  import { required, minValue, integer, decimal } from 'vuelidate/lib/validators';

  const initialData = () => {
    return {
      mode: 'end',
      countingOffset: 0,
      startDateInput: null,
      endDateInput: null,
      intervalInput: null,
      intervalOption: 'days',
      results: false
    }
  };

  export default {
    mixins: [methods, filters, components, validationMixin],
    data: initialData,
    computed: {
      intervalMultiplier() {
        return { days: 1, weeks: 7 }[this.intervalOption]
      },
      intervalInDays() {
        return (this.intervalInput || 0) * this.intervalMultiplier;
      },
      numberOfDays() {
        return this.intervalInDays || differenceInDays(this.endDateInput, this.startDateInput) + this.countingOffset;
      },
      numberOfWeeks() {
        return { weeks: Math.trunc(this.numberOfDays / 7), days: this.numberOfDays % 7 }
      },
      startDate() {
        return this.startDateInput || subDays(this.endDateInput, Math.ceil(this.intervalInDays) - this.countingOffset);
      },
      endDate() {
        return this.endDateInput || addDays(this.startDateInput, Math.ceil(this.intervalInDays) - this.countingOffset);
      }
    },
    methods: {
      addToEndDate(value) {
        return addDays(this.endDate, value);
      },
      resetData() {
        Object.assign(this.$data, initialData(), { mode: this.mode });
        this.$v.$reset();
      }
    },
    validations: {
      startDateInput: { required(value) { return this.mode === 'start' ? true : required(value) } },
      endDateInput: {
        required(value) { return this.mode === 'end' ? true : required(value) },
        minValue(value) {
          return this.mode === 'difference' ? minValue(this.startDateInput)(value) : true
        }
      },
      intervalInput: {
        required(value) { return this.mode === 'difference' ? true : required(value) },
        minValue(value) { return this.mode === 'difference' ? true : minValue(1)(value) },
        decimal(value) { return { days: true, weeks: decimal(value) }[this.intervalOption] },
        integer(value) { return { days: integer(value), weeks: true }[this.intervalOption] }
      }
    },
    components: { DatePicker }
  }
</script>
