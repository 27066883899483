class ImpairmentRating {
  constructor(impairment, variables) {
    this.impairment = impairment;
    this.variables = variables;
  }

  get rating() {
    return this.variables.v10;
  }

  get title() {
    return [this.sideOfBody, this.impairment.method.header].filter(e => e).join(' ');
  }

  get sideOfBody() {
    return { Left: 'Left', Right: 'Right' }[this.impairment.sideOfBody];
  }

  get ratingString() {
    const { v1, v2, v3, v4, v5, v6, v7, v8, v9, v10 } = this.variables;
    return `${v1}% - (${v2} - ${v3} - [${v4}] ${v5} - ${v6}${v7} - ${v8} - ${v9}%) ${v10}%`
  }

  get combinedRatingKey() {
    const { chapter, value } = this.impairment.method;
    const number = ([16, 17].includes(chapter) && this.sideOfBody) ? chapter : value;
    return [number, this.sideOfBody].filter(e => e).join(':');
  }
}

export default ImpairmentRating;
