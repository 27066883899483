var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("section", { attrs: { id: "calculator-form" } }, [
      _c("h2", [_vm._v("Inputs")]),
      _vm._v(" "),
      _c(
        "form",
        {
          attrs: { id: "awards-form" },
          on: {
            submit: function ($event) {
              $event.preventDefault()
            },
          },
        },
        [
          _c("claim-number", {
            on: { data: _vm.populateForm },
            model: {
              value: _vm.claimNumber,
              callback: function ($$v) {
                _vm.claimNumber = $$v
              },
              expression: "claimNumber",
            },
          }),
          _vm._v(" "),
          _c("injured-worker", {
            model: {
              value: _vm.worker,
              callback: function ($$v) {
                _vm.worker = $$v
              },
              expression: "worker",
            },
          }),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.awardDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "award-date",
                    error: _vm.errorMessage("awardDate"),
                  },
                },
                [
                  _vm._v("\n          Date of Award:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("The date the award was issued."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "award-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      disabledDates: { from: new Date() },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.awardDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.awardDate,
                      callback: function ($$v) {
                        _vm.awardDate = $$v
                      },
                      expression: "awardDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.paymentDate.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "payment-date",
                    error: _vm.errorMessage("paymentDate"),
                  },
                },
                [
                  _vm._v("\n          Date of Payment:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the date payment will be sent."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("date-picker", {
                    attrs: {
                      format: _vm.americanDateFormatter,
                      id: "payment-date",
                      "input-class": "shortField",
                      typeable: true,
                      placeholder: "mm/dd/yy",
                      disabledDates: { to: _vm.awardDate },
                    },
                    on: {
                      input: function ($event) {
                        return _vm.$v.paymentDate.$touch()
                      },
                    },
                    model: {
                      value: _vm.paymentDate,
                      callback: function ($$v) {
                        _vm.paymentDate = $$v
                      },
                      expression: "paymentDate",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.awardAmount.$error } },
            [
              _c(
                "calc-label",
                {
                  attrs: {
                    target: "amount-of-award",
                    error: _vm.errorMessage("awardAmount"),
                  },
                },
                [
                  _vm._v("\n          Amount of Award:\n          "),
                  _c("template", { slot: "tooltip" }, [
                    _vm._v("Enter the amount of the award."),
                  ]),
                ],
                2
              ),
              _vm._v(" "),
              _c("calc-field", [
                _c("span", [_vm._v("$")]),
                _vm._v(" "),
                _c("input", {
                  directives: [
                    {
                      name: "model",
                      rawName: "v-model.number.lazy",
                      value: _vm.awardAmount,
                      expression: "awardAmount",
                      modifiers: { number: true, lazy: true },
                    },
                  ],
                  staticClass: "shortField",
                  attrs: { type: "text", id: "amount-of-award" },
                  domProps: { value: _vm.awardAmount },
                  on: {
                    change: [
                      function ($event) {
                        _vm.awardAmount = _vm._n($event.target.value)
                      },
                      function ($event) {
                        return _vm.$v.awardAmount.$touch()
                      },
                    ],
                    blur: function ($event) {
                      return _vm.$forceUpdate()
                    },
                  },
                }),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            "calc-field-box",
            { attrs: { error: _vm.$v.customInterestRate.$error } },
            [
              _c(
                "calc-label",
                { attrs: { error: _vm.errorMessage("customInterestRate") } },
                [
                  _vm._v("\n          Annual Interest Rate:\n          "),
                  _c(
                    "template",
                    { slot: "tooltip" },
                    [
                      _vm._v(
                        "\n            Select the interest rate to be used in calculating the dollar amount of interest owed on an award.\n            "
                      ),
                      _c("ul", [
                        _c("li", [
                          _vm._v(
                            "Select 7% if the employer is a local public entity."
                          ),
                        ]),
                        _vm._v(" "),
                        _c("li", [
                          _vm._v("Select 10% for all other employers."),
                        ]),
                      ]),
                      _vm._v("\n            See "),
                      _c("soc-section", { attrs: { section: "16.43" } }),
                      _vm._v(" for more detail.\n          "),
                    ],
                    1
                  ),
                ],
                2
              ),
              _vm._v(" "),
              _c(
                "calc-field",
                [
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interestRateOption,
                          expression: "interestRateOption",
                        },
                      ],
                      attrs: {
                        type: "radio",
                        value: "seven",
                        id: "rate-seven",
                      },
                      domProps: {
                        checked: _vm._q(_vm.interestRateOption, "seven"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.interestRateOption = "seven"
                          },
                          function ($event) {
                            _vm.customInterestRate = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "rate-seven" },
                        slot: "label",
                      },
                      [_vm._v("7%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c("pretty-radio", [
                    _c("input", {
                      directives: [
                        {
                          name: "model",
                          rawName: "v-model",
                          value: _vm.interestRateOption,
                          expression: "interestRateOption",
                        },
                      ],
                      attrs: { type: "radio", value: "ten", id: "rate-ten" },
                      domProps: {
                        checked: _vm._q(_vm.interestRateOption, "ten"),
                      },
                      on: {
                        change: [
                          function ($event) {
                            _vm.interestRateOption = "ten"
                          },
                          function ($event) {
                            _vm.customInterestRate = null
                          },
                        ],
                      },
                    }),
                    _vm._v(" "),
                    _c(
                      "label",
                      {
                        attrs: { slot: "label", for: "rate-ten" },
                        slot: "label",
                      },
                      [_vm._v("10%")]
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    "pretty-radio",
                    { attrs: { "el-style": "margin-right: 0" } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.interestRateOption,
                            expression: "interestRateOption",
                          },
                        ],
                        attrs: {
                          type: "radio",
                          value: "custom",
                          id: "rate-custom",
                        },
                        domProps: {
                          checked: _vm._q(_vm.interestRateOption, "custom"),
                        },
                        on: {
                          change: function ($event) {
                            _vm.interestRateOption = "custom"
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          attrs: { slot: "label", for: "rate-custom" },
                          slot: "label",
                        },
                        [_vm._v(" ")]
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model.number.lazy",
                        value: _vm.customInterestRate,
                        expression: "customInterestRate",
                        modifiers: { number: true, lazy: true },
                      },
                    ],
                    staticClass: "shortField",
                    attrs: {
                      id: "custom-interest-rate",
                      type: "text",
                      placeholder: "Custom",
                      disabled: _vm.presetMode,
                    },
                    domProps: { value: _vm.customInterestRate },
                    on: {
                      change: [
                        function ($event) {
                          _vm.customInterestRate = _vm._n($event.target.value)
                        },
                        function ($event) {
                          return _vm.$v.customInterestRate.$touch()
                        },
                      ],
                      blur: function ($event) {
                        return _vm.$forceUpdate()
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c("span", [_vm._v("%")]),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c("calculate-button", {
            on: {
              click: function ($event) {
                return _vm.calculate()
              },
              clear: function ($event) {
                return _vm.clearForm()
              },
            },
          }),
        ],
        1
      ),
    ]),
    _vm._v(" "),
    !_vm.$v.$invalid && _vm.results
      ? _c(
          "section",
          { attrs: { id: "calculator-results" } },
          [
            _c("h2", [_vm._v("Results")]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Number of days between award and payment:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm.daysBetweenAwardAndPayment.toLocaleString())),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Award Amount:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.awardAmount))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Interest Amount:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.interest))),
              ]),
            ]),
            _vm._v(" "),
            _c("div", { staticClass: "resultContainer" }, [
              _c("div", { staticClass: "resultLabel" }, [
                _vm._v("Total Payment:"),
              ]),
              _vm._v(" "),
              _c("div", { staticClass: "resultValue" }, [
                _vm._v(_vm._s(_vm._f("currency")(_vm.total))),
              ]),
            ]),
            _vm._v(" "),
            _c("save-button", {
              attrs: {
                form: "awards-form",
                status: _vm.saveStatus,
                message: _vm.saveMessage,
                enabled: !!_vm.claimNumber,
              },
            }),
          ],
          1
        )
      : _vm._e(),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }