var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm._t("title"),
      _vm._v(" "),
      _vm.collapsed
        ? _c(
            "p",
            {
              staticClass: "revealDetails",
              on: {
                click: function ($event) {
                  return _vm.toggle(false)
                },
              },
            },
            [_vm._v("Show Calculator Details ▼")]
          )
        : _c(
            "p",
            {
              staticClass: "revealDetails",
              on: {
                click: function ($event) {
                  return _vm.toggle(true)
                },
              },
            },
            [_vm._v("Hide Calculator Details ▲")]
          ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: !_vm.collapsed,
              expression: "!collapsed",
            },
          ],
          staticClass: "calc-detail-container",
        },
        [_vm._t("default")],
        2
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }