const getters = {
  rows(state, getters, rootState, rootGetters) {
    return rootGetters['commutationOfDeathBenefits/rows'].slice(rootGetters['commutationOfDeathBenefits/afterDocRowIndex'])
  }
};

export default {
  namespaced: true,
  getters
}
