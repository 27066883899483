import { addWeeks, addYears, differenceInDays, isBefore, isWithinRange, subDays } from 'date-fns';
import { createAnnualPair } from './dates';

const MINIMUM_TTD = 224;

const getLimitsForDate = (date, wageLimits, rateOfIncrease) => {
  let result = wageLimits.find(({ startDate, endDate }) => isWithinRange(date, startDate, endDate));
  if (!result) {
    const lastAvailableLimit = wageLimits[wageLimits.length - 1]
    const yearsSinceLastAvailableDate = date.getYear() - lastAvailableLimit.startDate.getYear() ;
    const assumedMinimumTTD = lastAvailableLimit.min * (Math.pow(( 1 + rateOfIncrease/100 ), yearsSinceLastAvailableDate))
    const assumedMaximumTTD = lastAvailableLimit.max * (Math.pow(( 1 + rateOfIncrease/100 ), yearsSinceLastAvailableDate))
    return { max: assumedMaximumTTD, min: assumedMinimumTTD}
  }

  return result
}

export const createDatesAndRate = (
  {
    dateOfInjury,
    benefitStartDate,
    dateOfCalculation,
    averageWeeklyEarnings,
    wageLimits,
    rateOfIncrease
  }
) => {
  let calculatedTtd = Math.max(averageWeeklyEarnings * 2 / 3, MINIMUM_TTD);
  const anniversaryDate = subDays(addYears(dateOfInjury, 2), 1);
  const dateGenerator = createAnnualPair(benefitStartDate, dateOfCalculation, anniversaryDate);
  let rate = Math.max(getLimitsForDate(dateOfInjury, wageLimits, rateOfIncrease).min, calculatedTtd);

  const initialRate = rate;
  const initialMaxRate = getLimitsForDate(dateOfInjury, wageLimits, rateOfIncrease).max

  return {
    next() {
      const { from, through } = dateGenerator.next();
      const increaseFactor = isBefore(from, anniversaryDate) ? 1 : 1 + (rateOfIncrease / 100);
      const newRate = rate * increaseFactor;
      calculatedTtd = Math.max(averageWeeklyEarnings * 2 / 3, getLimitsForDate(from, wageLimits, rateOfIncrease).min, MINIMUM_TTD);

      if(isBefore(from, anniversaryDate)) {
        calculatedTtd = Math.min(initialRate, initialMaxRate);
      }
      rate = Math.min((getLimitsForDate(from, wageLimits, rateOfIncrease) || { max: newRate }).max, calculatedTtd);
      return { from, through, rate };
    }
  }
};

export const maxBenefitRows = (state) => {
  const { benefitAmount } = state;
  const rows = [];
  const dateAndRateGenerator = createDatesAndRate(state);
  let cumulativeAmount = 0;

  while (true) {
    let terminate = false;
    let { from, through, rate } = dateAndRateGenerator.next();
    let intervalWeeks = (differenceInDays(through, from) + 1) / 7;
    const intervalAmount = rate * intervalWeeks;
    const remainingBalance = benefitAmount - cumulativeAmount;

    if (intervalAmount > remainingBalance) {
      intervalWeeks = remainingBalance / rate;
      through = addWeeks(from, intervalWeeks);
      terminate = true;
    } else {
      terminate = false;
    }

    cumulativeAmount += intervalAmount;

    rows.push({ from, through, rate, intervalWeeks });

    if (terminate) { break }
  }

  return rows;
};

export const endDateRows = (state) => {
  const { benefitEndDate } = state;
  const dateAndRateGenerator = createDatesAndRate(state);
  const rows = [];
  let { from, through, rate } = dateAndRateGenerator.next();

  while (isBefore(through, benefitEndDate)) {
    rows.push({ from, through, rate, intervalWeeks: (differenceInDays(through, from) + 1) / 7 });
    ({ from, through, rate } = dateAndRateGenerator.next());
  }

  rows.push({ from, rate, through: benefitEndDate, intervalWeeks: (differenceInDays(benefitEndDate, from) + 1) / 7 });

  return rows;
};
