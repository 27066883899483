var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "calcLabel", class: _vm.containerClass }, [
    _c(
      "label",
      { attrs: { for: _vm.target, "data-content": _vm.error } },
      [
        _vm._t("default"),
        _vm._v(" "),
        _vm.$slots.tooltip
          ? _c("i", { staticClass: "fa fa-info-circle" })
          : _vm._e(),
        _vm._v(" "),
        _vm.$slots.tooltip
          ? _c("div", { staticClass: "ttip" }, [_vm._t("tooltip")], 2)
          : _vm._e(),
      ],
      2
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }